import React, { useState, useEffect } from "react";
import { API, graphqlOperation, Geo } from "aws-amplify";
import { Button, TextField, SelectField, Flex } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import {
  Modal,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from "@mui/material";
import GeofenceList from "./GeofenceList";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import GoogleMapReact from "google-map-react";

const Geofences = ({ adminOverride, business, setBusiness, user }) => {
  /* Dialog Box */
  const [open, setOpen] = useState(false);
  const [geoId, setGeoId] = useState(null);

  const handleClickOpen = (id) => {
    setGeoId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setGeoId(null);
    setOpen(false);
  };
  /* Geofence Form */
  const [openGeoForm, setOpenGeoForm] = useState(false);
  const [geoFormData, setGeoFormData] = useState({ name: "", assigned: "" });
  const [geofences, setGeofences] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [geofence, setGeofence] = useState([]);
  const [center, setCenter] = useState({ lat: null, lng: null });
  const [radius, setRadius] = useState(0);
  var points = [];
  var currentGeofence;

  const handleOpenGeoForm = () => setOpenGeoForm(true);
  const handleCloseGeoForm = () => setOpenGeoForm(false);
  const handleGeoFormChange = (e) => {
    setGeoFormData({ ...geoFormData, [e.target.name]: e.target.value });
  };

  const handleGeoSubmit = async (e) => {
    e.preventDefault();
    const { name, schedule } = geoFormData;

    console.log(radius);

    if (!(center.lat || center.lng)) {
      alert("Please add a geofence on the map before submitting.");
      return;
    }

    // set the API parameters
    const value = {
      name,
      toggle: true,
      lastSeen: "N/A",
      lat: center.lat,
      lng: center.lng,
      businessId: business.id,
      geofenceScheduleId: schedule.id,
      radius: radius,
      businessName: business.name,
    };

    // send the new geofence info to the dB
    const result = await API.graphql(
      graphqlOperation(mutations.createGeofence, { input: value })
    );
    const updateGeoCount = await API.graphql(
      graphqlOperation(mutations.updateBusiness, {
        input: {
          id: business.id,
          numberGeofences: business.numberGeofences + 1,
        },
      })
    );
    setBusiness(updateGeoCount.data.updateBusiness);

    // use the resulting geofenceId entry to name the AWS geofence in the collection
    let saveGeofenceResults;
    try {
      saveGeofenceResults = await Geo.saveGeofences({
        geofenceId: result.data.createGeofence.id,
        geometry: {
          polygon: [geofence],
        },
      });
    } catch (error) {
      // errors thrown by input validations of `createGeofence`
      const errHandle = await API.graphql(
        graphqlOperation(mutations.deleteGeofence, {
          input: { id: result.data.createGeofence.id },
        })
      );
      const reduceGeoCount = await API.graphql(
        graphqlOperation(mutations.updateBusiness, {
          input: {
            id: business.id,
            numberGeofences: business.numberGeofences - 1,
          },
        })
      );
      setBusiness(reduceGeoCount.data.updateBusiness);
      throw error;
    }

    if (saveGeofenceResults.errors.length > 0) {
      // error handling that are from the underlying API calls
      console.log(`Success count: ${saveGeofenceResults.successes.length}`);
      console.log(`Error count: ${saveGeofenceResults.errors.length}`);
    } else {
      console.log("Success! Check AWS for the geofence");
    }

    // set the geofence resource in the UI collection
    setGeofences([...geofences, result.data.createGeofence]);
    handleCloseGeoForm();
  };

  const handleGeoDelete = async (geoId) => {
    let responseLS, responseDB, responseB;
    // Delete from dB
    responseDB = await API.graphql(
      graphqlOperation(mutations.deleteGeofence, { input: { id: geoId } })
    );
    console.log(responseDB);

    // Delete from Location Services
    try {
      responseLS = await Geo.deleteGeofences(geoId);
    } catch (error) {
      // error handling from logic and validation issues within `deleteGeofences`
      throw error;
    }

    if (responseLS.errors.length > 0) {
      // error handling that are from the underlying API calls
      console.log(`Success count: ${responseLS.successes.length}`);
      console.log(`Error count: ${responseLS.errors.length}`);
    }

    // reduce Geofence count by 1
    responseB = await API.graphql(
      graphqlOperation(mutations.updateBusiness, {
        input: {
          id: business.id,
          numberGeofences: business.numberGeofences - 1,
        },
      })
    );
    setBusiness(responseB.data.updateBusiness);
    console.log(responseB);

    // filter out the deleted geofence and rerender the list
    var filtered = geofences.filter(function (value, index, arr) {
      console.log(value.id + " = " + responseDB.data.deleteGeofence.id);
      return value.id != responseDB.data.deleteGeofence.id;
    });
    setGeofences(filtered);
    console.log("Successfully deleted Geofence " + geoId);
  };

  const handleScheduleSelection = (e) => {
    const schedule = schedules.find(
      (element) => element.name === e.target.value
    );
    setGeoFormData({ ...geoFormData, schedule: schedule });
  };

  useEffect(() => {
    //useEffect function must return a cleanup function or nothing
    (async () => {
      if (adminOverride) {
        const resultGeos = await API.graphql(
          graphqlOperation(queries.listGeofences)
        );
        setGeofences(resultGeos.data.listGeofences.items);
      } else {
        const resultGeos = await API.graphql(
          graphqlOperation(queries.listGeofences, {
            filter: { businessId: { eq: business.id } },
          })
        );
        setGeofences(resultGeos.data.listGeofences.items);
      }
    })(); //IIFE

    (async () => {
      if (adminOverride) {
        const resultSchedules = await API.graphql(
          graphqlOperation(queries.listSchedules)
        );
        setSchedules(resultSchedules.data.listSchedules.items);
      } else {
        const resultSchedules = await API.graphql(
          graphqlOperation(queries.listSchedules, {
            filter: { businessId: { eq: business.id } },
          })
        );
        setSchedules(resultSchedules.data.listSchedules.items);
      }
    })(); //IIFE
  }, [!adminOverride ? business.id : adminOverride, geofences.length]);

  const codifyGeofence = async (maps, circle) => {
    currentGeofence = circle;
    var r = circle.getRadius();
    console.log(r);
    var c = circle.getCenter();
    var degreeStep = 360 / 9;

    setCenter({ lat: c.lat(), lng: c.lng() });
    setRadius(r);

    for (var i = 0; i < 9; i++) {
      var gpos = maps.geometry.spherical.computeOffset(c, r, degreeStep * i);
      points.push([gpos.lat(), gpos.lng()]);
    }

    // Duplicate the last point to close the geojson ring
    points.push(points[0]);
    setGeofence(points);
  };

  const handleApiLoaded = async (map, maps) => {
    // use map and maps objects
    let drawingManager = new maps.drawing.DrawingManager({
      drawingMode: maps.drawing.OverlayType.CIRCLE,
      drawingControl: true,
      drawingControlOptions: {
        position: maps.ControlPosition.TOP_CENTER,
        drawingModes: [maps.drawing.OverlayType.CIRCLE],
      },
      circleOptions: {
        strokeColor: "#00FF00",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#00FF00",
        fillOpacity: 0.35,
        clickable: true,
        editable: true,
        zIndex: 1,
      },
    });

    drawingManager.setMap(map);

    // when someone completes the circle, codify the points
    maps.event.addListener(
      drawingManager,
      "circlecomplete",
      async function (circle) {
        if (points.length > 0) {
          points = [];
          currentGeofence.setMap(null);
        }
        codifyGeofence(maps, circle);
      }
    );
  };

  return (
    <>
      <div className="title_container">
        <h1>Geofence Manager Page</h1>
        {user.role === "Admin" ? (
          !adminOverride ? (
            <Button className="add_button" onClick={handleOpenGeoForm}>
              + New Geofence
            </Button>
          ) : (
            <Button className="add_button" onClick={handleOpenGeoForm} disabled>
              + New Geofence
            </Button>
          )
        ) : null}
      </div>
      <GeofenceList
        user={user}
        schedules={schedules}
        geofences={geofences}
        setGeofences={setGeofences}
        deleteGeo={handleClickOpen}
      />
      <Modal
        sx={{
          "& > .MuiBackdrop-root": {
            backdropFilter: "blur(2px)",
            backgroundColor: "#FFFFFF55",
          },
        }}
        open={openGeoForm}
        onClose={handleCloseGeoForm}
      >
        <Box className="add_input_margin padding_3 smaller_frame">
          <Flex
            as="form"
            padding={30}
            direction="column"
            onSubmit={handleGeoSubmit}
          >
            <TextField
              name="name"
              label="Geofence Name"
              placeholder="Geofence name"
              onChange={handleGeoFormChange}
              isRequired={true}
            />
            <SelectField onChange={handleScheduleSelection} required>
              <option>Please select an ad schedule</option>
              {schedules.map((schedule) => (
                <option key={schedule.id}>{schedule.name}</option>
              ))}
            </SelectField>
            <div style={{ width: "100%", height: 350 }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyAdpkRJZys3Y4dfo8iXEIS2CG6Md4JCIvw",
                  libraries: ["geometry", "drawing"],
                }}
                defaultCenter={{ lat: 34.8526, lng: -82.394 }}
                defaultZoom={15}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) =>
                  handleApiLoaded(map, maps)
                }
              ></GoogleMapReact>
            </div>
            <Button
              type="submit"
              variation="primary"
              backgroundColor={"#429321"}
            >
              Submit
            </Button>
          </Flex>
        </Box>
      </Modal>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Delete ${geoId}?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this geofence? Doing so will remove
            this geofence and all associated data
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => handleGeoDelete(geoId)} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Geofences;
