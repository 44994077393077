import React, { useState, useEffect } from "react";
import {
  Card,
  Text,
  Button,
  Image,
  TextField,
  SelectField,
  Flex,
} from "@aws-amplify/ui-react";
import { API, graphqlOperation, Geo } from "aws-amplify";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import GoogleMapReact from "google-map-react";

const baseUrl =
  "https://dtgvlv2f04838c8631b4596b8b3acde06c1239b145859-dev.s3.amazonaws.com/public/";

const cardStyle = {
  border: "0.5px solid lightgrey",
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
  justifyItems: "center",
  alignItems: "center",
};

const GeofenceCard = ({
  user,
  geofenceItem,
  geofences,
  setGeofences,
  schedules,
  deleteGeo,
}) => {
  // geofence state variables
  const [geofenceId, setGeofenceId] = useState("");
  const [openGeoForm, setOpenGeoForm] = useState(false);
  const [geoFormData, setGeoFormData] = useState({ name: "", schedule: "" });
  const [center, setCenter] = useState({
    lat: geofenceItem.lat,
    lng: geofenceItem.lng,
  });
  const [radius, setRadius] = useState(0);

  const [geofence, setGeofence] = useState([]);
  const handleOpenGeoForm = (id, name, schedule) => {
    setOpenGeoForm(true);
    setGeoFormData({ name: name, schedule: schedule });
    setGeofenceId(id);
  };
  const handleCloseGeoForm = () => setOpenGeoForm(false);
  const handleGeoFormChange = (e) => {
    setGeoFormData({ ...geoFormData, [e.target.name]: e.target.value });
  };
  const handleScheduleSelection = (e) => {
    const schedule = schedules.find(
      (element) => element.name === e.target.value
    );
    setGeoFormData({ ...geoFormData, schedule: schedule });
  };

  const handleGeoSubmit = async (e) => {
    e.preventDefault();
    const { name, schedule } = geoFormData;

    // set the API parameters
    const value = {
      id: geofenceId,
      name,
      geofenceScheduleId: schedule.id,
    };
    // send the new geofence info to the dB
    const result = await API.graphql(
      graphqlOperation(mutations.updateGeofence, { input: value })
    );

    let newArr = geofences.filter((x) => x.id !== geofenceId);
    setGeofences([...newArr, result.data.updateGeofence]);
    handleCloseGeoForm();
  };

  const [openContent, setOpenContent] = useState(false);
  const handleOpenContent = (geo) => {
    setOpenContent(true);
    setGeofence(geo);
  };
  const handleCloseContent = () => setOpenContent(false);

  const [openMap, setOpenMap] = useState(false);
  const handleOpenMap = () => setOpenMap(true);
  const handleCloseMap = () => setOpenMap(false);

  const [shift, setShift] = useState(null);
  const [event, setEvent] = useState(null);

  const week = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  const d = new Date();
  let day = week[d.getDay()];
  let hour = d.getHours();

  var path = [],
    geofenceDrawing;

  useEffect(() => {
    (async () => {
      const shifts = await API.graphql(
        graphqlOperation(queries.listShifts, {
          filter: {
            scheduleShiftsId: { eq: geofenceItem.schedule.id },
            weekday: { eq: day },
            startTime: { le: hour },
            endTime: { gt: hour },
          },
        })
      );
      console.log(shifts);
      setShift(shifts.data.listShifts.items[0]);
    })(); //IIFE

    (async () => {
      console.log(d.toLocaleDateString());
      const events = await API.graphql(
        graphqlOperation(queries.listEvents, {
          filter: {
            businessId: { eq: geofenceItem.businessId },
            date: { eq: d.toLocaleDateString() },
            startTime: { le: hour },
            endTime: { gt: hour },
          },
        })
      );
      console.log(events);
      setEvent(events.data.listEvents.items[0]);
    })(); //IIFE
  }, [geofenceItem.schedule.id]);

  const submitGeofence = async (maps, circle, e) => {
    geofenceDrawing = circle;
    var r = circle.getRadius();
    var c = circle.getCenter();
    setCenter({ lat: c.lat(), lng: c.lng() });
    var degreeStep = 360 / 9;

    for (var i = 0; i < 9; i++) {
      var gpos = maps.geometry.spherical.computeOffset(c, r, degreeStep * i);
      path.push([gpos.lat(), gpos.lng()]);
    }

    // Duplicate the last point to close the geojson ring
    path.push(path[0]);

    let saveGeofenceResults;
    try {
      saveGeofenceResults = await Geo.saveGeofences({
        geofenceId: geofenceItem.id,
        geometry: {
          polygon: [path],
        },
      });
    } catch (error) {
      // errors thrown by input validations of `createGeofence`
      throw error;
    }

    if (saveGeofenceResults.errors.length > 0) {
      // error handling that are from the underlying API calls
      console.log(`Success count: ${saveGeofenceResults.successes.length}`);
      console.log(`Error count: ${saveGeofenceResults.errors.length}`);
    } else {
      console.log("Success! Check AWS for the geofence");
    }

    let geoUpdate = await API.graphql(
      graphqlOperation(mutations.updateGeofence, {
        input: { id: geofenceItem.id, lat: c.lat(), lng: c.lng(), radius: r },
      })
    );
    console.log(geoUpdate);
  };

  const handleApiLoaded = async (map, maps) => {
    // get the current path, if any
    let result, drawingManager;
    try {
      result = await Geo.getGeofence(geofenceItem.id);
      for (let i = 0; i < result.geometry.polygon[0].length; i++) {
        let x = {
          lat: result.geometry.polygon[0][i][0],
          lng: result.geometry.polygon[0][i][1],
        };
        path.push(x);
      }

      geofenceDrawing = new maps.Polygon({
        paths: path,
        strokeColor: "#00FF00",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#00FF00",
        fillOpacity: 0.35,
      });

      geofenceDrawing.setMap(map);
    } catch (err) {
      console.log(err);
    }

    // use map and maps objects
    drawingManager = new maps.drawing.DrawingManager({
      drawingControl: true,
      drawingControlOptions: {
        position: maps.ControlPosition.TOP_CENTER,
        drawingModes: [maps.drawing.OverlayType.CIRCLE],
      },
      circleOptions: {
        strokeColor: "#00FF00",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#00FF00",
        fillOpacity: 0.35,
        clickable: true,
        editable: true,
        zIndex: 1,
      },
    });

    drawingManager.setMap(map);

    maps.event.addListener(
      drawingManager,
      "circlecomplete",
      async function (circle) {
        if (path.length > 0) {
          path = [];
          geofenceDrawing.setMap(null);
        }
        submitGeofence(maps, circle);
      }
    );
  };

  return (
    <>
      <Card style={cardStyle}>
        <div className="card_btn_wrapper">
          <Button
            onClick={() => handleOpenContent(geofenceItem)}
            style={buttonStyle}
          >
            View Ad
          </Button>
        </div>
        <Text style={textStyle}>{geofenceItem.name}</Text>
        <Text style={textStyle}>{geofenceItem.schedule.name}</Text>
        <Text style={textStyle}>{geofenceItem.lastSeen}</Text>
        <div className="card_btn_wrapper">
          {user.role !== "Manager" ? (
            <>
              <IconButton
                aria-label="delete"
                size="large"
                onClick={() =>
                  handleOpenGeoForm(
                    geofenceItem.id,
                    geofenceItem.name,
                    geofenceItem.schedule
                  )
                }
              >
                <EditIcon />
              </IconButton>
              <IconButton
                aria-label="delete"
                onClick={() => deleteGeo(geofenceItem.id)}
              >
                <DeleteIcon />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton aria-label="delete" size="large" disabled>
                <EditIcon />
              </IconButton>
              <IconButton aria-label="delete" disabled>
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </div>
        <div className="card_btn_wrapper">
          <Button onClick={handleOpenMap} style={buttonStyle}>
            Set Geofence
          </Button>
        </div>
        <Modal
          sx={{
            "& > .MuiBackdrop-root": {
              backdropFilter: "blur(2px)",
              backgroundColor: "#FFFFFF55",
            },
          }}
          open={openMap}
          onClose={handleCloseMap}
        >
          <Box className="add_input_margin larger_frame">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyAdpkRJZys3Y4dfo8iXEIS2CG6Md4JCIvw",
                libraries: ["drawing", "geometry"],
              }}
              defaultCenter={{ lat: center.lat, lng: center.lng }}
              defaultZoom={15}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            ></GoogleMapReact>
          </Box>
        </Modal>
        <Modal
          sx={{
            "& > .MuiBackdrop-root": {
              backdropFilter: "blur(2px)",
              backgroundColor: "#FFFFFF55",
            },
          }}
          open={openGeoForm}
          onClose={handleCloseGeoForm}
        >
          <Box className="add_input_margin padding_3 smaller_frame">
            <Flex
              as="form"
              padding={30}
              direction="column"
              onSubmit={handleGeoSubmit}
            >
              <TextField
                name="name"
                label="Geofence Name"
                value={geoFormData.name}
                onChange={handleGeoFormChange}
                isRequired={true}
              />
              <SelectField
                onChange={handleScheduleSelection}
                value={geoFormData.schedule.name}
                required
              >
                <option value="">Please select an ad schedule</option>
                {schedules.map((schedule) => (
                  <option key={schedule.id}>{schedule.name}</option>
                ))}
              </SelectField>
              <Button
                type="submit"
                variation="primary"
                backgroundColor={"#429321"}
              >
                Submit
              </Button>
            </Flex>
          </Box>
        </Modal>
      </Card>
      <Modal
        sx={{
          "& > .MuiBackdrop-root": {
            backdropFilter: "blur(2px)",
            backgroundColor: "#FFFFFF55",
          },
        }}
        open={openContent}
        onClose={handleCloseContent}
      >
        {event ? (
          event.ad.type === "Image" ? (
            <Box className="show_ad">
              <Image
                alt="Content"
                src={baseUrl + event.ad.url}
                width="400px"
                height="667px"
              />
            </Box>
          ) : (
            <Box className="add_input_margin larger_frame">
              <iframe src={event.ad.url}></iframe>
            </Box>
          )
        ) : shift ? (
          shift.ad.type === "Image" ? (
            <Box className="show_ad">
              <Image
                alt="Content"
                src={baseUrl + shift.ad.url}
                width="400px"
                height="667px"
              />
            </Box>
          ) : (
            <Box className="add_input_margin larger_frame">
              <iframe src={shift.ad.url}></iframe>
            </Box>
          )
        ) : (
          <Box className="show_ad">
            <h1>Currently no ad running</h1>
          </Box>
        )}
      </Modal>
    </>
  );
};

const textStyle = {
  fontSize: "12px",
  textAlign: "center",
};

const buttonStyle = { backgroundColor: "#429321", color: "white" };

export default GeofenceCard;
