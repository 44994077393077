import { Card, Text, Button, Image, TextField, SelectField, Flex } from '@aws-amplify/ui-react';
import { API, graphqlOperation } from 'aws-amplify';
import React, { useState, useEffect } from 'react';
import { updateEvent } from '../graphql/mutations';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { listAds } from '../graphql/queries';

const baseUrl = "https://dtgvlv2f04838c8631b4596b8b3acde06c1239b145859-dev.s3.amazonaws.com/public/"

const startHours = ["12:00AM", "1:00AM", "2:00AM", "3:00AM", "4:00AM", "5:00AM", "6:00AM", "7:00AM", "8:00AM", "9:00AM", "10:00AM", "11:00AM", "12:00PM", "1:00PM", "2:00PM", "3:00PM", "4:00PM", "5:00PM", "6:00PM", "7:00PM", "8:00PM", "9:00PM", "10:00PM", "11:00PM"];
const endHours = ["12:00AM", "1:00AM", "2:00AM", "3:00AM", "4:00AM", "5:00AM", "6:00AM", "7:00AM", "8:00AM", "9:00AM", "10:00AM", "11:00AM", "12:00PM", "1:00PM", "2:00PM", "3:00PM", "4:00PM", "5:00PM", "6:00PM", "7:00PM", "8:00PM", "9:00PM", "10:00PM", "11:00PM", "12:00AM"];
const hours = ['12', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'];

function EventCard({ event, events, setEvents, user, handleDelete }) {
    const [eventFormData, setEventFormData] = useState({startTime: '', endTime: '', ad: '', date: null, name: ''});
    const [openContent, setOpenContent] = useState(false);
    const handleOpenContent = () => setOpenContent(true);
    const handleCloseContent = () => setOpenContent(false);

    const [openEventForm, setOpenEventForm] = useState(false);
    const handleCloseEventForm = () => {
      setOpenEventForm(false);
      setEventId("");
    }
    
    const [ads, setAds] = useState([]);
    const [eventId, setEventId] = useState("");
    const handleOpenEventForm = (id, ad, start, end, date, name) => {
        console.log(name);
      setOpenEventForm(true);
      setEventFormData({startTime: start, endTime: end, ad: ad, date: new Date(date), name: name});
      setEventId(id);
    }
  
    const handleEventFormChange = (e) => {
      setEventFormData({...eventFormData, [e.target.name]: e.target.value});
    }
  
    const handleAdSelection = (e) => {
      const ad = ads.find((element) => element.name === e.target.value);
      setEventFormData({...eventFormData, ad: ad});
    };
  
    const handleEventSubmit = async (e) => {
        e.preventDefault();
        const {ad, date, startTime, endTime, name} = eventFormData;

        console.log(new Date(date.getFullYear(), date.getMonth(), date.getDate(), endTime))
        console.log(new Date());
        if (new Date(date.getFullYear(), date.getMonth(), date.getDate(), endTime) <= new Date()) {
            alert('Cannot update event to a past date.');
            return;
        }

        const value = {
            id: eventId,
            eventAdId: ad.id,
            date: date.toLocaleDateString(),
            name,
            startTime,
            endTime
        }
        const result = await API.graphql(graphqlOperation(updateEvent, { input: value }));
        let newArr = events.filter(x => x.id !== eventId);
        setEvents([...newArr, result.data.updateEvent])
        handleCloseEventForm();
    }

    useEffect(() => {
        //useEffect function must return a cleanup function or nothing
        (async () => {
          const result = await API.graphql(graphqlOperation(listAds, {filter: {businessId: {eq: event.businessId}}}));
          console.log(result);
          setAds(result.data.listAds.items);
        })();//IIFE
    
    
    }, [event.id])

    return (
        <>
            <Card style={cardStyle}>
                <h2 style={textStyle}>{event.name}</h2>
                <h2 style={textStyle}>{new Date(event.date).toLocaleDateString()} @ {hours[(event.startTime)%12]}:00{(event.startTime < 12) ? 'AM' : 'PM'} to {hours[(event.endTime)%12]}{':00'}{(event.endTime <= 12 || event.endTime == 24) ? 'AM' : 'PM'}</h2>
                <p></p>
                <p></p>
                <div>
                    <h2 style={textStyle}>{event.ad.name}</h2>
                    <Button onClick={handleOpenContent} style={buttonStyle}>View</Button>
                </div>
                <div className="card_btn_wrapper">
                    {user.role !== "Manager" ?
                        <>
                            <IconButton aria-label="edit" size="large" onClick={()=>handleOpenEventForm(event.id, event.ad, event.startTime, event.endTime, event.date, event.name)}>
                                <EditIcon />
                            </IconButton>
                            <IconButton aria-label="delete" onClick={()=>handleDelete(event.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </> :
                        <>
                            <IconButton aria-label="edit" size="large" disabled>
                                <EditIcon />
                            </IconButton>
                            <IconButton aria-label="delete" disabled>
                                <DeleteIcon />
                            </IconButton>
                        </>}
                </div>
            </Card>
            <Modal sx={{ 
                "& > .MuiBackdrop-root" : {
                        backdropFilter: "blur(2px)",
                        backgroundColor: "#FFFFFF55"
                    }
                }} open={openEventForm} onClose={handleCloseEventForm}>
                <Box className="add_input_margin padding_3 smaller_frame">
                    <Flex as="form" padding={30} direction="column" onSubmit={handleEventSubmit}>
                        <TextField value={eventFormData.name} name="name" label="Event Name" placeholder="Special Event Name" onChange={handleEventFormChange} isRequired={true}/>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <Text style={{ color: 'hsl(210deg 25% 25%)' }}>Event Date</Text>
                            <DatePicker
                                selected={eventFormData.date}
                                onChange={date => setEventFormData({...eventFormData, date: date})}
                                placeholderText={'Select a date'}
                                className='date_picker_style'
                            />
                        </div>
                        <Flex direction="row">
                            <SelectField
                                name="startTime"
                                label="Start Time"
                                placeholder="Event start"
                                onChange={handleEventFormChange}
                                value={eventFormData.startTime}
                                required>
                                {startHours.map((hour, index) =>
                                    <option value={index} key={index}>{hour}</option>
                                )}
                            </SelectField>
                            {eventFormData.startTime === '' ? <SelectField placeholder="Event end" label="End Time" disabled></SelectField> :
                            <SelectField
                                name="endTime"
                                label="End Time"
                                placeholder="shift end"
                                onChange={handleEventFormChange}
                                value={eventFormData.endTime}
                                required>
                                {endHours.map((hour, index) =>
                                    (index > eventFormData.startTime) ? <option value={index} key={index}>{hour}</option> : null
                                )}
                            </SelectField>}
                        </Flex>
                        <SelectField
                            onChange={handleAdSelection}
                            label="Running Ad"
                            value={eventFormData.ad.name}
                            required>
                            <option value="">Please select an ad</option>
                            {ads.map((ad) =>
                                <option key={ad.id}>{ad.name}</option>)}
                        </SelectField>
                        <Button type="submit" variation="primary" backgroundColor={"#429321"}>Submit</Button>
                    </Flex>
                </Box>
            </Modal>
            <Modal sx={{ 
                "& > .MuiBackdrop-root" : {
                        backdropFilter: "blur(2px)",
                        backgroundColor: "#FFFFFF55"
                    }
                }} open={openContent} onClose={handleCloseContent}>
                {event.ad.type === "Image" ?
                <Box className="show_ad">
                    <Image
                    alt="Content"
                    src={baseUrl+event.ad.url}
                    width="400px"
                    height="667px"/>
                </Box> :
                <Box className="add_input_margin larger_frame">
                    <iframe title="website_ad_holder" src={event.ad.url}></iframe>
                </Box>}
            </Modal>
        </>
    );
}

const textStyle = {
    fontSize: '12px',
    textAlign: 'center'
}

const buttonStyle = { backgroundColor: '#429321', color: 'white', fontSize: 8, width: '30%' }

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 6,
    overflow: 'auto'
};

const style2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflow: 'auto'
};

const cardStyle = {
    border: '0.5px solid lightgrey',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
    justifyItems: 'center',
    alignItems: 'center'
}


export default EventCard;