import { CircularProgress, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import WeekdayBreakdown from './WeekdayBreakdown';
import { API, graphqlOperation } from 'aws-amplify';
import { analyticsByBusinessId } from '../graphql/queries';

const times = ['12am', '1am', '2am', '3am', '4am', '5am', '6am', '7am', '8am', '9am', '10am', '11am', '12am', '1pm', '2pm', '3pm', '4pm', '5pm', '6pm', '7pm', '8pm', '9pm', '10pm', '11pm'];

// helper function
function getWeekdayFromISODate(isoDate) {
  const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const dateObj = new Date(isoDate);
  const weekdayIndex = dateObj.getDay();
  
  return weekdays[weekdayIndex];
}

const TimeHeatMap = ({ business, startDate, endDate }) => {
  const [maxValue, setMaxValue] = useState(0);
  const [loading, setLoading] = useState(false);

  const [mon, setMon] = useState([]);
  const [tues, setTues] = useState([]);
  const [wed, setWed] = useState([]);
  const [thur, setThur] = useState([]);
  const [fri, setFri] = useState([]);
  const [sat, setSat] = useState([]);
  const [sun, setSun] = useState([]);

  useEffect(() => {
    if (!startDate || !endDate) return;
    else {
      console.log("calling analytics table");
      setLoading(true);
      (async() => {
        // get all the analytics for the business
        const fetchedData = await API.graphql(graphqlOperation(analyticsByBusinessId, { businessId: business.id, limit: 500 }));
          
        // filter out by interval
        const data = fetchedData.data.analyticsByBusinessId.items.filter(entry => new Date(entry.createdAt) < endDate && new Date(entry.createdAt) > startDate);

        // segment data into the separate days for the weekly breakdown
        setMon(data.filter(value => getWeekdayFromISODate(value.createdAt) === "Monday"));
        setTues(data.filter(value => getWeekdayFromISODate(value.createdAt) === "Tuesday"));
        setWed(data.filter(value => getWeekdayFromISODate(value.createdAt) === "Wednesday"));
        setThur(data.filter(value => getWeekdayFromISODate(value.createdAt) === "Thursday"));
        setFri(data.filter(value => getWeekdayFromISODate(value.createdAt) === "Friday"));
        setSat(data.filter(value => getWeekdayFromISODate(value.createdAt) === "Saturday"));
        setSun(data.filter(value => getWeekdayFromISODate(value.createdAt) === "Sunday"));

        setLoading(false);
      })();
    }

  }, [startDate, endDate, business.id]);

  return (
    <div className="center_left_analytics">
      {!startDate && !endDate ? <h3>Select a time interval</h3> : 
      loading ? <Skeleton variant="rectangular" style={{ justifySelf: 'center', width: '80%', height: '80%' }} /> :
      <div style={{ display: 'flex', flexDirection: 'column', width: '95%', height: '90%', justifyContent: 'center', alignItems: 'center' }}>
        <div className='heatmap_grid'>
          <div></div>
          {times.map((time, i) => <p key={i} className='labelCell'>{time}</p>)}
        </div>
        <WeekdayBreakdown day={sun} />
        <WeekdayBreakdown day={mon} />
        <WeekdayBreakdown day={tues} />
        <WeekdayBreakdown day={wed} />
        <WeekdayBreakdown day={thur} />
        <WeekdayBreakdown day={fri} />
        <WeekdayBreakdown day={sat} />
        <div className='heatRangeStyle'>
          <div className='heatRangeCell' style={{ backgroundColor: '#FEFED9' }}></div>
          <div className='heatRangeCell' style={{ backgroundColor: '#EBF6B1' }}></div>
          <div className='heatRangeCell' style={{ backgroundColor: '#C6E7B4' }}></div>
          <div className='heatRangeCell' style={{ backgroundColor: '#82CCBB' }}></div>
          <div className='heatRangeCell' style={{ backgroundColor: '#4BB5C4' }}></div>
          <div className='heatRangeCell' style={{ backgroundColor: '#3192C0' }}></div>
          <div className='heatRangeCell' style={{ backgroundColor: '#3060A8' }}></div>
          <div className='heatRangeCell' style={{ backgroundColor: '#2F3994' }}></div>
          <div className='heatRangeCell' style={{ backgroundColor: '#122058' }}></div>
        </div>
        <div className='heatRangeStyle'>
          <div className='heatRangeCell'>{'<'}4</div>
          <div className='heatRangeCell'>{'<'}12</div>
          <div className='heatRangeCell'>{'<'}36</div>
          <div className='heatRangeCell'>{'<'}64</div>
          <div className='heatRangeCell'>{'<'}136</div>
          <div className='heatRangeCell'>{'<'}194</div>
          <div className='heatRangeCell'>{'<'}235</div>
          <div className='heatRangeCell'>{'<'}448</div>
          <div className='heatRangeCell'>{'>'}448</div>
        </div>
      </div>}
    </div>
  )
}

export default TimeHeatMap;
