import React, { useState, useEffect, useRef } from 'react';
import { Collection, Card, Text, Button, Image, TextField, SelectField, Flex } from '@aws-amplify/ui-react'
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { QRCodeCanvas } from 'qrcode.react';
import { Download } from '@mui/icons-material';

const baseUrl = "https://dtgvlv2f04838c8631b4596b8b3acde06c1239b145859-dev.s3.amazonaws.com/public/"

const NFCCard = ({ nfc, nfcs, setNfcs, schedules, deleteNfc, user, analytics }) => {
  const [openContent, setOpenContent] = useState(false);
  const handleOpenContent = () => setOpenContent(true);
  const handleCloseContent = () => setOpenContent(false);
  const [taps, setTaps] = useState(0);

  // state variables for displaying nfc update form
  const [businessName, setBusinessName] = useState('');
  const [nfcId, setNfcId] = useState("");
  const [openNfcForm, setOpenNfcForm] = useState(false);
  const [nfcFormData, setNfcFormData] = useState({name: '', value: '', nfcType: '', schedule: ''});

  const handleOpenNfcForm = (id, name, value, nfcType, schedule) => {
    setOpenNfcForm(true);
    setNfcFormData({name: name, value: value, nfcType: nfcType, schedule: schedule});
    setNfcId(id);
  }
  const handleCloseNfcForm = () => setOpenNfcForm(false);
  const handleNfcFormChange = (e) => {
    setNfcFormData({...nfcFormData, [e.target.name]: e.target.value});
  }
  const handleNfcSubmit = async (e) => {
    e.preventDefault();
    const {name, value, nfcType, schedule} = nfcFormData;
    let nfcInput;

    if (nfcType === "ad") {
      nfcInput = {
        id: nfcId,
        name,
        nfcType,
        nfcScheduleId: schedule.id
      }
    } else {
      nfcInput = {
        id: nfcId,
        name,
        nfcType,
        value
      }
    }

    const result = await API.graphql(graphqlOperation(mutations.updateNfc, {input: nfcInput }));

    let newArr = nfcs.filter(x => x.id !== nfcId);
    setNfcs([...newArr, result.data.updateNfc])
    handleCloseNfcForm();
  }

  const handleScheduleSelection = (e) => {
    const schedule = schedules.find((element) => element.name === e.target.value);
    setNfcFormData({...nfcFormData, schedule: schedule});
  };

  const handleDownload = (canvas) => {
    if(!canvas) return;
    const link = document.createElement("a");
    link.download = `qrcode_${nfc.name}.png`;
    link.href = canvas.toDataURL();
    link.click();
  };

  const [shift, setShift] = useState(null);
  const [event, setEvent] = useState(null);

  const week = ["sunday","monday","tuesday","wednesday","thursday","friday","saturday"];
  const d = new Date();
  let day = week[d.getDay()];
  let hour = d.getHours();

  useEffect(() => {
    (async () => {
      const busName = await API.graphql(graphqlOperation(queries.getBusiness, { id: nfc.businessId }));
      setBusinessName(busName.data.getBusiness.name)
    })();

    (async () => {
      const tapsRes = analytics.filter(analytic => analytic.deviceName === nfc.name);
      console.log(tapsRes);
      setTaps(tapsRes.length)
    })();
  }, [])

  useEffect(()=>{

    if (nfc.nfcType === "ad") {
      (async () => {
        const shifts = await API.graphql(graphqlOperation(queries.listShifts, {filter: {scheduleShiftsId: {eq: nfc.schedule.id}, weekday: {eq: day}, startTime: {le: hour}, endTime: {gt: hour} }}));
        console.log(shifts);
        setShift(shifts.data.listShifts.items[0]);
      })();//IIFE

      (async () => {
        console.log(d.toLocaleDateString());
        const events = await API.graphql(graphqlOperation(queries.listEvents, {filter: {businessId: {eq: nfc.businessId}, date: {eq: d.toLocaleDateString()}, startTime: {le: hour}, endTime: {gt: hour} }}));
        console.log(events);
        setEvent(events.data.listEvents.items[0]);
      })();//IIFE
  
    }

  }, [nfc.nfcType === "ad" ? nfc.schedule.id : null])

  return (
    <>
      <Card style={cardStyle}>
        <div className={`qr_holder_${nfc.id}`}>
          <QRCodeCanvas size={65} value={'https://d1os8kn99h.execute-api.us-east-1.amazonaws.com/dev/returnnfc/'+nfc.id} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', minWidth: 0, overflowX: "auto" }}>
          <Text style={{ fontSize: 16}}>{nfc.name}</Text>
          {nfc.nfcType === "webpage" ? <Text style={{ fontSize: 8, color: 'grey', marginBlock: 5}}>Link: {nfc.value}</Text> : <Text style={{ fontSize: 8, color: 'grey', marginBlock: 5}}>Schedule: {nfc.schedule.name}</Text>}
          <Text style={{ fontSize: 8, color: 'grey', marginBlock: 5}}>id: {nfc.id}</Text>
          <Button onClick={handleOpenContent} style={buttonStyle}>View</Button>
        </div>
        <Text style={textStyle}>{taps}</Text>
        <Text style={textStyle}>{businessName}</Text>
        <Text style={textStyle}>{nfc.nfcType}</Text>
        <Text style={textStyle}>{new Date(nfc.createdAt).toLocaleDateString()}</Text>
        <div className="card_btn_wrapper">
          {user.role !== "Manager" ?
            <>
              <IconButton aria-label="delete" size="large" onClick={()=>handleOpenNfcForm(nfc.id, nfc.name, nfc.value, nfc.nfcType, nfc.schedule)}>
                <EditIcon />
              </IconButton>
              <IconButton aria-label="delete" onClick={()=>deleteNfc(nfc.id)}>
                <DeleteIcon />
              </IconButton>
              <IconButton aria-label="download" onClick={()=>handleDownload(document.querySelector(`.qr_holder_${nfc.id} canvas`))}>
                <Download />
              </IconButton>
            </> :
            <>
              <IconButton aria-label="delete" size="large" disabled>
                <EditIcon />
              </IconButton>
              <IconButton aria-label="delete" disabled>
                <DeleteIcon />
              </IconButton>
              <IconButton aria-label="download" disabled>
                <Download />
              </IconButton>
            </>}
        </div>
      </Card>
      <Modal sx={{ 
            "& > .MuiBackdrop-root" : {
                    backdropFilter: "blur(2px)",
                    backgroundColor: "#FFFFFF55"
                  }
            }} open={openContent} onClose={handleCloseContent}>
          {nfc.nfcType === "webpage" ?
            <Box className="add_input_margin larger_frame">
              <iframe src={nfc.value}></iframe>
            </Box> :
            event ? 
              (event.ad.type) === "Image" ?
                <Box className="show_ad">
                  <Image
                  alt="Content"
                  src={baseUrl+event.ad.url}
                  width="400px"
                  height="667px"/>
                </Box> :
                <Box className="add_input_margin larger_frame">
                  <iframe src={event.ad.url}></iframe>
                </Box> :
            shift ?
              (shift.ad.type) === "Image" ?
              <Box className="show_ad">
                <Image
                  alt="Content"
                  src={baseUrl+shift.ad.url}
                  width="400px"
                  height="667px"/>
              </Box> :
              <Box className="add_input_margin larger_frame">
                <iframe src={shift.ad.url}></iframe>
              </Box> : 
              <Box className="show_ad">
                <h1>Currently no ad running</h1>
              </Box>}
      </Modal>
      <Modal sx={{ 
            "& > .MuiBackdrop-root" : {
                    backdropFilter: "blur(2px)",
                    backgroundColor: "#FFFFFF55"
                  }
            }} open={openNfcForm} onClose={handleCloseNfcForm}>
        <Box className="add_input_margin padding_3 smaller_frame">
          <Flex as="form" padding={30} direction="column" onSubmit={handleNfcSubmit}>
            <TextField name="name" label="NFC Name" value={nfcFormData.name} onChange={handleNfcFormChange} isRequired={true}/>
            <SelectField
              name="nfcType"
              label="NFC Type"
              value={nfcFormData.nfcType}
              disabled>
                <option>{nfcFormData.nfcType}</option>
            </SelectField>
            {nfcFormData.nfcType === "webpage" ?
              <TextField name="value" label="Webpage URL" value={nfcFormData.value} onChange={handleNfcFormChange} isRequired={true}/> :
              <SelectField label="Ad Schedule" value={nfcFormData.schedule.name} onChange={handleScheduleSelection} required>
                <option value="">Please select an ad schedule</option>
                {schedules.map((schedule) =>
                  <option key={schedule.id} value={schedule.name}>{schedule.name}</option>)}
              </SelectField>}
            <Button type="submit" variation="primary" backgroundColor={"#429321"}>Submit</Button>
          </Flex>
        </Box>
      </Modal>
    </>
  );
}

const textStyle = {
  fontSize: '12px',
  textAlign: 'center'
}

const buttonStyle = { backgroundColor: '#429321', color: 'white', fontSize: 8, width: '30%' }

const cardStyle = {
  border: '0.5px solid lightgrey',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
  justifyItems: 'center',
  alignItems: 'center'
}

export default NFCCard;
