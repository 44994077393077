import { API, graphqlOperation } from 'aws-amplify';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { listBeacons, listGeofences, listNfcs, listQrs } from '../graphql/queries';

function UsageComponent({ schedule }) {
    const [devices, setDevices] = useState([]);

    useEffect(() => {
        (async () => {
            // get all the possible devices that could be running a schedule
            const beaconsRes = await API.graphql(graphqlOperation(listBeacons, { filter: { beaconScheduleId: {eq: schedule.id }}}));
            const geofencesRes = await API.graphql(graphqlOperation(listGeofences, { filter: { geofenceScheduleId: {eq: schedule.id }}}));
            const qrsRes = await API.graphql(graphqlOperation(listQrs, { filter: { qrScheduleId: {eq: schedule.id }}}));
            const nfcsRes = await API.graphql(graphqlOperation(listNfcs, { filter: { nfcScheduleId: {eq: schedule.id }}}));

            const temp = beaconsRes.data.listBeacons.items.concat(geofencesRes.data.listGeofences.items, qrsRes.data.listQrs.items, nfcsRes.data.listNfcs.items);
            setDevices(temp);
        })();
    }, [])

    return (
        <div style={{ width: '70%' }}>
            <p style={{ marginBlock: 2, color: devices.length === 0 ? 'red' : 'green' }}>&#x2022;{devices.length === 0 ? 'Not in use' : 'In use'}</p>
            {devices.length > 0 ? 
            <>
                <p style={{ fontSize: 10, margin: 0 }}>By: </p>
                <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
                    {devices.map((device, index) => <p key={device.id} style={{ fontSize: 8, margin: 1 }}>{device.name}{index !== devices.length-1 ? ',' : null}</p>)}
                </div>
            </> : null}
        </div>
    );
}

export default UsageComponent;