import * as React from 'react';
import beaconAsset from '../assets/beacon_menu_asset.png';
import useMediaQuery from '@mui/material/useMediaQuery';
import Tooltip from '@mui/material/Tooltip';

import logico from '../assets/menu/LOGOUT.png';
import analico from '../assets/menu/ANALYTICS.png';
import beaico from '../assets/menu/BEACONS.png';
import bizico from '../assets/menu/BIZINFO.png';
import adico from '../assets/menu/ADS.png';
import qrico from '../assets/menu/QR.png';
import nfcico from '../assets/menu/NFC.png';
import resetico from '../assets/menu/RESET.png';
import cancico from '../assets/menu/CANCEL.png';
import geoico from '../assets/menu/GEOFENCES.png';
import helpico from '../assets/menu/HELP.png';
import schico from '../assets/menu/SCHEDULER.png';
import { Auth } from 'aws-amplify';

const MainMenu = (props) => {
  const matches = useMediaQuery('(min-width:600px)');
  let imgStyle;
  let buttons = document.getElementsByClassName('buttons_main');

  if (matches) {
    imgStyle = {
      height: '175px',
      width: '175px'
    }
  }
  else imgStyle = {
    height: '125px',
    width: '125px'
  }

  React.useEffect(()=>{
    let theta = [0, Math.PI / 6, Math.PI / 3, Math.PI / 2, 2 * (Math.PI / 3), 5 * (Math.PI / 6), Math.PI, 7 * (Math.PI / 6), 4 * (Math.PI / 3), 3 * (Math.PI / 2), 5 * (Math.PI / 3), 11 * (Math.PI / 6)];
    let radius = 200;
    var mainHeight = parseInt(window.getComputedStyle(document.getElementById('main_menu')).height.slice(0, -2));

    for (let i = 0; i < buttons.length; i++) {
      buttons[i].posx = Math.round(radius * (Math.cos(theta[i]))) + 'px';
      buttons[i].posy = Math.round(radius * (Math.sin(theta[i]))) + 'px';
      buttons[i].style.position = "absolute";
      buttons[i].style.top = ((mainHeight / 2) - parseInt(buttons[i].posy.slice(0, -2))) + 'px';
      buttons[i].style.left = ((mainHeight/ 2 ) + parseInt(buttons[i].posx.slice(0, -2))) + 'px';
    }
  }, []);

  return (
    <div id="main_menu">
      <img id="beacon_img" src={beaconAsset} style={imgStyle}/>
      <Tooltip title="Logout" arrow>
        <img src={logico} className="buttons_main" onClick={()=>Auth.signOut()} />
      </Tooltip>
      <Tooltip title="Analytics" arrow>
        <img src={analico} className="buttons_main" onClick={()=>props.render.setRenderContent('analytics')} />
      </Tooltip>
      <Tooltip title="Beacons" arrow>
        <img src={beaico} className="buttons_main" onClick={()=>props.render.setRenderContent('beacons')} />
      </Tooltip>
      <Tooltip title="Geofences" arrow>
        <img src={geoico} className="buttons_main" onClick={()=>props.render.setRenderContent('geofences')} />
      </Tooltip>
      <Tooltip title="NFCs" arrow>
        <img src={nfcico} className="buttons_main" onClick={()=>props.render.setRenderContent('nfcs')} />
      </Tooltip>
      <Tooltip title="QR Codes" arrow>
        <img src={qrico} className="buttons_main" onClick={()=>props.render.setRenderContent('qr codes')} />
      </Tooltip>
      <Tooltip title="Ads" arrow>
        <img src={adico} className="buttons_main" onClick={()=>props.render.setRenderContent('ads')} />
      </Tooltip>
      <Tooltip title="Schedules" arrow>
        <img src={schico} className="buttons_main" onClick={()=>props.render.setRenderContent('schedule')} />
      </Tooltip>
      <Tooltip title="Help & FAQs" arrow>
        <img src={helpico} className="buttons_main" onClick={()=>props.render.setRenderContent('help')} />
      </Tooltip>
      <Tooltip title="Cancel Subscription" arrow>
        <img src={cancico} className="buttons_main" onClick={()=>props.render.setRenderContent('cancel')} />
      </Tooltip>
      <Tooltip title="Business Info & Manager" arrow>
        <img src={bizico} className="buttons_main" onClick={()=>props.render.setRenderContent('business info')} />
      </Tooltip>
      <Tooltip title="Reset Password" arrow>
        <img src={resetico} className="buttons_main" onClick={()=>props.render.setRenderContent('reset')} />
      </Tooltip>
    </div>
  )
}

export default MainMenu;
