import React from 'react';
import { Collection } from '@aws-amplify/ui-react';
import LocationCard from './LocationCard';

const LocationList = ({ locations, setLocations, user, deleteLocation}) => {
  return (
    <>
      <div className="loc_headers_container">
        <p>Circular Icon</p>
        <p>Location Name</p>
        <p>Show on Mobile</p>
        <p>Action</p>
        <p>Details</p>
      </div>
        {locations && locations.length > 0 ? <Collection type="list" items={locations} gap="0.25rem">
          {(item, index) => (
            <LocationCard index={index} key={index} user={user} location={item} locations={locations} setLocations={setLocations} deleteLocation={deleteLocation} />
          )}
        </Collection> : <p style={{ color: "white", fontStyle: "italic", fontWeight: "100" }}>No locations to display.</p>}
    </>
  );
};

export default LocationList;
