import React, { useState, useEffect } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { Button, TextField, SelectField, Flex } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import AdList from "./AdList";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import {
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Info } from "@mui/icons-material";

const Ads = ({ user, business }) => {
  /* Dialog Box */
  const [open, setOpen] = useState(false);
  const [adId, setAdId] = useState(null);

  const handleClickOpen = (id) => {
    setAdId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setAdId(null);
    setOpen(false);
  };

  /* Ad Form */
  const [openAdForm, setOpenAdForm] = useState(false);
  const [adFormData, setAdFormData] = useState({
    name: "",
    title: "",
    description: "",
    type: "Image",
    url: "",
    redirectLink: "",
  });
  const [ads, setAds] = useState([]);

  const handleOpenAdForm = () => setOpenAdForm(true);
  const handleCloseAdForm = () => setOpenAdForm(false);
  const handleAdFormChange = (e) => {
    setAdFormData({ ...adFormData, [e.target.name]: e.target.value });
  };
  const handleSelection = (e) => {
    if (e.target.name === "Image") {
      setAdFormData({ ...adFormData, [e.target.name]: e.target.value });
    } else {
      setAdFormData({
        ...adFormData,
        [e.target.name]: e.target.value,
        redirectLink: "",
      });
    }
  };
  const handleAdSubmit = async (e) => {
    e.preventDefault();
    const { name, title, description, type, url, redirectLink } = adFormData;
    const adIcon = document.getElementById("ad-icon-button-file").files[0];
    let urlLink, contentFile;

    if (type === "Image") {
      contentFile = document.getElementById("content-icon-button-file")
        .files[0];
      urlLink = business.name + "/" + contentFile.name.split(" ").join("_");
    } else {
      urlLink = url;
    }

    const ad = {
      name,
      adIcon: business.name + "/" + adIcon.name.split(" ").join("_"),
      title,
      description,
      url: urlLink,
      redirectLink,
      type,
      businessId: business.id,
    };
    await Storage.put(
      business.name + "/" + adIcon.name.split(" ").join("_"),
      adIcon,
      { contentType: "image/*" }
    );
    if (type === "Image") {
      await Storage.put(
        business.name + "/" + contentFile.name.split(" ").join("_"),
        contentFile,
        { contentType: "image/*" }
      );
    }
    const result = await API.graphql(
      graphqlOperation(mutations.createAd, { input: ad })
    );

    setAds([...ads, result.data.createAd]);
    handleCloseAdForm();
  };

  const handleAdDelete = async (id) => {
    let responseDB, responseB;
    // Need to remove all shifts associated to an ad
    const shiftList = await API.graphql(
      graphqlOperation(queries.listShifts, {
        filter: { shiftAdId: { eq: id } },
      })
    );

    for (let i = 0; i < shiftList.data.listShifts.items.length; i++) {
      await API.graphql(
        graphqlOperation(mutations.deleteShift, {
          input: { id: shiftList.data.listShifts.items[i].id },
        })
      );
    }

    console.log("all shifts deleted");

    // Need to delete all analytics associated to an ad
    const adAnalytics = await API.graphql(
      graphqlOperation(queries.listAnalytics, {
        filter: { analyticsAdId: { eq: id } },
      })
    );

    for (let i = 0; i < adAnalytics.data.listAnalytics.items.length; i++) {
      await API.graphql(
        graphqlOperation(mutations.deleteAnalytics, {
          input: { id: adAnalytics.data.listAnalytics.items[i].id },
        })
      );
    }

    // Delete from dB
    responseDB = await API.graphql(
      graphqlOperation(mutations.deleteAd, { input: { id: id } })
    );

    // filter out the deleted Ad and rerender the list
    var filtered = ads.filter(function (value, index, arr) {
      return value.id !== responseDB.data.deleteAd.id;
    });
    setAds(filtered);
  };

  useEffect(() => {
    //useEffect function must return a cleanup function or nothing
    (async () => {
      const result = await API.graphql(
        graphqlOperation(queries.listAds, {
          filter: { businessId: { eq: business.id } },
        })
      );
      console.log(result);
      setAds(result.data.listAds.items);
    })(); //IIFE
  }, [business.id]);

  return (
    <>
      <div className="title_container">
        <h1>Ad Manager Page</h1>
        <Button className="add_button" onClick={handleOpenAdForm}>
          + New Ad
        </Button>
      </div>
      <AdList
        ads={ads}
        setAds={setAds}
        user={user}
        deleteAd={handleClickOpen}
      />
      <Modal
        sx={{
          "& > .MuiBackdrop-root": {
            backdropFilter: "blur(2px)",
            backgroundColor: "#FFFFFF55",
          },
        }}
        open={openAdForm}
        onClose={handleCloseAdForm}
      >
        <Box className="add_input_margin padding_3 smaller_frame">
          <Flex
            as="form"
            padding={30}
            direction="column"
            onSubmit={handleAdSubmit}
          >
            <label
              htmlFor="ad-icon-button-file"
              style={{ color: "hsl(210deg 25% 25%)", fontSize: "1.05rem" }}
            >
              Ad Icon <br />
              <input
                name="icon"
                accept="image/*"
                id="ad-icon-button-file"
                type="file"
                required
              />
            </label>
            <TextField
              name="name"
              label="Ad Name"
              placeholder="Ad name"
              onChange={handleAdFormChange}
              isRequired={true}
            />
            <TextField
              name="title"
              label="Ad Title"
              placeholder="Ad title"
              onChange={handleAdFormChange}
              isRequired={true}
            />
            <TextField
              name="description"
              label="Ad Description"
              placeholder="Ad description"
              onChange={handleAdFormChange}
              isRequired={true}
            />
            <SelectField
              name="type"
              label="Ad Type"
              value={adFormData.type}
              onChange={handleSelection}
              style={{ marginBottom: "15px" }}
            >
              <option value="Image">Image</option>
              <option value="Website">Website</option>
            </SelectField>
            {adFormData.type === "Image" ? (
              <>
                <label
                  htmlFor="content-icon-button-file"
                  style={{ color: "hsl(210deg 25% 25%)", fontSize: "1.05rem" }}
                >
                  Ad Content <br />
                  <input
                    name="icon"
                    accept="image/*"
                    id="content-icon-button-file"
                    type="file"
                    required
                  />
                </label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    name="redirectLink"
                    label="Redirect Link"
                    placeholder="https://example.com"
                    onChange={handleAdFormChange}
                    isRequired={true}
                  />
                  <Tooltip
                    title="Website that customers go to when they click on the ad"
                    arrow
                  >
                    <Info style={{ color: "lightgrey" }} />
                  </Tooltip>
                </div>
              </>
            ) : (
              <TextField
                name="url"
                label="Website"
                placeholder="https://example.com"
                onChange={handleAdFormChange}
                isRequired={true}
              />
            )}
            <Button
              type="submit"
              variation="primary"
              backgroundColor={"#429321"}
            >
              Submit
            </Button>
          </Flex>
        </Box>
      </Modal>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Delete ${adId}?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this ad? Doing so will remove this
            ad and any associated shifts and analytics
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => handleAdDelete(adId)} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Ads;
