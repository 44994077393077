import { Amplify, Auth, API, graphqlOperation, Hub } from "aws-amplify";
import * as mutations from "./graphql/mutations";
import logo from "./assets/dtgvl_logo.png";
import or from "./assets/or.png";
import fb from "./assets/facebook_login.png";
import apple from "./assets/apple.png";

import { TextField, Flex, Button } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "./stylesheets/auth.css";
import "./App.css";

import Dashboard from "./components/Dashboard";

import awsconfig from "./aws-exports";
import { useEffect, useState } from "react";
import { userByEmail } from "./graphql/queries";
import { CircularProgress } from "@mui/material";

// awsconfig.oauth.redirectSignIn = "http://localhost:3000/";
// awsconfig.oauth.redirectSignOut = "http://localhost:3000/";
// awsconfig.oauth.redirectSignIn = "https://ds9jl6k575cqw.cloudfront.net/";
// awsconfig.oauth.redirectSignOut = "https://ds9jl6k575cqw.cloudfront.net/";
awsconfig.oauth.redirectSignIn = "https://proximdash.com/";
awsconfig.oauth.redirectSignOut = "https://proximdash.com/";

Amplify.configure(awsconfig);

const App = () => {
  const [authPage, setAuthPage] = useState("signin");
  const [signInFormData, setSignInFormData] = useState({
    email: "",
    password: "",
  });
  const [detailsFormData, setDetailsFormData] = useState({
    firstName: "",
    lastName: "",
  });
  const [signUpFormData, setSignUpFormData] = useState({
    email: "",
    password: "",
    confirm: "",
    firstName: "",
    lastName: "",
  });
  const [forgotPasswordFormData, setForgotPasswordFormData] = useState({
    email: "",
    code: "",
    newPassword: "",
  });
  const [confirmationCodeFormData, setConfirmationCodeFormData] = useState({
    code: "",
  });
  const [user, setUser] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [errMessage, setErrMessage] = useState(null);

  const handleSignInFormChange = (e) => {
    setSignInFormData({ ...signInFormData, [e.target.name]: e.target.value });
  };

  const handleDetailsFormChange = (e) => {
    setDetailsFormData({ ...detailsFormData, [e.target.name]: e.target.value });
  };

  const handleSignUpFormChange = (e) => {
    setSignUpFormData({ ...signUpFormData, [e.target.name]: e.target.value });
  };

  const handleForgotPasswordFormChange = (e) => {
    setForgotPasswordFormData({
      ...forgotPasswordFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleConfirmationCodeFormChange = (e) => {
    setConfirmationCodeFormData({
      ...confirmationCodeFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSignInSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await Auth.signIn(signInFormData.email, signInFormData.password);
    } catch (e) {
      setErrMessage(e.message);
    }
    setLoading(false);
  };

  const handleDetailsSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await API.graphql(
        graphqlOperation(mutations.updateUser, {
          input: { id: user.id, ...detailsFormData },
        })
      );
      setUser({
        ...user,
        firstName: detailsFormData.firstName,
        lastName: detailsFormData.lastName,
      });
    } catch (e) {
      setErrMessage(e.message);
    }
    setLoading(false);
  };

  const handleFBSignIn = async () => {
    await Auth.federatedSignIn({ provider: "Facebook" });
  };

  const handleAppleSignIn = async () => {
    await Auth.federatedSignIn({ provider: "SignInWithApple" });
  };

  const handleSignUpSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    console.log(signUpFormData);

    if (signUpFormData.password !== signUpFormData.confirm) {
      setErrMessage("Passwords must match");
      return;
    }

    try {
      await Auth.signUp({
        username: signUpFormData.email,
        password: signUpFormData.password,
        attributes: {
          email: signUpFormData.email,
          given_name: signUpFormData.firstName,
          family_name: signUpFormData.lastName,
        },
        autoSignIn: {
          enabled: true,
        },
      });
      const userInput = {
        firstName: signUpFormData.firstName,
        lastName: signUpFormData.lastName,
        email: signUpFormData.email,
        role: "Mobile",
        gender: "N/A",
        birthday: "N/A",
        businessId: "",
        username: signUpFormData.email,
      };
      await API.graphql(
        graphqlOperation(mutations.createUser, { input: userInput })
      );
      setErrMessage(null);
      setAuthPage("confirm");
    } catch (e) {
      setErrMessage(e.message);
    }
    setLoading(false);
  };

  const handleConfirmCodeSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await Auth.confirmSignUp(
        signUpFormData.email,
        confirmationCodeFormData.code
      );
      const response = await API.graphql(
        graphqlOperation(userByEmail, { email: signUpFormData.email })
      );
      setUser(response.data.userByEmail.items[0]);
    } catch (error) {
      setErrMessage(e.message);
    }
    setLoading(false);
  };

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await Auth.forgotPassword(forgotPasswordFormData.email);
      setErrMessage(null);
      setAuthPage("newpassword");
    } catch (error) {
      setErrMessage(e.message);
    }
    setLoading(false);
  };

  const handleNewPasswordSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await Auth.forgotPasswordSubmit(
        forgotPasswordFormData.email,
        forgotPasswordFormData.code,
        forgotPasswordFormData.newPassword
      );
      alert("New password has been set.");
      setErrMessage(null);
      setAuthPage("signin");
    } catch (error) {
      setErrMessage(e.message);
    }
    setLoading(false);
  };

  // called when app is first started and during auth events (sign in / out)
  const checkUser = async () => {
    try {
      const authUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });

      // if user is logged in, get the user from the dB
      const response = await API.graphql(
        graphqlOperation(userByEmail, { email: authUser.attributes.email })
      );
      console.log(response.data.userByEmail.items[0]);

      // handle adding the federated sign up users to the dB
      if (response.data.userByEmail.items.length === 0) {
        console.log("federated");
        const userInput = {
          firstName: authUser.attributes.given_name || "",
          lastName: authUser.attributes.family_name || "",
          email: authUser.attributes.email,
          role: "Mobile",
          gender: "N/A",
          birthday: "N/A",
          businessId: "",
          username: authUser.username,
        };
        console.log(userInput);
        const fbUser = await API.graphql(
          graphqlOperation(mutations.createUser, { input: userInput })
        );
        setUser(fbUser.data.createUser);
      } else {
        setUser(response.data.userByEmail.items[0]);
        setErrMessage(null);
      }
      console.log("user signed in");
    } catch (e) {
      console.log(e);
      setUser(null);
      setErrMessage(null);
    }
  };

  useEffect(() => {
    checkUser();
  }, []);

  useEffect(() => {
    const listener = (data) => {
      console.log(data.payload);
      if (data.payload.event === "signIn" || data.payload.event === "signOut") {
        console.log(`${data.payload.event} event just occurred`);
        checkUser();
      }
    };

    Hub.listen("auth", listener);
    return () => Hub.remove("auth", listener);
  }, []);

  return (
    <div className="auth_placement">
      {user === undefined ? (
        <CircularProgress />
      ) : user ? (
        user.firstName == "" ? (
          <>
            <img
              src={logo}
              alt=""
              style={{ width: "15%", position: "relative" }}
            />
            <h1 style={{ color: "white" }}>Partner Management Dashboard</h1>
            {errMessage && (
              <h3 style={{ textAlign: "center", color: "red" }}>
                {errMessage}
              </h3>
            )}
            <Flex
              style={{ width: "25%" }}
              as="form"
              direction="column"
              onSubmit={handleDetailsSubmit}
            >
              <TextField
                style={{ color: "white", borderColor: "white" }}
                name="firstName"
                placeholder="First Name"
                onChange={handleDetailsFormChange}
                variation={"quiet"}
                isRequired={true}
                value={detailsFormData.firstName}
              />
              <TextField
                style={{ color: "white", borderColor: "white" }}
                name="lastName"
                placeholder="Last Name"
                onChange={handleDetailsFormChange}
                variation={"quiet"}
                isRequired={true}
                value={detailsFormData.lastName}
              />
              <Button
                style={{
                  backgroundColor: "white",
                  borderRadius: 25,
                  width: "100%",
                  marginTop: 35,
                }}
                type="submit"
                disabled={loading ? true : false}
              >
                {loading ? "Submitting..." : "Submit"}
              </Button>
            </Flex>
            <button
              style={{
                background: "none",
                border: "none",
                color: "white",
                marginTop: 15,
              }}
              onClick={() => {
                Auth.signOut();
                setAuthPage("signin");
              }}
            >
              Go back
            </button>
          </>
        ) : user.role === "Admin" ||
          user.role === "Editor" ||
          user.role === "Manager" ? (
          <Dashboard user={user} />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h1 style={{ color: "white" }}>
              You do not have permission to access this application
            </h1>
            <button
              style={{
                border: "none",
                width: "50%",
                background: "white",
                borderRadius: 25,
                marginTop: 15,
              }}
              onClick={() => {
                Auth.signOut();
                setAuthPage("signin");
              }}
            >
              Sign Out
            </button>
          </div>
        )
      ) : (
        <div className="auth_holder">
          {authPage === "signin" ? (
            <>
              <img
                src={logo}
                alt=""
                style={{ width: "15%", position: "relative" }}
              />
              <h1 style={{ color: "white" }}>Partner Management Dashboard</h1>
              {errMessage && (
                <h3 style={{ textAlign: "center", color: "red" }}>
                  {errMessage}
                </h3>
              )}
              <Flex
                style={{ width: "25%" }}
                as="form"
                direction="column"
                onSubmit={handleSignInSubmit}
              >
                <TextField
                  style={{ color: "white", borderColor: "white" }}
                  name="email"
                  placeholder="Email"
                  onChange={handleSignInFormChange}
                  variation={"quiet"}
                  isRequired={true}
                  value={signInFormData.email}
                />
                <TextField
                  style={{ color: "white", borderColor: "white" }}
                  type={"password"}
                  name="password"
                  placeholder="Password"
                  onChange={handleSignInFormChange}
                  variation={"quiet"}
                  isRequired={true}
                  value={signInFormData.password}
                />
                <Button
                  style={{
                    backgroundColor: "white",
                    borderRadius: 25,
                    width: "100%",
                    marginTop: 35,
                  }}
                  type="submit"
                  disabled={loading ? true : false}
                >
                  {loading ? "Signing in..." : "Sign In"}
                </Button>
              </Flex>
              <button
                style={{
                  background: "none",
                  border: "none",
                  color: "white",
                  marginTop: 15,
                }}
                onClick={() => setAuthPage("forgot")}
              >
                Forgot Password
              </button>
              <img
                src={or}
                style={{ width: "15%", position: "relative", margin: 25 }}
                alt=""
              />
              <img
                src={fb}
                style={{ position: "relative", margin: 15, cursor: "pointer" }}
                onClick={handleFBSignIn}
                alt=""
              />
              <img
                src={apple}
                style={{ position: "relative", margin: 15, cursor: "pointer" }}
                onClick={handleAppleSignIn}
                alt=""
              />
              <button
                style={{ background: "none", border: "none", color: "white" }}
                onClick={() => {
                  setAuthPage("signup");
                  setErrMessage(null);
                }}
              >
                Don't have an account? Register here!
              </button>
            </>
          ) : authPage === "signup" ? (
            <>
              <img
                src={logo}
                alt=""
                style={{ width: "15%", position: "relative" }}
              />
              <h1 style={{ color: "white" }}>Partner Management Dashboard</h1>
              {errMessage ? (
                <h3 style={{ textAlign: "center", color: "red" }}>
                  {errMessage}
                </h3>
              ) : null}
              <Flex
                style={{ width: "25%" }}
                as="form"
                direction="column"
                onSubmit={handleSignUpSubmit}
              >
                <TextField
                  style={{ color: "white", borderColor: "white" }}
                  name="firstName"
                  placeholder="First Name"
                  onChange={handleSignUpFormChange}
                  variation={"quiet"}
                  isRequired={true}
                  value={signUpFormData.firstName}
                />
                <TextField
                  style={{ color: "white", borderColor: "white" }}
                  name="lastName"
                  placeholder="Last Name"
                  onChange={handleSignUpFormChange}
                  variation={"quiet"}
                  isRequired={true}
                  value={signUpFormData.lastName}
                />
                <TextField
                  style={{ color: "white", borderColor: "white" }}
                  name="email"
                  placeholder="Email"
                  onChange={handleSignUpFormChange}
                  variation={"quiet"}
                  isRequired={true}
                  value={signUpFormData.email}
                />
                <TextField
                  style={{ color: "white", borderColor: "white" }}
                  type={"password"}
                  name="password"
                  placeholder="Password"
                  onChange={handleSignUpFormChange}
                  variation={"quiet"}
                  isRequired={true}
                  value={signUpFormData.password}
                />
                <TextField
                  style={{ color: "white", borderColor: "white" }}
                  type={"password"}
                  name="confirm"
                  placeholder="Confirm Password"
                  onChange={handleSignUpFormChange}
                  variation={"quiet"}
                  isRequired={true}
                  value={signUpFormData.confirm}
                />
                <Button
                  style={{
                    backgroundColor: "white",
                    borderRadius: 25,
                    width: "100%",
                    marginTop: 35,
                    marginBottom: 15,
                  }}
                  type="submit"
                  disabled={loading ? true : false}
                >
                  {loading ? "Signing up..." : "Sign Up"}
                </Button>
              </Flex>
              <button
                style={{ background: "none", border: "none", color: "white" }}
                onClick={() => {
                  setAuthPage("signin");
                  setErrMessage(null);
                }}
              >
                Already have an account? Sign in here!
              </button>
            </>
          ) : authPage === "confirm" ? (
            <>
              <img
                src={logo}
                alt=""
                style={{ width: "15%", position: "relative" }}
              />
              <h1 style={{ color: "white" }}>Confirm Email</h1>
              {errMessage ? (
                <h3 style={{ textAlign: "center", color: "red" }}>
                  {errMessage}
                </h3>
              ) : null}
              <Flex
                style={{ width: "25%" }}
                as="form"
                direction="column"
                onSubmit={handleConfirmCodeSubmit}
              >
                <TextField
                  style={{ color: "white", borderColor: "white" }}
                  name="code"
                  placeholder="Confirmation Code"
                  onChange={handleConfirmationCodeFormChange}
                  variation={"quiet"}
                  isRequired={true}
                  value={confirmationCodeFormData.code}
                />
                <Button
                  style={{
                    backgroundColor: "white",
                    borderRadius: 25,
                    width: "100%",
                    marginTop: 35,
                    marginBottom: 15,
                  }}
                  type="submit"
                  disabled={loading ? true : false}
                >
                  {loading ? "Confirming..." : "Confirm Code"}
                </Button>
              </Flex>
              <button
                style={{ background: "none", border: "none", color: "white" }}
                onClick={() => Auth.resendSignUp(signUpFormData.email)}
              >
                Resend Code
              </button>
            </>
          ) : authPage === "forgot" ? (
            <>
              <img
                src={logo}
                alt=""
                style={{ width: "15%", position: "relative" }}
              />
              <h1 style={{ color: "white" }}>Forgot Password</h1>
              {errMessage ? (
                <h3 style={{ textAlign: "center", color: "red" }}>
                  {errMessage}
                </h3>
              ) : null}
              <Flex
                style={{ width: "25%" }}
                as="form"
                direction="column"
                onSubmit={handleForgotPasswordSubmit}
              >
                <TextField
                  style={{ color: "white", borderColor: "white" }}
                  name="email"
                  placeholder="Email"
                  onChange={handleForgotPasswordFormChange}
                  variation={"quiet"}
                  isRequired={true}
                  value={forgotPasswordFormData.email}
                />
                <Button
                  style={{
                    backgroundColor: "white",
                    borderRadius: 25,
                    width: "100%",
                    marginTop: 35,
                    marginBottom: 15,
                  }}
                  type="submit"
                  disabled={loading ? true : false}
                >
                  {loading ? "Submitting..." : "Submit"}
                </Button>
              </Flex>
              <button
                style={{ background: "none", border: "none", color: "white" }}
                onClick={() => setAuthPage("signin")}
              >
                Back to sign in
              </button>
            </>
          ) : (
            <>
              <img
                src={logo}
                alt=""
                style={{ width: "15%", position: "relative" }}
              />
              <h1 style={{ color: "white" }}>New Password</h1>
              {errMessage ? (
                <h3 style={{ textAlign: "center", color: "red" }}>
                  {errMessage}
                </h3>
              ) : null}
              <Flex
                style={{ width: "25%" }}
                as="form"
                direction="column"
                onSubmit={handleNewPasswordSubmit}
              >
                <TextField
                  style={{ color: "white", borderColor: "white" }}
                  name="code"
                  placeholder="Verification Code"
                  onChange={handleForgotPasswordFormChange}
                  variation={"quiet"}
                  isRequired={true}
                  value={forgotPasswordFormData.code}
                />
                <TextField
                  style={{ color: "white", borderColor: "white" }}
                  type={"password"}
                  name="newPassword"
                  placeholder="New Password"
                  onChange={handleForgotPasswordFormChange}
                  variation={"quiet"}
                  isRequired={true}
                  value={forgotPasswordFormData.newPassword}
                />
                <Button
                  style={{
                    backgroundColor: "white",
                    borderRadius: 25,
                    width: "100%",
                    marginTop: 35,
                    marginBottom: 15,
                  }}
                  type="submit"
                  disabled={loading ? true : false}
                >
                  {loading ? "Submitting..." : "Submit"}
                </Button>
              </Flex>
              <button
                style={{ background: "none", border: "none", color: "white" }}
                onClick={() => Auth.resendSignUp(forgotPasswordFormData.email)}
              >
                Resend Code
              </button>
              <button
                style={{
                  background: "none",
                  border: "none",
                  color: "white",
                  marginTop: 15,
                }}
                onClick={() => setAuthPage("signin")}
              >
                Back to sign in
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
};
// function App() {

//   useEffect(()=>{
//     const unsubscribe = Hub.listen('auth', (data) => {
//       const { payload } = data
//       console.log('A new auth event has happened: ', data)
//        if (payload.event === 'signIn') {
//          alert('a user has signed in!')
//        }
//        if (payload.event === 'signOut') {
//          console.log('a user has signed out!')
//        }
//     })

//     return unsubscribe();
//   }, [])

//   const services = {
//     async handleSignUp(formData) {
//       let { username, password, attributes } = formData;
//       let user = {
//         firstName: attributes.given_name,
//         lastName: attributes.family_name,
//         email: username,
//         role: "Mobile",
//         gender: attributes.gender,
//         birthday: attributes.birthdate,
//         businessId: ""
//       }
//       try {
//         let result = await Auth.signUp({
//           username,
//           password,
//           attributes,
//         });
//         await API.graphql(graphqlOperation(mutations.createUser, {input: user}));
//         return result;
//       } catch (error) {
//         console.log(error);
//       }
//     }
//   };

//   return (
//     <div className="auth_placement">
//       {
//       // authenticate the user using cognito
//       // if the user is in a group (Admin, Editor, Manager) -> allow them entry
//       // otherwise, tell the user they dont have permission (mobile users)
//       }
//       <div className="auth_holder">
//         <Authenticator services={services} components={{
//           SignUp: {
//             FormFields() {
//               return (
//                 <>
//                   <Authenticator.SignUp.FormFields />

//                   <TextField
//                     type="date"
//                     label="Birthday"
//                     name="birthdate"
//                   ></TextField>

//                   <RadioGroupField
//                     direction="row"
//                     label="Gender"
//                     name="gender"
//                   >
//                     <Radio value="male">Male</Radio>
//                     <Radio value="female">Female</Radio>
//                     <Radio value="other">Other</Radio>
//                   </RadioGroupField>
//                 </>
//               );
//             },
//           },
//         }}>
//           {({ signOut, user }) => (
//             user.getSignInUserSession().getAccessToken().payload['cognito:groups'] ?
//             <Dashboard user={user} signout={signOut}/>
//             : <div style={{ display: 'flex', flexDirection: 'column'}}>
//                 <h1>You do not have permission to access this application</h1>
//                 <button onClick={signOut}>Sign Out</button>
//               </div>
//           )}
//         </Authenticator>
//       </div>
//     </div>
//   );
// }

export default App;
