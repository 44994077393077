import { Skeleton } from '@mui/material';
import { API, graphqlOperation } from 'aws-amplify';
import React, { useState, useEffect } from 'react';

const heatRange = [4, 12, 36, 64, 136, 194, 235, 448];

function getHourFromISODate(isoDate) {
    const dateObj = new Date(isoDate);
    const timeIndex = dateObj.getHours();
    
    return timeIndex;
}


function WeekdayBreakdown({ day }) {
    const [data, setData] = useState([]);
    useEffect(() => {
        let tempDataHolder = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        // separate the day into 24 hour chunks
        day.forEach(value => {
            // for each chunk of time, filter out the values and add them to the tempDataHolder array
            const index = getHourFromISODate(value.createdAt);
            console.log(value.createdAt + "=" + index);
            tempDataHolder[index]++;
        });
        console.log(tempDataHolder);
        setData(tempDataHolder);
    }, [])
    
    return (
        <>
            {data.length > 0 ? 
            <div className="heatmap_grid">
                <p style={{ fontSize: '8px', width: '30px', height: '100%', padding: 0, margin: 0 }}>{['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'][new Date(day[0]).getDay()]}</p>
                {data.map((val, index) => 
                    <div 
                        key={index} 
                        style={{ 
                            width: '95%', 
                            height: '95%', 
                            borderRadius: '5px',
                            backgroundColor: (val <= heatRange[0]) ? '#FEFED9' : 
                            (val <= heatRange[1] && val > heatRange[0]) ? '#EBF6B1' : 
                            (val <= heatRange[2] && val > heatRange[1]) ? '#C6E7B4' : 
                            (val <= heatRange[3] && val > heatRange[2]) ? '#82CCBB' : 
                            (val <= heatRange[4] && val > heatRange[3]) ? '#4BB5C4' : 
                            (val <= heatRange[5] && val > heatRange[4]) ? '#3192C0' : 
                            (val <= heatRange[6] && val > heatRange[5]) ? '#3060A8' : 
                            (val <= heatRange[7] && val > heatRange[6]) ? '#2F3994' : 
                            '#122058'
                            }}></div>)}
            </div> : null}
        </>
    );
}

export default WeekdayBreakdown;