import React, { useState, useEffect } from 'react';
import { Collection, Card, Text } from '@aws-amplify/ui-react';
import { Button, TextField, SelectField, Flex } from '@aws-amplify/ui-react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Amplify, { Auth, API, graphqlOperation, Storage } from 'aws-amplify';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import * as queries from './../graphql/queries';
import * as mutations from './../graphql/mutations';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  height: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 5,
  overflow: 'auto'
};

const cardStyle = {
  border: '0.5px solid lightgrey',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  justifyItems: 'center',
  width: '90%',
  alignItems: 'center'
}

const UserList = ({ users, setUsers, deleteUser, user }) => {
  /* User Form */
  const [openUserForm, setOpenUserForm] = useState(false);
  const [userFormData, setUserFormData] = useState({ email: "", role: "" });
  const [userId, setUserId] = useState("");

  const handleOpenUserForm = (id, email, role) => {
    setOpenUserForm(true);
    setUserFormData({ email: email, role: role });
    setUserId(id);
  }
  const handleCloseUserForm = () => setOpenUserForm(false);
  const handleUserFormChange = (e) => {
    setUserFormData({...userFormData, [e.target.name]: e.target.value});
  }

  // handles user getting added to business (Admin only)
  const handleUserSubmit = async (e) => {
    e.preventDefault();
    const {email, role} = userFormData;

    // get the user from the dB using the email
    let dBUser = await API.graphql(graphqlOperation(queries.userByEmail, {email: email}));

    // update the dB entry of the user with the new role
    const updateUser = await API.graphql(graphqlOperation(mutations.updateUser, {input: {id: userId, role: role}}));

    // update the user role on the UI
    let newArr = users.filter(x => x.id !== userId);
    setUsers([...newArr, updateUser.data.updateUser])
    handleCloseUserForm();
  }

  return (
    <>
      <div className="user_headers_container">
        <p>Name</p>
        <p>Email</p>
        <p>Role</p>
        <p>Actions</p>
      </div>
      <div className="user_list_container">
        {users && users.length > 0 ? <Collection type="list" items={users} gap="0" >
          {(item, index) => (
            <Card key={index} style={cardStyle}>
              <Text style={textStyle}>{item.firstName} {item.lastName}</Text>
              <Text style={textStyle}>{item.email}</Text>
              <Text style={textStyle}>{item.role}</Text>
              <div className="card_btn_wrapper">
                {user.role === "Admin" ?
                  <>
                    <IconButton aria-label="delete" size="large" onClick={()=>handleOpenUserForm(item.id, item.email, item.role)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={()=>deleteUser(item.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </> :
                  <>
                    <IconButton aria-label="delete" size="large" disabled>
                      <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete" disabled>
                      <DeleteIcon />
                    </IconButton>
                  </>}
              </div>
            </Card>
          )}
        </Collection> : <p style={{ color: "white", fontStyle: "italic", fontWeight: "100" }}>No users to display.</p>}
      </div>
      <Modal sx={{ 
            "& > .MuiBackdrop-root" : {
                    backdropFilter: "blur(2px)",
                    backgroundColor: "#FFFFFF55"
                  }
            }} open={openUserForm} onClose={handleCloseUserForm}>
        <Box className="add_input_margin smaller_frame">
          <Flex as="form" padding={30} direction="column" onSubmit={handleUserSubmit}>
            <TextField name="email" label="Email" value={userFormData.email} disabled/>
            <SelectField
              name="role"
              label="Role"
              value={userFormData.role}
              onChange={handleUserFormChange}
              required
            >
              <option value="Admin">Admin</option>
              <option value="Editor">Editor</option>
              <option value="Manager">Manager</option>
            </SelectField>
            <Button type="submit" variation="primary" backgroundColor={"#429321"}>Submit</Button>
          </Flex>
        </Box>
      </Modal>
    </>
  );
};

const textStyle = {
  fontSize: '12px'
}
export default UserList;
