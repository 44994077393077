import React, { useState, useEffect } from 'react';
import '../stylesheets/scheduler.css';
import { Grid, View, Text, Button, TextField, SelectField, Flex, Image } from '@aws-amplify/ui-react';
import Box from '@mui/material/Box';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import Modal from '@mui/material/Modal';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import IconButton from '@mui/material/IconButton';

const baseUrl = "https://dtgvlv2f04838c8631b4596b8b3acde06c1239b145859-dev.s3.amazonaws.com/public/"

const startHours = ["12:00AM", "1:00AM", "2:00AM", "3:00AM", "4:00AM", "5:00AM", "6:00AM", "7:00AM", "8:00AM", "9:00AM", "10:00AM", "11:00AM", "12:00PM", "1:00PM", "2:00PM", "3:00PM", "4:00PM", "5:00PM", "6:00PM", "7:00PM", "8:00PM", "9:00PM", "10:00PM", "11:00PM"];
const endHours = ["12:00AM", "1:00AM", "2:00AM", "3:00AM", "4:00AM", "5:00AM", "6:00AM", "7:00AM", "8:00AM", "9:00AM", "10:00AM", "11:00AM", "12:00PM", "1:00PM", "2:00PM", "3:00PM", "4:00PM", "5:00PM", "6:00PM", "7:00PM", "8:00PM", "9:00PM", "10:00PM", "11:00PM", "12:00AM"];

const tempRows="2rem 2rem 2rem 2rem 2rem 2rem 2rem 2rem 2rem 2rem 2rem 2rem 2rem 2rem 2rem 2rem 2rem 2rem 2rem 2rem 2rem 2rem 2rem 2rem 2rem"

const TimeAxis = () => {
  return(
    <Grid templateRows={tempRows} templateColumns="1fr" fontSize="12px" textAlign="right">
      <View border="2px solid lightgrey"></View>
      <Text style={textStyle}>12:00 AM</Text>
      <Text style={textStyle}>1:00 AM</Text>
      <Text style={textStyle}>2:00 AM</Text>
      <Text style={textStyle}>3:00 AM</Text>
      <Text style={textStyle}>4:00 AM</Text>
      <Text style={textStyle}>5:00 AM</Text>
      <Text style={textStyle}>6:00 AM</Text>
      <Text style={textStyle}>7:00 AM</Text>
      <Text style={textStyle}>8:00 AM</Text>
      <Text style={textStyle}>9:00 AM</Text>
      <Text style={textStyle}>10:00 AM</Text>
      <Text style={textStyle}>11:00 AM</Text>
      <Text style={textStyle}>12:00 PM</Text>
      <Text style={textStyle}>1:00 PM</Text>
      <Text style={textStyle}>2:00 PM</Text>
      <Text style={textStyle}>3:00 PM</Text>
      <Text style={textStyle}>4:00 PM</Text>
      <Text style={textStyle}>5:00 PM</Text>
      <Text style={textStyle}>6:00 PM</Text>
      <Text style={textStyle}>7:00 PM</Text>
      <Text style={textStyle}>8:00 PM</Text>
      <Text style={textStyle}>9:00 PM</Text>
      <Text style={textStyle}>10:00 PM</Text>
      <Text style={textStyle}>11:00 PM</Text>
      <Text style={textStyle}>12:00 PM</Text>
    </Grid>
  );
}

const WeekdayContainer = ({ day, shifts, setShifts, openShiftForm }) => {
  const [openContent, setOpenContent] = useState(false);
  const [ad, setAd] = useState({});
  const handleOpenContent = (ad) => {
    setAd(ad);
    setOpenContent(true);
  }
  const handleCloseContent = () => setOpenContent(false);

  const handleShiftDelete = async (id) => {
    console.log(id);
    // Delete from dB
    let responseDB = await API.graphql(graphqlOperation(mutations.deleteShift,  {input: {id: id} }));

    // filter out the deleted schedule and rerender the list
    var filtered = shifts.filter(function(value, index, arr){
        return value.id != responseDB.data.deleteShift.id;
    });
    setShifts(filtered);
    console.log("Successfully deleted Shift " + id);
  };

  return (
    <Grid name={day} templateRows={tempRows} templateColumns="1fr" textAlign="center">
      <View>{day.charAt(0).toUpperCase() + day.slice(1)}</View>
      {shifts.map((item)=>
        (item.weekday === day) ?
        <View key={item.id} rowStart={item.startTime+2} rowEnd={item.endTime+2} style={shiftStyle} as="div">
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
            <button style={{ background: 'none', border: 'none' }} onClick={() => handleShiftDelete(item.id)}>x</button>
            <div></div>
          </div>
          <p style={title}>Ad Name</p>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
            <p style={subtitle}>{item.ad.name}</p>
            <IconButton aria-label="edit" size="small" onClick={()=>openShiftForm(item.id, item.ad, item.startTime, item.endTime, item.weekday)}>
              <KeyboardArrowDownRoundedIcon style={{ width: 20, height: 20}} />
            </IconButton>
          </div>
          <p style={title}>Running Time</p>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
            <div>
              <p style={subtitle}>{startHours[item.startTime]}</p>
              <p style={subtitle}>to</p>
              <p style={subtitle}>{endHours[item.endTime]}</p>
            </div>
            <IconButton aria-label="edit" size="small" onClick={()=>openShiftForm(item.id, item.ad, item.startTime, item.endTime, item.weekday)}>
              <KeyboardArrowDownRoundedIcon style={{ width: 20, height: 20}} />
            </IconButton>
          </div>
          <Button onClick={(ad)=>handleOpenContent(item.ad)} style={buttonStyle}>Show {item.ad.type}</Button>
        </View> : null
      )}
      <Modal sx={{ 
            "& > .MuiBackdrop-root" : {
                    backdropFilter: "blur(2px)",
                    backgroundColor: "#FFFFFF55"
                  }
            }} open={openContent} onClose={handleCloseContent}>
        <Box className="add_input_margin larger_frame">
          {ad.type === "Image" ?
            <>
              <Image
                alt="Content"
                src={baseUrl+ad.url}
                width="200px"
                height="320px"/>
              <h3>Message</h3>
              <h4 style={{ textAlign: 'center'}}>{ad.description}</h4>
            </> :
              <iframe src={ad.url}></iframe>
            }
        </Box>
      </Modal>
    </Grid>
  );
}

const Outline = () => {
  return (
    <Grid templateRows={tempRows} templateColumns="1fr" textAlign="center">
      <View style={{backgroundColor: 'white', borderWidth: '2px 1px 0px 1px', borderColor: 'lightgrey', borderStyle: 'solid'}}></View>
      <View style={{backgroundColor: 'white', borderWidth: '2px 1px 0px 1px', borderColor: 'lightgrey', borderStyle: 'solid'}}></View>
      <View style={{backgroundColor: 'white', borderWidth: '1px 1px 0px 1px', borderColor: 'lightgrey', borderStyle: 'dashed solid'}}></View>
      <View style={{backgroundColor: 'white', borderWidth: '2px 1px 0px 1px', borderColor: 'lightgrey', borderStyle: 'solid'}}></View>
      <View style={{backgroundColor: 'white', borderWidth: '1px 1px 0px 1px', borderColor: 'lightgrey', borderStyle: 'dashed solid'}}></View>
      <View style={{backgroundColor: 'white', borderWidth: '2px 1px 0px 1px', borderColor: 'lightgrey', borderStyle: 'solid'}}></View>
      <View style={{backgroundColor: 'white', borderWidth: '1px 1px 0px 1px', borderColor: 'lightgrey', borderStyle: 'dashed solid'}}></View>
      <View style={{backgroundColor: 'white', borderWidth: '2px 1px 0px 1px', borderColor: 'lightgrey', borderStyle: 'solid'}}></View>
      <View style={{backgroundColor: 'white', borderWidth: '1px 1px 0px 1px', borderColor: 'lightgrey', borderStyle: 'dashed solid'}}></View>
      <View style={{backgroundColor: 'white', borderWidth: '2px 1px 0px 1px', borderColor: 'lightgrey', borderStyle: 'solid'}}></View>
      <View style={{backgroundColor: 'white', borderWidth: '1px 1px 0px 1px', borderColor: 'lightgrey', borderStyle: 'dashed solid'}}></View>
      <View style={{backgroundColor: 'white', borderWidth: '2px 1px 0px 1px', borderColor: 'lightgrey', borderStyle: 'solid'}}></View>
      <View style={{backgroundColor: 'white', borderWidth: '1px 1px 0px 1px', borderColor: 'lightgrey', borderStyle: 'dashed solid'}}></View>
      <View style={{backgroundColor: 'white', borderWidth: '2px 1px 0px 1px', borderColor: 'lightgrey', borderStyle: 'solid'}}></View>
      <View style={{backgroundColor: 'white', borderWidth: '1px 1px 0px 1px', borderColor: 'lightgrey', borderStyle: 'dashed solid'}}></View>
      <View style={{backgroundColor: 'white', borderWidth: '2px 1px 0px 1px', borderColor: 'lightgrey', borderStyle: 'solid'}}></View>
      <View style={{backgroundColor: 'white', borderWidth: '1px 1px 0px 1px', borderColor: 'lightgrey', borderStyle: 'dashed solid'}}></View>
      <View style={{backgroundColor: 'white', borderWidth: '2px 1px 0px 1px', borderColor: 'lightgrey', borderStyle: 'solid'}}></View>
      <View style={{backgroundColor: 'white', borderWidth: '1px 1px 0px 1px', borderColor: 'lightgrey', borderStyle: 'dashed solid'}}></View>
      <View style={{backgroundColor: 'white', borderWidth: '2px 1px 0px 1px', borderColor: 'lightgrey', borderStyle: 'solid'}}></View>
      <View style={{backgroundColor: 'white', borderWidth: '1px 1px 0px 1px', borderColor: 'lightgrey', borderStyle: 'dashed solid'}}></View>
      <View style={{backgroundColor: 'white', borderWidth: '2px 1px 0px 1px', borderColor: 'lightgrey', borderStyle: 'solid'}}></View>
      <View style={{backgroundColor: 'white', borderWidth: '1px 1px 0px 1px', borderColor: 'lightgrey', borderStyle: 'dashed solid'}}></View>
      <View style={{backgroundColor: 'white', borderWidth: '2px 1px 0px 1px', borderColor: 'lightgrey', borderStyle: 'solid'}}></View>
      <View style={{backgroundColor: 'white', borderWidth: '1px 1px 2px 1px', borderColor: 'lightgrey', borderStyle: 'dashed solid solid solid'}}></View>
    </Grid>
  );
}

const Blank = () => {
  return (
    <Grid templateRows={tempRows} templateColumns="1fr">
      <View></View>
      <View></View>
      <View></View>
      <View></View>
      <View></View>
      <View></View>
      <View></View>
      <View></View>
      <View></View>
      <View></View>
      <View></View>
      <View></View>
      <View></View>
      <View></View>
      <View></View>
      <View></View>
      <View></View>
      <View></View>
      <View></View>
      <View></View>
      <View></View>
      <View></View>
      <View></View>
      <View></View>
      <View></View>
    </Grid>
  );
}

const Scheduler = ({ schedule, shifts, setShifts }) => {
  const [shiftFormData, setShiftFormData] = useState({startTime: '', endTime: '', ad: '', weekday: ''});
  const [openContent, setOpenContent] = useState(false);
  const handleOpenContent = () => setOpenContent(true);
  const handleCloseContent = () => {
    setOpenContent(false);
    setShiftId("");
  }
  
  const [ads, setAds] = useState([]);
  const [shiftId, setShiftId] = useState("");
  const handleOpenShiftForm = (id, ad, start, end, weekday) => {
    console.log({id, ad, start, end, weekday});
    setOpenContent(true);
    setShiftFormData({startTime: start, endTime: end, ad: ad, weekday: weekday});
    setShiftId(id);
  }

  const handleShiftFormChange = (e) => {
    setShiftFormData({...shiftFormData, [e.target.name]: e.target.value});
  }

  const handleAdSelection = (e) => {
    const ad = ads.find((element) => element.name === e.target.value);
    setShiftFormData({...shiftFormData, ad: ad});
  };

  const handleWeekdaySelection = (e) => {
    setShiftFormData({...shiftFormData, weekday: e.target.value});
  };

  const handleShiftSubmit = async (e) => {
    e.preventDefault();
    const {ad, weekday, startTime, endTime} = shiftFormData;

    if (shiftId === "") {
      const value = {
        weekday,
        startTime,
        endTime,
        shiftAdId: ad.id,
        scheduleShiftsId: schedule.id,
        businessId: schedule.businessId
      }
      const result = await API.graphql(graphqlOperation(mutations.createShift,  { input: value }));
      setShifts([...shifts, result.data.createShift]);
    } else {
      const value = {
        id: shiftId,
        shiftAdId: ad.id,
        weekday,
        startTime,
        endTime
      }
      const result = await API.graphql(graphqlOperation(mutations.updateShift, { input: value }));
      let newArr = shifts.filter(x => x.id !== shiftId);
      setShifts([...newArr, result.data.updateShift])
    }
    handleCloseContent();
  }

  useEffect(()=>{

    (async () => {
      const resultAds = await API.graphql(graphqlOperation(queries.listAds, {filter: {businessId: {eq: schedule.businessId}}}));
      console.log(resultAds);
      setAds(resultAds.data.listAds.items);
    })();//IIFE

  }, [schedule.id])

  return (
    <>
      <Button className="add_button" onClick={()=>handleOpenShiftForm('', '', '', '')}>+Add Shift</Button>
      <Modal sx={{ 
            "& > .MuiBackdrop-root" : {
                    backdropFilter: "blur(2px)",
                    backgroundColor: "#FFFFFF55"
                  }
            }} open={openContent} onClose={handleCloseContent}>
        <Box className="add_input_margin smaller_frame" border={'1px solid grey'}>
          <Flex as="form" padding={30} direction="column" onSubmit={handleShiftSubmit}>
            <SelectField
              onChange={handleWeekdaySelection}
              label="Weekday"
              value={shiftFormData.weekday}
              required>
              <option value="">Please select a weekday</option>
              <option value="monday">Monday</option>
              <option value="tuesday">Tuesday</option>
              <option value="wednesday">Wednesday</option>
              <option value="thursday">Thursday</option>
              <option value="friday">Friday</option>
              <option value="saturday">Saturday</option>
              <option value="sunday">Sunday</option>
            </SelectField>
            <Flex direction="row">
              <SelectField
                name="startTime"
                label="Start Time"
                placeholder="shift start"
                onChange={handleShiftFormChange}
                value={shiftFormData.startTime}
                required>
                {startHours.map((hour, index) =>
                  <option value={index} key={index}>{hour}</option>
                )}
              </SelectField>
              {shiftFormData.startTime === '' ? <SelectField placeholder="shift end" label="End Time" disabled></SelectField> :
              <SelectField
                name="endTime"
                label="End Time"
                placeholder="shift end"
                onChange={handleShiftFormChange}
                value={shiftFormData.endTime}
                required>
                {endHours.map((hour, index) =>
                  (index > shiftFormData.startTime) ? <option value={index} key={index}>{hour}</option> : null
                )}
              </SelectField>}
            </Flex>
            <SelectField
              onChange={handleAdSelection}
              label="Running Ad"
              value={shiftFormData.ad.name}
              required>
              <option value="">Please select an ad</option>
              {ads.map((ad) =>
                <option key={ad.id}>{ad.name}</option>)}
            </SelectField>
            <Button type="submit" variation="primary" backgroundColor={"#429321"}>Submit</Button>
          </Flex>
        </Box>
      </Modal>
      <div style={{ height: '90%', background: 'lightgrey', overflow: 'auto', position: 'relative' }}>
        <Grid templateColumns="1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr">
          <Blank />
          <Outline />
          <Outline />
          <Outline />
          <Outline />
          <Outline />
          <Outline />
          <Outline />
        </Grid>
        <Grid templateColumns="1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr" style={{position: 'absolute', width: '100%', top: 0}}>
          <TimeAxis />
          <WeekdayContainer shifts={shifts} day="monday" setShifts={setShifts} openShiftForm={handleOpenShiftForm}/>
          <WeekdayContainer shifts={shifts} day="tuesday" setShifts={setShifts} openShiftForm={handleOpenShiftForm}/>
          <WeekdayContainer shifts={shifts} day="wednesday" setShifts={setShifts} openShiftForm={handleOpenShiftForm}/>
          <WeekdayContainer shifts={shifts} day="thursday" setShifts={setShifts} openShiftForm={handleOpenShiftForm}/>
          <WeekdayContainer shifts={shifts} day="friday" setShifts={setShifts} openShiftForm={handleOpenShiftForm}/>
          <WeekdayContainer shifts={shifts} day="saturday" setShifts={setShifts} openShiftForm={handleOpenShiftForm}/>
          <WeekdayContainer shifts={shifts} day="sunday" setShifts={setShifts} openShiftForm={handleOpenShiftForm}/>
        </Grid>
      </div>
    </>
  );
}

const textStyle = {
  padding: '0 5px'
}

const title = {
  fontWeight: '800',
  fontSize: '12px'
}

const subtitle = {
  fontWeight: '300',
  fontSize: '9px',
  margin: 2.5
}

const shiftStyle = {
  backgroundColor: 'lightgreen',
  borderRadius: '0 20px 20px 0',
  borderLeft: '2px solid green',
  width: '85%',
  position: 'relative',
  left: '7.5%',
  overflow: 'auto'
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 8,
  overflow: 'auto',
};

const adStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1200,
  height: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  overflow: 'auto',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',

};

const buttonStyle = { backgroundColor: '#429321', color: 'white', fontSize: 8, border: 'none' }

export default Scheduler;
