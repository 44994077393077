import React from 'react';
import { Collection } from '@aws-amplify/ui-react'
import GeofenceCard from './GeofenceCard';

const GeofenceList = ({ geofences, user, schedules, setGeofences, deleteGeo }) => {

  return (
    <>
      <div className="geo_headers_container">
        <p>Current Ad</p>
        <p>Name</p>
        <p>Schedule</p>
        <p>Last Seen On</p>
        <p>Actions</p>
        <p></p>
      </div>
      <div className="list_container">
        {geofences.length > 0 ? <Collection type="list" items={geofences} gap="0.25rem" >
          {(item, index) => (
            <GeofenceCard key={item.id} user={user} geofences={geofences} setGeofences={setGeofences} schedules={schedules} geofenceItem={item} deleteGeo={deleteGeo}/>
          )}
        </Collection> : <p style={{ color: "white", fontStyle: "italic", fontWeight: "100" }}>No geofences to display.</p>}
      </div>
    </>
  );
};

export default GeofenceList;
