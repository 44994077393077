import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import { Skeleton } from '@mui/material';

// helper function
function countObjectsWithActions(arr) {
  const nameCounts = {};

  for (const obj of arr) {
    const name = obj.deviceName;
    const action = obj.action;
    if (!nameCounts[name]) {
      nameCounts[name] = { name: name, notification: 0, tap: 0, scan: 0 };
    }
    nameCounts[name][action]++;
  }

  const resultArray = Object.values(nameCounts);

  return resultArray;
}

function AnalyticsDeviceChart({ business }) {
    const [deviceData, setDeviceData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      (async () => {
        // get the data for the given business
        const analyticsData = await API.graphql(graphqlOperation(queries.analyticsByBusinessId, { businessId: business.id, limit: 500}));

        // separate out the analytics by device
        const result = countObjectsWithActions(analyticsData.data.analyticsByBusinessId.items);

        setDeviceData(result);
      })();//IIFE
    }, [business.id]);
    
    return (
        <div className="center_right_analytics">
            <h3>Device Ranking</h3>
            {loading ? <Skeleton variant="rectangular" style={{ justifySelf: 'center', width: '80%', height: '80%' }} /> :
              <ResponsiveContainer>
                <BarChart
                  data={deviceData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                  width={100}
                  height={300}
                  layout={'vertical'}
                >
                  <CartesianGrid />
                  <XAxis type="number" axisLine={false} style={{ fontSize: '8px'}}/>
                  <YAxis type="category" dataKey="name" style={{ fontSize: '8px'}}/>
                  <Tooltip itemStyle={{ fontSize: '8px', textAlign: 'center' }} />
                  <Bar dataKey="notification" stackId="a" fill="#4E6576" />
                  <Bar dataKey="tap" stackId="a" fill="#4880FF" />
                  <Bar dataKey="scan" stackId="b" fill="#4880FF" />
                </BarChart>
              </ResponsiveContainer>}
          </div>
    );
}

export default AnalyticsDeviceChart;