import { Button } from '@aws-amplify/ui-react';
import { API, graphqlOperation } from 'aws-amplify';
import React from 'react';
import { useState } from 'react';
import { updateBeacon, updateBusiness, updateLocation } from '../graphql/mutations';
import { listBeacons, listLocations } from '../graphql/queries';

const CancelService = ({ user, business, setBusiness }) => {
  const [loading, setLoading] = useState(false);

  const pauseSubsciption = async () => {
    setLoading(true);

    try {
      // set the business to paused status
      const updateStatus = await API.graphql(graphqlOperation(updateBusiness, { input: { id: business.id, status: 'paused' }}));
      console.log(updateStatus);
  
      // go through each device and make sure they are turned off
      const devices = await API.graphql(graphqlOperation(listBeacons, { filter: { businessId: {eq: business.id }}}));
      devices.data.listBeacons.items.forEach(async item => {
        await API.graphql(graphqlOperation(updateBeacon, { input: { id: item.id, toggle: false }}));
      });
  
      // make all locations hidden in mobile (location field participantsToggle = false)
      const locations = await API.graphql(graphqlOperation(listLocations, { filter: { businessId: {eq: business.id }}}));
      locations.data.listLocations.items.forEach(async item => {
        await API.graphql(graphqlOperation(updateLocation, { input: { id: item.id, participantsToggle: false }}));
      });

      // take user to the business paused screen
      setLoading(false);
      setBusiness(updateStatus.data.updateBusiness);

    } catch (e) {
      console.log(e);
      setLoading(false); 
    }

  };

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
      <h1 style={{ color: 'white', fontSize: 32, margin: 0, width: '60%', textAlign: 'center' }}>Pause Subscription</h1>
      <h1 style={{ color: 'white', fontSize: 28, margin: 0, width: '60%', textAlign: 'center' }}>Need to pause? No worries!</h1>
      <h1 style={{ color: 'white', fontSize: 24, margin: 0, width: '60%', textAlign: 'center', fontFamily: 'MontserratLight' }}>Please click pause below and one of our customer service representatives will reach out to confirm and pause your subscription.</h1>
      <Button style={{ backgroundColor: 'white', borderRadius: 25, width: '25%', marginTop: 35 }} onClick={pauseSubsciption} disabled={user.role === "Manager" || loading ? true : false}>{loading ? 'Pausing...' : 'Pause subscription'}</Button>
    </div>
  );
}

export default CancelService;
