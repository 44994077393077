import React, { useState, useEffect } from 'react';
import { Collection } from '@aws-amplify/ui-react';
import ScheduleCard from './ScheduleCard';

const ScheduleList = ({ schedules, setSchedules, user, handleDelete, handleCopy }) => {

  return (
    <>
      <div className="ad_headers_container">
        <p>Schedule Name</p>
        <p>Schedule Overview</p>
        <p></p>
        <p>Current Status</p>
        <p>Actions</p>
        <p></p>
      </div>
      <div className="list_container">
        {schedules && schedules.length > 0 ?
        <Collection type="list" items={schedules} gap="0.25rem" >
          {(item, index) => (
            <ScheduleCard key={item.id} schedule={item} schedules={schedules} setSchedules={setSchedules} user={user} handleDelete={handleDelete} handleCopy={handleCopy} />
          )}
        </Collection> : <p style={{ color: "white", fontStyle: "italic", fontWeight: "100" }}>No schedules to display.</p>}
      </div>
    </>
  );
};

export default ScheduleList;
