import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Flex, SelectField, TextAreaField, TextField, Button } from '@aws-amplify/ui-react';
import * as queries from '../graphql/queries';
import AnalyticsHeatMap from './AnalyticsHeatMap.jsx';
import AnalyticsOverview from './AnalyticsOverview';
import AnalyticsChart from './AnalyticsChart';
import { Box, CircularProgress, IconButton, Modal, Skeleton } from '@mui/material';
import AnalyticsDeviceChart from './AnalyticsDeviceChart';
import AnalyticsAdChart from './AnalyticsAdChart';
import { Cancel, Check, Edit } from '@mui/icons-material';
import '../stylesheets/dashboard.css'
import NewsArticleCard from './NewsArticleCard';
import { createArticles, updateLeaderboard } from '../graphql/mutations';

function subtractDays(numOfDays, date = new Date()) {
  date.setDate(date.getDate() - numOfDays);
  return new Date(date);
}

const AnalyticsScreen = ({ business, user}) => {
  const [loading, setLoading] = useState(false);
  const [interval, setInterval] = useState('');
  const [editing, setEditing] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [totalUsers, setTotalUsers] = useState(0);
  const [downloadLeader, setDownloadLeader] = useState('N/A');
  const [winnerLastMonth, setWinnerLastMonth] = useState('N/A');

  // handling the data changes when a customer selects a different timeframe from dropdown
  const handleWeekSelection = async (e) => {
    console.log(e.target.value);
    setInterval(e.target.value);

    const currentDate = new Date();
    const weekStart = subtractDays(currentDate.getDay());
    const lastWeekStart = subtractDays(7, new Date(weekStart));
    const lastMonthStart = subtractDays(21, new Date(weekStart));

    if (e.target.value === "") {
      setStartDate(null);
      setEndDate(null);
      return;
    } else if (e.target.value === 'This week') {
      setStartDate(weekStart);
      setEndDate(currentDate);
    } else if (e.target.value === 'Last week') {
      setStartDate(lastWeekStart);
      setEndDate(weekStart);
    } else {
      setStartDate(lastMonthStart);
      setEndDate(currentDate);
    }
  }

  const [articles, setArticles] = useState([]);
  const [openNewsForm, setOpenNewsForm] = useState(false);
  const [newsFormData, setNewsFormData] = useState({ author: user.firstName + " " + user.lastName, title: '', description: ''})
  const handleOpenNewsForm = () => {
    setOpenNewsForm(true);
  };
  const handleNewsFormChange = (e) => {
    setNewsFormData({...newsFormData, [e.target.name]: e.target.value});
  };
  const handleCloseNewsForm = () => {
    setNewsFormData({ author: user.firstName + " " + user.lastName, title: '', description: ''});
    setOpenNewsForm(false);
  }
  const handleNewsSubmit = async (e) => {
    e.preventDefault();
    const { author, title, description } = newsFormData;

    const newsInput = {
      author,
      title,
      description
    }

    const result = await API.graphql(graphqlOperation(createArticles, {input: newsInput }));

    setArticles([...articles, result.data.createArticles])
    handleCloseNewsForm();
  };

  const submitLeaderboardUpdates = async (e) => {
    e.preventDefault();
    const leaderboardInput = {
      id: "814a0b4c-5a40-4c3a-bf21-1afff6efeb69",
      downloadLeader,
      lastMonthWinner: winnerLastMonth
    }

    await API.graphql(graphqlOperation(updateLeaderboard, { input: leaderboardInput }));
    setEditing(false);
  }

  useEffect(() => {
    (async () => {
      var nextToken = null;
      do {
        const tUsers = await API.graphql(graphqlOperation(queries.listUsers))
        setTotalUsers(prev => prev + tUsers.data.listUsers.items.length)
        nextToken = tUsers.data.listUsers.nextToken
      } while (nextToken)
    })();

    (async () => {
        const leaderboardDetails = await API.graphql(graphqlOperation(queries.getLeaderboard, { id: "814a0b4c-5a40-4c3a-bf21-1afff6efeb69" }))
        setDownloadLeader(leaderboardDetails.data.getLeaderboard.downloadLeader);
        setWinnerLastMonth(leaderboardDetails.data.getLeaderboard.lastMonthWinner);
    })();

    (async () => {
      const articlesRes = await API.graphql(graphqlOperation(queries.listArticles))
      setArticles(articlesRes.data.listArticles.items.sort((a, b) => new Date(a.createdAt) < new Date(b.createdAt)));
    })();

  }, []);

  return (
    <>
      <div className="title_container">
        <h1>Analytics</h1>
        <SelectField style={{ backgroundColor: '#374148', color: 'white'}} iconColor='white' onChange={handleWeekSelection}>
          <option value="">Please select a timeframe</option>
          <option>This week</option>
          <option>Last week</option>
          <option>Last 4 weeks</option>
        </SelectField>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', padding: '25px' }}>
        {!loading ? <div className="grid_layout_container">
          <AnalyticsOverview business={business} />
          <AnalyticsChart business={business} startDate={startDate} endDate={endDate} />
          <AnalyticsHeatMap business={business} startDate={startDate} endDate={endDate} />
          <AnalyticsDeviceChart business={business} />
          <AnalyticsAdChart business={business} />
        </div> : 
        <>
            <Skeleton variant="rectangular" style={{ justifySelf: 'center', width: '80%', height: '80%' }} /> 
            <Skeleton variant="rectangular" style={{ justifySelf: 'center', width: '80%', height: '20%', margin: '20px' }} /> 
        </>}
        <div className="right_analytics">
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <h3>App Analytics</h3>
            {user.role === "Admin" && <IconButton size='small' style={{ color: 'lightgrey' }} onClick={()=>setEditing(!editing)}>{editing ? <Cancel fontSize='small'/> : <Edit fontSize='small'/>}</IconButton>}
            {editing && <IconButton size='small' style={{ color: 'lightgrey' }} onClick={submitLeaderboardUpdates}>{<Check fontSize='small' />}</IconButton> }
          </div>
          <div style={{ marginBlock: 10 }}>
            <h4 style={{ color: '#A6B9C8'}}>Total Users</h4>
            <h4>{totalUsers}</h4>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', marginBlock: 10}}>
            <div>
              <h4 style={{ color: '#748591', fontSize: 8 }}>Current Download Leader</h4>
              {editing ? <TextField color={'white'} name="downloadLeader" value={downloadLeader} onChange={(e) => setDownloadLeader(e.target.value)}/> : <h4>{downloadLeader}</h4>}
            </div>
            <div>
              <h4 style={{ color: '#748591', fontSize: 8 }}>Last Month's Winner</h4>
              {editing ? <TextField color={'white'} name="winnerLastMonth" value={winnerLastMonth} onChange={(e) => setWinnerLastMonth(e.target.value)}/> : <h4>{winnerLastMonth}</h4>}
            </div>
          </div>
          <div style={{ width: '100%', borderTop: '1px solid #16191C', paddingBlock: 5}}>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
              <h3 style={{ fontSize: 14}}>DTGVL News</h3>
              {user.role === "Admin" ? <Button style={{ backgroundColor: '#429321', border: 'none', borderRadius: 2, color: 'white', width: 90, height: 32, fontSize: 8 }} onClick={handleOpenNewsForm}>+ Add Article</Button> : null}
            </div>
            {articles.map(article => <NewsArticleCard key={article.id} article={article} />)}
          </div>
        </div>
      </div>
      <Modal sx={{ 
            "& > .MuiBackdrop-root" : {
                    backdropFilter: "blur(2px)",
                    backgroundColor: "#FFFFFF55"
                  }
            }} open={openNewsForm} onClose={handleCloseNewsForm}>
        <Box className="add_input_margin padding_3 smaller_frame">
          <Flex as="form" padding={30} direction="column" onSubmit={handleNewsSubmit}>
            <h2 style={{ margin: 0, color: "black" }}>Add DTGVL News Article</h2>
            <h3 style={{ marginTop: 0}}>Author: {newsFormData.author}</h3>
            <TextField label="Article Title" width={'100%'} name="title" value={newsFormData.title} onChange={handleNewsFormChange}/>
            <TextAreaField label="Article Description" rows={3} width={'100%'} name="description" value={newsFormData.description} onChange={handleNewsFormChange}/>
            <Button type="submit" variation="primary" backgroundColor={"#429321"}>Submit</Button>
          </Flex>
        </Box>
      </Modal>
    </>
  );
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  height: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  overflow: 'auto',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  color: '#757575',
  fontSize: 13
};

export default AnalyticsScreen;
