import React from 'react';

function NewsArticleCard({ article }) {
    return (
        <div style={{ width: '100%', borderBottom: '1px solid #16191C', padding: 20, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <h1 style={{ fontSize: 12, color: '#A6B9C8', margin: 0}}>{article.author}</h1>
                <p style={{ fontSize: 10, color: '#EEEEEE', margin: 0}}>{new Date(article.createdAt).toDateString()}</p>
            </div>
            <h3 style={{ textAlign: 'left', fontSize: 10}}>{article.title}</h3>
            <h5 style={{ fontSize: 9, marginTop: 5}}>{article.description}</h5>
        </div>
    );
}

export default NewsArticleCard;