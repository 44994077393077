import React, { useState } from "react";
import Switch from "@mui/material/Switch";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { CancelOutlined } from "@mui/icons-material";
import { API, Storage, graphqlOperation } from "aws-amplify";
import {
  Card,
  Text,
  Image,
  Button,
  TextField,
  Flex,
} from "@aws-amplify/ui-react";

const cardStyle = {
  border: "0.5px solid lightgrey",
  width: "100%",
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
  justifyItems: "center",
  alignItems: "center",
};

const baseUrl =
  "https://dtgvlv2f04838c8631b4596b8b3acde06c1239b145859-dev.s3.amazonaws.com/public/";

function LocationCard({
  index,
  location,
  locations,
  setLocations,
  user,
  deleteLocation,
}) {
  const [locImage, setLocImage] = useState(null);
  const [locFile, setLocFile] = useState(null);
  const [partsImage, setPartsImage] = useState(null);
  const [partsFile, setPartsFile] = useState(null);
  const [openContent, setOpenContent] = useState(false);
  const handleOpenContent = () => setOpenContent(true);
  const handleCloseContent = () => {
    setOpenContent(false);
    setLocImage(null);
    setPartsImage(null);
    setEditLocation(false);
  };

  const handleUpdateLocIcon = async (e) => {
    var ext = e.target.value.match(/\.([^\.]+)$/)[1];
    switch (ext) {
      case "jpg":
      case "png":
        setLocImage(URL.createObjectURL(e.target.files[0]));
        setLocFile(e.target.files[0]);
        break;
      default:
        alert("This file type is not supported");
        e.target.value = "";
    }
  };

  const handleUpdatePartsIcon = async (e) => {
    setPartsImage(URL.createObjectURL(e.target.files[0]));
    setPartsFile(e.target.files[0]);
  };

  const [locationId, setLocationId] = useState("");

  const [openLocationForm, setOpenLocationForm] = useState(false);
  const [locationFormData, setLocationFormData] = useState({
    name: "",
    address: "",
    description: "",
    phone: "",
    hours: "",
    email: "",
    websiteUrl: "",
  });

  const [editLocation, setEditLocation] = useState(false);
  const handleEditLocation = (tOrF, item) => {
    if (tOrF) {
      setLocationFormData({
        name: item.name,
        address: item.address,
        description: item.description,
        hours: item.hours,
        email: item.email,
        phone: item.phone,
        websiteUrl: item.websiteUrl,
      });
    }
    setEditLocation(tOrF);
  };

  const handleLocationFormChange = (e) => {
    setLocationFormData({
      ...locationFormData,
      [e.target.name]: e.target.value,
    });
  };
  const handleLocationSubmit = async (e) => {
    e.preventDefault();
    const { name, address, description, phone, email, websiteUrl, hours } =
      locationFormData;

    if (locFile && partsFile) {
      try {
        // update location icon & participants page image
        const uploadLocNameFile = locFile.name.split(" ").join("_");
        const uploadPartsNameFile = partsFile.name.split(" ").join("_");

        const locationId = await uploadFile(uploadLocNameFile, locFile);
        const partsId = await uploadFile(uploadPartsNameFile, partsFile);
        const locationParams = {
          id: location.id,
          name,
          address,
          description,
          hours,
          phone,
          email,
          websiteUrl,
          locationLocIconId: locationId,
          locationParticipantsImageId: partsId,
        };

        const result = await API.graphql(
          graphqlOperation(mutations.updateLocation, { input: locationParams })
        );

        let newArr = locations.filter((x) => x.id !== location.id);
        setLocations([...newArr, result.data.updateLocation]);
      } catch (error) {
        alert(JSON.stringify(error));
        return;
      }
    } else if (locFile) {
      try {
        // update location icon & participants page image
        const uploadLocNameFile = locFile.name.split(" ").join("_");

        const locationId = await uploadFile(uploadLocNameFile, locFile);
        const locationParams = {
          id: location.id,
          name,
          address,
          description,
          hours,
          phone,
          email,
          websiteUrl,
          locationLocIconId: locationId,
        };

        const result = await API.graphql(
          graphqlOperation(mutations.updateLocation, { input: locationParams })
        );

        let newArr = locations.filter((x) => x.id !== location.id);
        setLocations([...newArr, result.data.updateLocation]);
      } catch (error) {
        alert(JSON.stringify(error));
        return;
      }
    } else if (partsFile) {
      try {
        // update location icon & participants page image
        const uploadPartsNameFile = partsFile.name.split(" ").join("_");

        const partsId = await uploadFile(uploadPartsNameFile, partsFile);
        const locationParams = {
          id: location.id,
          name,
          address,
          description,
          hours,
          phone,
          email,
          websiteUrl,
          locationParticipantsImageId: partsId,
        };

        const result = await API.graphql(
          graphqlOperation(mutations.updateLocation, { input: locationParams })
        );

        let newArr = locations.filter((x) => x.id !== location.id);
        setLocations([...newArr, result.data.updateLocation]);
      } catch (error) {
        alert(JSON.stringify(error));
        return;
      }
    } else {
      try {
        const locationParams = {
          id: location.id,
          name,
          address,
          description,
          hours,
          phone,
          email,
          websiteUrl,
        };

        const result = await API.graphql(
          graphqlOperation(mutations.updateLocation, { input: locationParams })
        );

        let newArr = locations.filter((x) => x.id !== location.id);
        setLocations([...newArr, result.data.updateLocation]);
      } catch (error) {
        alert(JSON.stringify(error));
        return;
      }
    }

    handleEditLocation(false);
  };

  const uploadFile = async (name, file) => {
    try {
      const photoParams = {
        url: name,
      };
      await API.graphql(
        graphqlOperation(mutations.deletePhoto, {
          input: { id: location.locationLocIconId },
        })
      );
      const photoRes = await API.graphql(
        graphqlOperation(mutations.createPhoto, { input: photoParams })
      );

      // upload location icon to S3
      await Storage.remove(location.locationLocIconId);
      await Storage.put(photoRes.data.createPhoto["id"], file);

      return photoRes.data.createPhoto.id;
    } catch (e) {
      throw e;
    }
  };

  // handle location switch
  const handleChange = async (e, item, index) => {
    let update = [...locations];
    const input = {
      id: item.id,
      participantsToggle: !item.participantsToggle,
    };
    const result = await API.graphql(
      graphqlOperation(mutations.updateLocation, { input: input })
    );
    update[index] = result.data.updateLocation;
    setLocations(update);

    // all beacons at a given location need to be switched off when a location is turned off
    const beaconsAtLoc = await API.graphql(
      graphqlOperation(queries.listBeacons, {
        filter: { beaconLocationId: { eq: item.id } },
      })
    );
    beaconsAtLoc.data.listBeacons.items.forEach(async (beacon) => {
      await API.graphql(
        graphqlOperation(mutations.updateBeacon, {
          input: { id: beacon.id, toggle: !item.participantsToggle },
        })
      );
    });
  };

  return (
    <Card style={cardStyle}>
      <Image
        alt="Company icon"
        src={baseUrl + location.locationLocIconId}
        style={{ width: "50px", height: "50px", borderRadius: "50px" }}
      />
      <Text style={{ fontSize: "14px" }}>{location.name}</Text>
      <Switch
        id={location.id}
        checked={location.participantsToggle}
        onChange={(e) => handleChange(e, location, index)}
      />
      <div className="card_btn_wrapper">
        {user.role !== "Manager" ? (
          <>
            <IconButton
              aria-label="delete"
              onClick={() => deleteLocation(location.id)}
            >
              <DeleteIcon />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton aria-label="delete" disabled>
              <DeleteIcon />
            </IconButton>
          </>
        )}
      </div>
      <Button onClick={handleOpenContent} style={buttonStyle}>
        Show Details
      </Button>
      <Modal
        sx={{
          "& > .MuiBackdrop-root": {
            backdropFilter: "blur(2px)",
            backgroundColor: "#FFFFFF55",
          },
        }}
        open={openContent}
        onClose={handleCloseContent}
      >
        <Box className="add_input_margin larger_frame">
          <Flex
            as="form"
            padding={30}
            direction="column"
            onSubmit={handleLocationSubmit}
          >
            {editLocation ? (
              <IconButton
                style={{ position: "absolute", right: 0, top: 0 }}
                aria-label="cancel"
                size="large"
                onClick={() => handleEditLocation(false, location)}
              >
                <CancelOutlined />
              </IconButton>
            ) : (
              <IconButton
                style={{ position: "absolute", right: 0, top: 0 }}
                aria-label="delete"
                size="large"
                onClick={() => handleEditLocation(true, location)}
              >
                <EditIcon />
              </IconButton>
            )}
            <div style={flexAligned}>
              <div style={{ padding: 15, width: "50%" }}>
                <div style={flexAligned}>
                  <h3>Image for Participants Page</h3>
                  <div>
                    {editLocation && locImage ? (
                      <Image
                        alt="Company icon"
                        src={locImage}
                        style={{
                          width: "75px",
                          height: "75px",
                          borderRadius: "75px",
                        }}
                      />
                    ) : (
                      <Image
                        alt="Company icon"
                        src={baseUrl + location.locationLocIconId}
                        style={{
                          width: "75px",
                          height: "75px",
                          borderRadius: "75px",
                        }}
                      />
                    )}
                    {editLocation ? (
                      <TextField
                        type={"file"}
                        onChange={(e) =>
                          handleUpdateLocIcon(e, location.locationLocIconId)
                        }
                        style={{ width: 225 }}
                      />
                    ) : null}
                  </div>
                </div>
                <div style={flexAligned}>
                  <h3>Location Name</h3>
                  {editLocation ? (
                    <TextField
                      name="name"
                      value={locationFormData.name}
                      onChange={handleLocationFormChange}
                      isRequired={true}
                    />
                  ) : (
                    <p style={{ paddingInline: 10, textAlign: "end" }}>
                      {location.name}
                    </p>
                  )}
                </div>
                <div style={flexAligned}>
                  <h3>Address</h3>
                  {editLocation ? (
                    <TextField
                      name="address"
                      value={locationFormData.address}
                      onChange={handleLocationFormChange}
                      isRequired={true}
                    />
                  ) : (
                    <p style={{ paddingInline: 10, textAlign: "end" }}>
                      {location.address}
                    </p>
                  )}
                </div>
                <div style={flexAligned}>
                  <h3>Description</h3>
                  {editLocation ? (
                    <TextField
                      name="description"
                      value={locationFormData.description}
                      onChange={handleLocationFormChange}
                      isRequired={true}
                    />
                  ) : (
                    <p style={{ paddingInline: 10, textAlign: "end" }}>
                      {location.description}
                    </p>
                  )}
                </div>
              </div>
              <div style={{ paddingInline: 15, width: "50%" }}>
                <div style={flexAligned}>
                  <h3>Hours</h3>
                  {editLocation ? (
                    <TextField
                      name="hours"
                      value={locationFormData.hours}
                      onChange={handleLocationFormChange}
                      isRequired={true}
                    />
                  ) : (
                    <p style={{ paddingInline: 10, textAlign: "end" }}>
                      {location.hours}
                    </p>
                  )}
                </div>
                <div style={flexAligned}>
                  <h3>Phone Number</h3>
                  {editLocation ? (
                    <TextField
                      name="phone"
                      value={locationFormData.phone}
                      onChange={handleLocationFormChange}
                      isRequired={true}
                    />
                  ) : (
                    <p style={{ paddingInline: 10, textAlign: "end" }}>
                      {location.phone}
                    </p>
                  )}
                </div>
                <div style={flexAligned}>
                  <h3>Email Address</h3>
                  {editLocation ? (
                    <TextField
                      name="email"
                      value={locationFormData.email}
                      onChange={handleLocationFormChange}
                      isRequired={false}
                    />
                  ) : (
                    <p style={{ paddingInline: 10, textAlign: "end" }}>
                      {location.email}
                    </p>
                  )}
                </div>
                <div style={flexAligned}>
                  <h3>Website</h3>
                  {editLocation ? (
                    <TextField
                      name="websiteUrl"
                      value={locationFormData.websiteUrl}
                      onChange={handleLocationFormChange}
                    />
                  ) : (
                    <p style={{ paddingInline: 10, textAlign: "end" }}>
                      {location.websiteUrl}
                    </p>
                  )}
                </div>
                <div style={flexAligned}>
                  <h3>Image for Participants Page</h3>
                  <div>
                    {editLocation && partsImage ? (
                      <Image
                        alt="Company participant's page image"
                        src={partsImage}
                        style={{ width: "250px", height: "200px" }}
                      />
                    ) : (
                      <Image
                        alt="Company participant's page image"
                        src={baseUrl + location.locationParticipantsImageId}
                        style={{ width: "250px", height: "200px" }}
                      />
                    )}
                    {editLocation ? (
                      <TextField
                        type={"file"}
                        onChange={(e) =>
                          handleUpdatePartsIcon(
                            e,
                            location.locationParticipantsImageId
                          )
                        }
                        style={{ width: 225 }}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            {editLocation ? (
              <Button
                type="submit"
                variation="primary"
                backgroundColor={"#429321"}
              >
                Submit
              </Button>
            ) : null}
          </Flex>
        </Box>
      </Modal>
    </Card>
  );
}

const buttonStyle = {
  backgroundColor: "#429321",
  color: "white",
  width: "75%",
  alignSelf: "center",
};
const flexAligned = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  color: "#757575",
  fontSize: 13,
  alignItems: "flex-start",
};
export default LocationCard;
