import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { Button, TextField, SelectField, Flex } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import {
  Modal,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import NFCList from "./NFCList";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { CircularProgress } from "@mui/material";

const NFCTags = ({ user, business, setBusiness, adminOverride }) => {
  /* Dialog Box */
  const [open, setOpen] = useState(false);
  const [nfcId, setNfcId] = useState(null);

  const handleClickOpen = (id) => {
    setNfcId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setNfcId(null);
    setOpen(false);
  };
  /* NFC Form */
  const [openNFCForm, setOpenNFCForm] = useState(false);
  const [nfcFormData, setNFCFormData] = useState({
    name: "",
    uuid: "",
    value: "",
    nfcType: "ad",
    schedule: "",
  });
  const [nfcs, setNFCs] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [analytics, setAnalytics] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleOpenNFCForm = () => setOpenNFCForm(true);
  const handleCloseNFCForm = () => setOpenNFCForm(false);
  const handleNFCFormChange = (e) => {
    setNFCFormData({ ...nfcFormData, [e.target.name]: e.target.value });
  };

  const handleNfcSubmit = async (e) => {
    e.preventDefault();
    const { name, uuid, value, nfcType, schedule } = nfcFormData;

    const nfc = {
      name,
      uuid,
      value,
      nfcType,
      toggle: true,
      businessId: business.id,
      nfcScheduleId: schedule.id,
    };
    const result = await API.graphql(
      graphqlOperation(mutations.createNfc, { input: nfc })
    );
    const updateNFCCount = await API.graphql(
      graphqlOperation(mutations.updateBusiness, {
        input: { id: business.id, numberNFCs: business.numberNFCs + 1 },
      })
    );
    setBusiness(updateNFCCount.data.updateBusiness);

    setNFCs([...nfcs, result.data.createNfc]);
    handleCloseNFCForm();
  };

  const handleNfcDelete = async (nfcId) => {
    let responseDB, responseB;
    // Delete from dB
    responseDB = await API.graphql(
      graphqlOperation(mutations.deleteNfc, { input: { id: nfcId } })
    );

    // reduce Geofence count by 1
    responseB = await API.graphql(
      graphqlOperation(mutations.updateBusiness, {
        input: { id: business.id, numberNFCs: business.numberNFCs - 1 },
      })
    );
    setBusiness(responseB.data.updateBusiness);

    // filter out the deleted geofence and rerender the list
    var filtered = nfcs.filter(function (value, index, arr) {
      console.log(value.id + " = " + responseDB.data.deleteNfc.id);
      return value.id != responseDB.data.deleteNfc.id;
    });
    setNFCs(filtered);
    console.log("Successfully deleted NFC " + nfcId);
  };

  const handleScheduleSelection = (e) => {
    const schedule = schedules.find(
      (element) => element.name === e.target.value
    );
    setNFCFormData({ ...nfcFormData, schedule: schedule });
  };

  useEffect(() => {
    setLoading(true);
    //useEffect function must return a cleanup function or nothing
    (async () => {
      if (adminOverride) {
        const resultNFCs = await API.graphql(
          graphqlOperation(queries.listNfcs)
        );
        setNFCs(resultNFCs.data.listNfcs.items);

        const resultSchedules = await API.graphql(
          graphqlOperation(queries.listSchedules)
        );
        setSchedules(resultSchedules.data.listSchedules.items);

        const resultAnalytics = await API.graphql(
          graphqlOperation(queries.listAnalytics, {
            filter: { device: { eq: "nfc" } },
            limit: 500,
          })
        );
        setAnalytics(resultAnalytics.data.listAnalytics.items);

        setLoading(false);
      } else {
        const resultNFCs = await API.graphql(
          graphqlOperation(queries.listNfcs, {
            filter: { businessId: { eq: business.id } },
          })
        );
        setNFCs(resultNFCs.data.listNfcs.items);

        const resultSchedules = await API.graphql(
          graphqlOperation(queries.listSchedules, {
            filter: { businessId: { eq: business.id } },
          })
        );
        setSchedules(resultSchedules.data.listSchedules.items);

        const resultAnalytics = await API.graphql(
          graphqlOperation(queries.analyticsByBusinessId, {
            businessId: business.id,
            filter: { device: { eq: "nfc" } },
            limit: 500,
          })
        );
        setAnalytics(resultAnalytics.data.analyticsByBusinessId.items);

        setLoading(false);
      }
    })(); //IIFE
  }, [!adminOverride ? business.id : adminOverride]);

  return (
    <>
      <div className="title_container">
        <h1>NFC Manager Page</h1>
        {user.role === "Admin" ? (
          !adminOverride ? (
            <Button className="add_button" onClick={handleOpenNFCForm}>
              + New NFC
            </Button>
          ) : (
            <Button className="add_button" onClick={handleOpenNFCForm} disabled>
              + New NFC
            </Button>
          )
        ) : null}
      </div>
      {loading ? (
        <CircularProgress />
      ) : (
        <NFCList
          nfcs={nfcs}
          setNfcs={setNFCs}
          user={user}
          schedules={schedules}
          deleteNfc={handleClickOpen}
          analytics={analytics}
        />
      )}
      <Modal
        sx={{
          "& > .MuiBackdrop-root": {
            backdropFilter: "blur(2px)",
            backgroundColor: "#FFFFFF55",
          },
        }}
        open={openNFCForm}
        onClose={handleCloseNFCForm}
      >
        <Box className="add_input_margin padding_3 smaller_frame">
          <Flex
            as="form"
            padding={30}
            direction="column"
            onSubmit={handleNfcSubmit}
          >
            <TextField
              name="name"
              label="NFC Name"
              placeholder="NFC name"
              onChange={handleNFCFormChange}
              isRequired={true}
            />
            <SelectField
              name="nfcType"
              label="NFC Type"
              value={nfcFormData.nfcType}
              onChange={handleNFCFormChange}
              isRequired={true}
            >
              <option value="webpage">Webpage</option>
              <option value="ad">Advertisement</option>
            </SelectField>
            {nfcFormData.nfcType === "webpage" ? (
              <TextField
                name="value"
                label="Webpage URL"
                placeholder="https://example.com"
                onChange={handleNFCFormChange}
                isRequired={true}
              />
            ) : (
              <SelectField
                label="Ad Schedule"
                onChange={handleScheduleSelection}
                required
              >
                <option value="">Please select an ad schedule</option>
                {schedules.map((schedule) => (
                  <option key={schedule.id}>{schedule.name}</option>
                ))}
              </SelectField>
            )}
            <Button
              type="submit"
              variation="primary"
              backgroundColor={"#429321"}
            >
              Submit
            </Button>
          </Flex>
        </Box>
      </Modal>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Delete ${nfcId}?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this NFC? Doing so will remove this
            NFC and all associated data
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => handleNfcDelete(nfcId)} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NFCTags;
