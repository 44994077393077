import React from "react";
import { Collection } from "@aws-amplify/ui-react";
import NFCCard from "./NFCCard";

const NFCList = ({ nfcs, setNfcs, user, schedules, deleteNfc, analytics }) => {
  return (
    <>
      <div className="qr_headers_container">
        <p>Current Ad</p>
        <p>NFC Tag Name</p>
        <p>Taps</p>
        <p>Business</p>
        <p>NFC Type</p>
        <p>Created</p>
        <p>Actions</p>
      </div>
      <div className="list_container">
        {nfcs && nfcs.length > 0 ? (
          <Collection type="list" items={nfcs} gap="0.25rem">
            {(item, index) => (
              <NFCCard
                key={item.id}
                nfcs={nfcs}
                setNfcs={setNfcs}
                schedules={schedules}
                user={user}
                nfc={item}
                deleteNfc={deleteNfc}
                analytics={analytics}
              />
            )}
          </Collection>
        ) : (
          <p style={{ color: "white", fontStyle: "italic", fontWeight: "100" }}>
            No NFCs to display.
          </p>
        )}
      </div>
    </>
  );
};

export default NFCList;
