import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  Button,
  TextField,
  SelectField,
  Flex,
  Text,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import {
  Modal,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ScheduleList from "./ScheduleList";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SpecialEventsList from "./SpecialEventsList";

const startHours = [
  "12:00AM",
  "1:00AM",
  "2:00AM",
  "3:00AM",
  "4:00AM",
  "5:00AM",
  "6:00AM",
  "7:00AM",
  "8:00AM",
  "9:00AM",
  "10:00AM",
  "11:00AM",
  "12:00PM",
  "1:00PM",
  "2:00PM",
  "3:00PM",
  "4:00PM",
  "5:00PM",
  "6:00PM",
  "7:00PM",
  "8:00PM",
  "9:00PM",
  "10:00PM",
  "11:00PM",
];
const endHours = [
  "12:00AM",
  "1:00AM",
  "2:00AM",
  "3:00AM",
  "4:00AM",
  "5:00AM",
  "6:00AM",
  "7:00AM",
  "8:00AM",
  "9:00AM",
  "10:00AM",
  "11:00AM",
  "12:00PM",
  "1:00PM",
  "2:00PM",
  "3:00PM",
  "4:00PM",
  "5:00PM",
  "6:00PM",
  "7:00PM",
  "8:00PM",
  "9:00PM",
  "10:00PM",
  "11:00PM",
  "12:00AM",
];

const Schedule = ({ user, business }) => {
  /* Dialog Boxes */
  const [open, setOpen] = useState(false);
  const [scheduleId, setScheduleId] = useState(null);

  const handleClickOpen = (id) => {
    setScheduleId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setScheduleId(null);
    setOpen(false);
  };
  const [openEvent, setOpenEvent] = useState(false);
  const [eventId, setEventId] = useState(null);

  const handleClickOpenEvent = (id) => {
    setEventId(id);
    setOpenEvent(true);
  };

  const handleCloseEvent = () => {
    setEventId(null);
    setOpenEvent(false);
  };
  /* Schedule Form */
  const [openScheduleForm, setOpenScheduleForm] = useState(false);
  const [scheduleFormData, setScheduleFormData] = useState({ name: "" });
  const [schedules, setSchedules] = useState([]);

  const handleOpenScheduleForm = () => setOpenScheduleForm(true);
  const handleCloseScheduleForm = () => setOpenScheduleForm(false);
  const handleScheduleFormChange = (e) => {
    setScheduleFormData({
      ...scheduleFormData,
      [e.target.name]: e.target.value,
    });
  };
  const handleScheduleSubmit = async (e) => {
    e.preventDefault();
    const { name } = scheduleFormData;
    const schedule = {
      name,
      status: false,
      businessId: business.id,
    };
    const result = await API.graphql(
      graphqlOperation(mutations.createSchedule, { input: schedule })
    );

    setSchedules([...schedules, result.data.createSchedule]);
    handleCloseScheduleForm();
  };

  const handleScheduleDelete = async (id) => {
    // Delete from dB
    let responseDB = await API.graphql(
      graphqlOperation(mutations.deleteSchedule, { input: { id: id } })
    );

    // filter out the deleted schedule and rerender the list
    var filtered = schedules.filter(function (value, index, arr) {
      return value.id != responseDB.data.deleteSchedule.id;
    });
    setSchedules(filtered);
    console.log("Successfully deleted Schedule " + id);
  };

  const handleCopy = async (id) => {
    const initSchedule = await API.graphql(
      graphqlOperation(queries.getSchedule, { id: id })
    );
    const copyInput = {
      name: "Copy of " + initSchedule.data.getSchedule.name,
      status: false,
      businessId: business.id,
    };
    const dupSchedule = await API.graphql(
      graphqlOperation(mutations.createSchedule, { input: copyInput })
    );

    const initShifts = await API.graphql(
      graphqlOperation(queries.listShifts, {
        filter: { scheduleShiftsId: { eq: id } },
      })
    );
    initShifts.data.listShifts.items.forEach(async (item) => {
      const shiftInput = {
        weekday: item.weekday,
        startTime: item.startTime,
        endTime: item.endTime,
        shiftAdId: item.shiftAdId,
        scheduleShiftsId: dupSchedule.data.createSchedule.id,
        businessId: item.businessId,
      };
      await API.graphql(
        graphqlOperation(mutations.createShift, { input: shiftInput })
      );
    });

    setSchedules([...schedules, dupSchedule.data.createSchedule]);
  };
  /***************/

  /* Events Form */
  const [openEventForm, setOpenEventForm] = useState(false);
  const [eventFormData, setEventFormData] = useState({
    startTime: "",
    endTime: "",
    ad: "",
    date: null,
    name: "",
  });
  const [events, setEvents] = useState([]);
  const [ads, setAds] = useState([]);

  const handleOpenEventForm = () => setOpenEventForm(true);
  const handleCloseEventForm = () => setOpenEventForm(false);
  const handleEventFormChange = (e) => {
    setEventFormData({ ...eventFormData, [e.target.name]: e.target.value });
  };
  const handleEventSubmit = async (e) => {
    e.preventDefault();

    const { ad, date, startTime, endTime, name } = eventFormData;

    const value = {
      name,
      date: date.toLocaleDateString(),
      startTime,
      endTime,
      eventAdId: ad.id,
      businessId: business.id,
    };
    const result = await API.graphql(
      graphqlOperation(mutations.createEvent, { input: value })
    );
    setEvents([...events, result.data.createEvent]);

    handleCloseEventForm();
  };

  const handleEventDelete = async (id) => {
    // Delete from dB
    let responseDB = await API.graphql(
      graphqlOperation(mutations.deleteEvent, { input: { id: id } })
    );

    // filter out the deleted schedule and rerender the list
    var filtered = events.filter(function (value, index, arr) {
      return value.id != responseDB.data.deleteEvent.id;
    });
    setEvents(filtered);
    console.log("Successfully deleted event " + id);
  };

  const handleAdSelection = (e) => {
    const ad = ads.find((element) => element.name === e.target.value);
    setEventFormData({ ...eventFormData, ad: ad });
  };

  useEffect(() => {
    const d = new Date();

    //useEffect function must return a cleanup function or nothing
    (async () => {
      const result = await API.graphql(
        graphqlOperation(queries.listSchedules, {
          filter: { businessId: { eq: business.id } },
        })
      );
      setSchedules(result.data.listSchedules.items);
    })(); //IIFE

    (async () => {
      const result = await API.graphql(
        graphqlOperation(queries.listEvents, {
          filter: { businessId: { eq: business.id } },
        })
      );
      let displayedEvents = [];
      result.data.listEvents.items.forEach((event) => {
        let converted = new Date(event.date);
        console.log(
          new Date(
            converted.getFullYear(),
            converted.getMonth(),
            converted.getDate(),
            event.endTime
          )
        );
        if (
          new Date(
            converted.getFullYear(),
            converted.getMonth(),
            converted.getDate(),
            event.endTime
          ) >= d
        )
          displayedEvents.push(event);
      });
      setEvents(displayedEvents);
    })(); //IIFE

    (async () => {
      const resultAds = await API.graphql(
        graphqlOperation(queries.listAds, {
          filter: { businessId: { eq: business.id } },
        })
      );
      console.log(resultAds);
      setAds(resultAds.data.listAds.items);
    })(); //IIFE
  }, [business.id]);

  return (
    <>
      <div className="title_container">
        <h1>Schedule Manager Page</h1>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "90%",
          marginLeft: "5%",
          alignItems: "center",
        }}
      >
        <h1 style={{ color: "white", fontSize: 18 }}>
          Recurring Weekly Schedule
        </h1>
        <Button className="add_button" onClick={handleOpenScheduleForm}>
          + New Schedule
        </Button>
      </div>
      <ScheduleList
        schedules={schedules}
        setSchedules={setSchedules}
        user={user}
        handleDelete={handleClickOpen}
        handleCopy={handleCopy}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "90%",
          marginLeft: "5%",
          marginTop: 25,
          alignItems: "center",
        }}
      >
        <h1 style={{ color: "white", fontSize: 18 }}>
          Special Event Overrides
        </h1>
        <Button className="add_button" onClick={handleOpenEventForm}>
          + New Event
        </Button>
      </div>
      <SpecialEventsList
        events={events}
        setEvents={setEvents}
        user={user}
        handleDelete={handleClickOpenEvent}
      />
      <Modal
        sx={{
          "& > .MuiBackdrop-root": {
            backdropFilter: "blur(2px)",
            backgroundColor: "#FFFFFF55",
          },
        }}
        open={openScheduleForm}
        onClose={handleCloseScheduleForm}
      >
        <Box className="add_input_margin padding_3 smaller_frame">
          <Flex
            as="form"
            padding={30}
            direction="column"
            onSubmit={handleScheduleSubmit}
          >
            <TextField
              name="name"
              label="Schedule Name"
              placeholder="Schedule Name"
              onChange={handleScheduleFormChange}
              isRequired={true}
            />
            <Button
              type="submit"
              variation="primary"
              backgroundColor={"#429321"}
            >
              Submit
            </Button>
          </Flex>
        </Box>
      </Modal>
      <Modal
        sx={{
          "& > .MuiBackdrop-root": {
            backdropFilter: "blur(2px)",
            backgroundColor: "#FFFFFF55",
          },
        }}
        open={openEventForm}
        onClose={handleCloseEventForm}
      >
        <Box className="add_input_margin padding_3 smaller_frame">
          <Flex
            as="form"
            padding={30}
            direction="column"
            onSubmit={handleEventSubmit}
          >
            <TextField
              name="name"
              label="Event Name"
              placeholder="Special Event Name"
              onChange={handleEventFormChange}
              isRequired={true}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Text style={{ color: "hsl(210deg 25% 25%)" }}>Event Date</Text>
              <DatePicker
                selected={eventFormData.date}
                onChange={(date) =>
                  setEventFormData({ ...eventFormData, date: date })
                }
                placeholderText={"Select a date"}
                className="date_picker_style"
              />
            </div>
            <Flex direction="row">
              <SelectField
                name="startTime"
                label="Start Time"
                placeholder="Event start"
                onChange={handleEventFormChange}
                value={eventFormData.startTime}
                required
              >
                {startHours.map((hour, index) => (
                  <option value={index} key={index}>
                    {hour}
                  </option>
                ))}
              </SelectField>
              {eventFormData.startTime === "" ? (
                <SelectField
                  placeholder="Event end"
                  label="End Time"
                  disabled
                ></SelectField>
              ) : (
                <SelectField
                  name="endTime"
                  label="End Time"
                  placeholder="shift end"
                  onChange={handleEventFormChange}
                  value={eventFormData.endTime}
                  required
                >
                  {endHours.map((hour, index) =>
                    index > eventFormData.startTime ? (
                      <option value={index} key={index}>
                        {hour}
                      </option>
                    ) : null
                  )}
                </SelectField>
              )}
            </Flex>
            <SelectField
              onChange={handleAdSelection}
              label="Running Ad"
              value={eventFormData.ad.name}
              required
            >
              <option value="">Please select an ad</option>
              {ads.map((ad) => (
                <option key={ad.id}>{ad.name}</option>
              ))}
            </SelectField>
            <Button
              type="submit"
              variation="primary"
              backgroundColor={"#429321"}
            >
              Submit
            </Button>
          </Flex>
        </Box>
      </Modal>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Delete ${scheduleId}?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this schedule? Doing so will remove
            this schedule and all associated data
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => handleScheduleDelete(scheduleId)} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openEvent}
        onClose={handleCloseEvent}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Delete ${eventId}?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this event? Doing so will remove
            this event and all associated data
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEvent}>Cancel</Button>
          <Button onClick={() => handleEventDelete(eventId)} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Schedule;
