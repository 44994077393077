import React, { useState, useEffect } from 'react';
import Amplify, { API, Auth, graphqlOperation } from 'aws-amplify';
import logo from './../assets/dtgvl_logo.png';
import businessIcon from './../assets/building.png';
import { Button, Flex, SelectField, TextField } from '@aws-amplify/ui-react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import * as queries from './../graphql/queries';
import * as mutations from './../graphql/mutations';
import '@aws-amplify/ui-react/styles.css';
import './../stylesheets/dashboard.css'

import BusinessInfo from './BusinessInfo';
import Beacons from './Beacons';
import Ads from './Ads';
import Schedule from './Schedule';
import QRCodes from './QRCodes';
import NFCTags from './NFCTags';
import MainMenu from './MainMenu';
import Geofences from './Geofences';
import AnalyticsScreen from './AnalyticsScreen';
import Help from './Help';
import CancelService from './CancelService';
import PassReset from './PassReset';

const Dashboard = ({user}) => {
  const [loading, setLoading] = useState(false);
  const [adminOverride, setAdminOverride] = useState(false);

  // renders the screen displayed
  const [renderContent, setRenderContent] = useState("menu");

  // State variable for all businesses (Admin only)
  const [businesses, setBusinesses] = useState([]);

  // Form variables (Admin only)
  const [openBusinessForm, setOpenBusinessForm] = useState(false);
  const [businessFormData, setBusinessFormData] = useState({name: '', note: '', subscriptionRate: 0 });

  // business that the user is associated with
  const [business, setBusiness] = useState(null);

  /* Business Form (Admin only) */
  const handleOpenBusinessForm = () => setOpenBusinessForm(true);
  const handleCloseBusinessForm = () => setOpenBusinessForm(false);
  const handleBusinessFormChange = (e) => setBusinessFormData({...businessFormData, [e.target.name]: e.target.value});
  const handleBusinessSubmit = async () => {
    setLoading(true);
    const {name, note, subscriptionRate} = businessFormData;
    const business = {
      name,
      note,
      subscriptionRate,
      numberBeacons: 0,
      numberLocations: 0,
      numberGeofences: 0,
      numberQRs: 0,
      numberNFCs: 0,
      numberBeacons: 0,
      status: 'active'
    }
    const result = await API.graphql(graphqlOperation(mutations.createBusiness,  {input: business }));

    setBusinesses([...businesses, result.data.createBusiness]);
    handleCloseBusinessForm();
    setLoading(false);
  }
  /**********************/

  // Menu variables & functions
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelection = (e) => {
    console.log(e.target.value);
    if (e.target.value === 'All businesses') {
      setAdminOverride(true);
      setBusiness(null);
    } else {
      setBusiness(businesses.find((element) => element.name === e.target.value));
      setAdminOverride(false);
    }
  };

  const menuSelect = (option) => {
    setAnchorEl(null);
    setRenderContent(option);
  }

  const unpauseBusiness = async () => {
    setLoading(true);
    try {
      // set the business to active status
      const businessUpdated = await API.graphql(graphqlOperation(mutations.updateBusiness, { input: { id: business.id, status: 'active' }}))
      
      // go through each device and make sure they are turned on
      const devs = await API.graphql(graphqlOperation(queries.listBeacons, { filter: { businessId: {eq: business.id }}}));
      devs.data.listBeacons.items.forEach(async item => {
        await API.graphql(graphqlOperation(mutations.updateBeacon, { input: { id: item.id, toggle: true }}));
      });
      
      // make all locations visible in mobile (location field participantsToggle = true)
      const locs = await API.graphql(graphqlOperation(queries.listLocations, { filter: { businessId: {eq: business.id }}}));
      locs.data.listLocations.items.forEach(async item => {
        await API.graphql(graphqlOperation(mutations.updateLocation, { input: { id: item.id, participantsToggle: true }}));
      });
      
      setLoading(false);
      setBusiness(businessUpdated.data.updateBusiness);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  useEffect(()=>{
    if (user.role === "Admin") {
      console.log("admin user");
      (async () => {
        const result = await API.graphql(graphqlOperation(queries.listBusinesses));
        setBusinesses(result.data.listBusinesses.items);
      })();//IIFE
    } else {
      console.log("non-admin user");
      (async () => {
        const result = await API.graphql(graphqlOperation(queries.getBusiness, {id: user.businessId}));
        setBusiness(result.data.getBusiness);
      })();//IIFE
    }
// break here
  }, [])

  return (
    <div className="admin_dashboard">
      {/* ADD BUSINESS FORM */}
      <Modal sx={{ 
            "& > .MuiBackdrop-root" : {
                    backdropFilter: "blur(2px)",
                    backgroundColor: "#FFFFFF55"
                  }
            }} open={openBusinessForm} onClose={handleCloseBusinessForm}>
        <Box className="add_input_margin smaller_frame">
          <Flex as="form" direction="column" padding={30} onSubmit={handleBusinessSubmit}>
            <h2 style={{ margin: 0 }}>Add Business</h2>
            <TextField style={{ color: '#757575'}} name="name" label="Business Name" placeholder="Business name" onChange={handleBusinessFormChange} isRequired={true}/>
            <TextField style={{ color: '#757575'}} name="note" label="Note" placeholder="Note" onChange={handleBusinessFormChange} isRequired={true}/>
            <SelectField
              name="subscriptionRate"
              label="Subscription Rate"
              placeholder="Please select a subscription"
              onChange={handleBusinessFormChange}
              style={{ color: '#757575'}}
            >
              <option value="84">Business Standard - $84/month</option>
            </SelectField>
            <Button type="submit" variation="primary" backgroundColor={"#429321"} disabled={loading}>{loading ? "Submitting..." : "Submit"}</Button>
          </Flex>
        </Box>
      </Modal>

      {/* NAVIGATION BAR ATOP THE PAGE */}
      {renderContent !== 'menu' ?
      <div style={{ width: '90%', height: '10%', display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'space-between', padding: 15, left: '5%' }}>
        <img src={logo} onClick={()=>setRenderContent('menu')} style={{ height: 40, position: 'relative', cursor: 'pointer', maxWidth: 125}} />

        {user.role === "Admin" && renderContent !== 'reset' && renderContent !== 'help' ?
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <img src={businessIcon} style={{ width: '30px', height: '35px', margin: 10 }} />
          {business ? <SelectField style={{ backgroundColor: 'rgba(255, 255, 255, 0.32)' }} onChange={handleSelection} value={business.name}>
            <option>Please select a business</option>
            {(renderContent === 'geofences' || renderContent === 'beacons' || renderContent === 'nfc tag' || renderContent === 'qr codes') ? <option>All businesses</option> : null}
            {businesses.map((business) =>
              <option key={business.id}>{business.name}</option>)}
          </SelectField> :
          <SelectField style={{ backgroundColor: 'rgba(255, 255, 255, 0.32)' }} onChange={handleSelection}>
            <option>Please select a business</option>
            {(renderContent === 'geofences' || renderContent === 'beacons' || renderContent === 'nfc tag' || renderContent === 'qr codes') ? <option>All businesses</option> : null}
            {businesses.map((business) =>
              <option key={business.id}>{business.name}</option>)}
          </SelectField>}
        </div> :
        <div style={{ width: '100px' }}></div>}

        {(renderContent === "business info" && user.role === "Admin") ?
          <Button className="add_button" onClick={handleOpenBusinessForm}>+ Add Business</Button> : null}

        <IconButton
          onClick={handleClick}
          size="large"
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {adminOverride ? null : <MenuItem name="business info" onClick={() => menuSelect("business info")}>Business Info</MenuItem>}
          <MenuItem name="beacons" onClick={() => menuSelect("beacons")}>Beacons</MenuItem>
          {adminOverride ? null : <MenuItem name="ads" onClick={() => menuSelect("ads")}>Ads</MenuItem>}
          {adminOverride ? null : <MenuItem name="schedule" onClick={() => menuSelect("schedule")}>Schedule</MenuItem>}
          <MenuItem name="qrs" onClick={() => menuSelect("qr codes")}>QR Codes</MenuItem>
          <MenuItem name="nfcs" onClick={() => menuSelect("nfc tag")}>NFC Tags</MenuItem>
          <MenuItem name="geofences" onClick={() => menuSelect("geofences")}>Geofences</MenuItem>
          {adminOverride ? null : <MenuItem name="analytics" onClick={() => menuSelect("analytics")}>Analytics</MenuItem>}
          {adminOverride ? null : <MenuItem name="reset" onClick={() => menuSelect("reset")}>Password Reset</MenuItem>}
          {adminOverride ? null : <MenuItem name="help" onClick={() => menuSelect("help")}>Help & FAQs</MenuItem>}
          {adminOverride ? null : <MenuItem name="cancel" onClick={() => menuSelect("cancel")}>Cancel Service</MenuItem>}
          <MenuItem onClick={() => Auth.signOut()}>Logout</MenuItem>
        </Menu>
      </div> : null}

      {/* ALL THE PAGES */}
      <div className="admin_content">
        {(business && business.status !== 'active') ? 
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '60%' }}>
          <h1 style={{ color: 'white'}}>Business paused</h1>
          {user.role === "Admin" ? <Button onClick={unpauseBusiness} style={{ backgroundColor: 'white', borderRadius: 25, width: '25%', marginTop: 35}} disabled={loading ? true : false}>{loading ? 'Re-activating' : 'Re-activate Business'}</Button> : null}
        </div> 
        : (renderContent === "menu") ? <MainMenu render={{setRenderContent}} /> 
        : (renderContent === "reset") ? <PassReset business={business} user={user}/> 
        : (renderContent === "help") ? <Help business={business} /> :
        business || adminOverride ?
            (renderContent === "business info") ? <BusinessInfo business={business} user={user} setBusiness={setBusiness}/> :
            (renderContent === "beacons") ? <Beacons adminOverride={adminOverride} business={business} setBusiness={setBusiness} user={user}/> :
            (renderContent === "ads") ? <Ads business={business} user={user}/> :
            (renderContent === "schedule") ? <Schedule business={business} user={user}/> :
            (renderContent === "qr codes") ? <QRCodes adminOverride={adminOverride} business={business} setBusiness={setBusiness} user={user}/> :
            (renderContent === "geofences") ? <Geofences adminOverride={adminOverride} business={business} setBusiness={setBusiness} user={user}/> :
            (renderContent === "analytics") ? <AnalyticsScreen business={business} user={user}/> :
            (renderContent === "cancel") ? <CancelService business={business} setBusiness={setBusiness} user={user}/> :
            <NFCTags adminOverride={adminOverride} business={business} setBusiness={setBusiness} user={user}/>
          : null}
      </div>
    </div>
  );
}

export default Dashboard;
