import { API, graphqlOperation } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { analyticsByBusinessId } from '../graphql/queries';
import { Skeleton } from '@mui/material';
  
function subtractDays(numOfDays, date = new Date()) {
  date.setDate(date.getDate() - numOfDays);
  return new Date(date);
}

function AnalyticsOverview({ business }) {
    const [loading, setLoading] = useState(false);

    const [notifsThisWeek, setNotifsThisWeek] = useState(0);
    const [tapsThisWeek, setTapsThisWeek] = useState(0);
    const [notifsLastWeek, setNotifsLastWeek] = useState(0);
    const [tapsLastWeek, setTapsLastWeek] = useState(0);
    const [notifsOverall, setNotifsOverall] = useState(0);
    const [tapsOverall, setTapsOverall] = useState(0);

    const currentDate = new Date();
    const weekStart = subtractDays(currentDate.getDay());
    const lastWeekStart = subtractDays(7, new Date(weekStart));

    useEffect(() => {
        (async () => {
            const analyticsForOverview = await API.graphql(graphqlOperation(analyticsByBusinessId, { businessId: business.id, filter: { or: [{ device: {eq: 'geofence'}}, {device: {eq: 'beacon'}}]}, limit: 500}));
            console.log(analyticsForOverview.data.analyticsByBusinessId);

            setNotifsThisWeek(analyticsForOverview.data.analyticsByBusinessId.items.filter(analytic => analytic.action === 'notification' && analytic.createdAt < currentDate && analytic.createdAt > weekStart).length);
            setTapsThisWeek(analyticsForOverview.data.analyticsByBusinessId.items.filter(analytic => analytic.action === 'tap' && analytic.createdAt < currentDate && analytic.createdAt > weekStart).length);
            setNotifsLastWeek(analyticsForOverview.data.analyticsByBusinessId.items.filter(analytic => analytic.action === 'notification' && analytic.createdAt < weekStart && analytic.createdAt > lastWeekStart).length);
            setTapsLastWeek(analyticsForOverview.data.analyticsByBusinessId.items.filter(analytic => analytic.action === 'tap' && analytic.createdAt < weekStart && analytic.createdAt > lastWeekStart).length);
            setNotifsOverall(analyticsForOverview.data.analyticsByBusinessId.items.filter(analytic => analytic.action === 'notification').length);
            setTapsOverall(analyticsForOverview.data.analyticsByBusinessId.items.filter(analytic => analytic.action === 'tap').length);
        })();
    }, [business.id])
        
    return (
        <div className="top_left_analytics">
            {loading ? 
            <>
                <h3 className="item1">App Notifications<br/>Beacons & Geofences</h3>
                <Skeleton variant="rectangular" style={{ justifySelf: 'center', width: '80%', height: '80%' }} /> 
                <Skeleton variant="rectangular" style={{ justifySelf: 'center', width: '80%', height: '80%' }} /> 
                <Skeleton variant="rectangular" style={{ justifySelf: 'center', width: '80%', height: '80%' }} /> 
                <Skeleton variant="rectangular" style={{ justifySelf: 'center', width: '80%', height: '80%' }} /> 
                <Skeleton variant="rectangular" style={{ justifySelf: 'center', width: '80%', height: '80%' }} /> 
                <Skeleton variant="rectangular" style={{ justifySelf: 'center', width: '80%', height: '80%' }} /> 
            </> :
            <>
                <h3 className="item1">App Notifications<br/>Beacons & Geofences</h3>
                <h3>{notifsThisWeek}<br/><p>Notifications this week</p></h3>
                <h3>{tapsThisWeek}<br/><p>Taps this week</p></h3>
                <h3>{notifsLastWeek}<br/><p>Notifications last week</p></h3>
                <h3>{tapsLastWeek}<br/><p>Taps last week</p></h3>
                <h3>{notifsOverall}<br/><p>Notifications all time</p></h3>
                <h3>{tapsOverall}<br/><p>Taps all time</p></h3>
            </>}
        </div>
    );
}

export default AnalyticsOverview;