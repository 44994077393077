import React from 'react';
import { Collection } from '@aws-amplify/ui-react';
import EventCard from './EventCard';

function SpecialEventsList({ events, setEvents, user, handleDelete }) {
    return (
        <>
            <div className="ad_headers_container">
                <p>Event Name</p>
                <p>Time {"&"} Date</p>
                <p></p>
                <p></p>
                <p>Ad Selected</p>
                <p>Actions</p>
            </div>
            <div className="list_container">
                {events && events.length > 0 ?
                <Collection type="list" items={events} gap="0.25rem" >
                    {(item, index) => (
                        <EventCard key={item.id} event={item} events={events} setEvents={setEvents} user={user} handleDelete={handleDelete} />
                    )}
                </Collection> : <p style={{ color: "white", fontStyle: "italic", fontWeight: "100" }}>No special events to display.</p>}
            </div>
        </>
    );
}

export default SpecialEventsList;