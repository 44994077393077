/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const ad = /* GraphQL */ `
  query Ad {
    ad {
      adIcon
      name
      title
      description
      type
      url
      redirectLink
      businessId
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const getBusiness = /* GraphQL */ `
  query GetBusiness($id: ID!) {
    getBusiness(id: $id) {
      name
      numberBeacons
      numberGeofences
      numberLocations
      numberQRs
      numberNFCs
      note
      subscriptionRate
      users {
        nextToken
        __typename
      }
      locations {
        nextToken
        __typename
      }
      beacons {
        nextToken
        __typename
      }
      status
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBusinesses = /* GraphQL */ `
  query ListBusinesses(
    $filter: ModelBusinessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinesses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        numberBeacons
        numberGeofences
        numberLocations
        numberQRs
        numberNFCs
        note
        subscriptionRate
        status
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      firstName
      lastName
      email
      role
      gender
      birthday
      businessId
      username
      id
      createdAt
      updatedAt
      businessUsersId
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        firstName
        lastName
        email
        role
        gender
        birthday
        businessId
        username
        id
        createdAt
        updatedAt
        businessUsersId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      name
      locIcon {
        url
        id
        createdAt
        updatedAt
        __typename
      }
      participantsToggle
      address
      longitude
      latitude
      description
      hours
      phone
      email
      websiteUrl
      reservations
      participantsImage {
        url
        id
        createdAt
        updatedAt
        __typename
      }
      sections
      businessId
      id
      createdAt
      updatedAt
      businessLocationsId
      locationLocIconId
      locationParticipantsImageId
      __typename
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        participantsToggle
        address
        longitude
        latitude
        description
        hours
        phone
        email
        websiteUrl
        reservations
        sections
        businessId
        id
        createdAt
        updatedAt
        businessLocationsId
        locationLocIconId
        locationParticipantsImageId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBeacon = /* GraphQL */ `
  query GetBeacon($id: ID!) {
    getBeacon(id: $id) {
      name
      businessName
      uuid
      toggle
      businessId
      location {
        name
        participantsToggle
        address
        longitude
        latitude
        description
        hours
        phone
        email
        websiteUrl
        reservations
        sections
        businessId
        id
        createdAt
        updatedAt
        businessLocationsId
        locationLocIconId
        locationParticipantsImageId
        __typename
      }
      schedule {
        name
        status
        businessId
        id
        createdAt
        updatedAt
        __typename
      }
      note
      lastSeen
      battery
      id
      createdAt
      updatedAt
      businessBeaconsId
      beaconLocationId
      beaconScheduleId
      __typename
    }
  }
`;
export const listBeacons = /* GraphQL */ `
  query ListBeacons(
    $filter: ModelBeaconFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBeacons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        businessName
        uuid
        toggle
        businessId
        note
        lastSeen
        battery
        id
        createdAt
        updatedAt
        businessBeaconsId
        location {
          id
          name
        }
        schedule {
          id
          name
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGeofence = /* GraphQL */ `
  query GetGeofence($id: ID!) {
    getGeofence(id: $id) {
      name
      businessName
      toggle
      lat
      lng
      businessId
      schedule {
        name
        status
        businessId
        id
        createdAt
        updatedAt
        __typename
      }
      lastSeen
      radius
      id
      createdAt
      updatedAt
      geofenceScheduleId
      __typename
    }
  }
`;
export const listGeofences = /* GraphQL */ `
  query ListGeofences(
    $filter: ModelGeofenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGeofences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        businessName
        toggle
        lat
        lng
        businessId
        lastSeen
        radius
        id
        createdAt
        updatedAt
        schedule {
          id
          name
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQr = /* GraphQL */ `
  query GetQr($id: ID!) {
    getQr(id: $id) {
      name
      qrType
      value
      toggle
      businessId
      schedule {
        name
        status
        businessId
        id
        createdAt
        updatedAt
        __typename
      }
      id
      createdAt
      updatedAt
      qrScheduleId
      __typename
    }
  }
`;
export const listQrs = /* GraphQL */ `
  query ListQrs($filter: ModelQrFilterInput, $limit: Int, $nextToken: String) {
    listQrs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        qrType
        value
        toggle
        businessId
        id
        createdAt
        updatedAt
        schedule {
          id
          name
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNfc = /* GraphQL */ `
  query GetNfc($id: ID!) {
    getNfc(id: $id) {
      name
      uuid
      nfcType
      value
      toggle
      businessId
      schedule {
        name
        status
        businessId
        id
        createdAt
        updatedAt
        __typename
      }
      id
      createdAt
      updatedAt
      nfcScheduleId
      __typename
    }
  }
`;
export const listNfcs = /* GraphQL */ `
  query ListNfcs(
    $filter: ModelNfcFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNfcs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        uuid
        nfcType
        value
        toggle
        businessId
        id
        createdAt
        updatedAt
        schedule {
          id
          name
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAd = /* GraphQL */ `
  query GetAd($id: ID!) {
    getAd(id: $id) {
      adIcon
      name
      title
      description
      type
      url
      redirectLink
      businessId
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAds = /* GraphQL */ `
  query ListAds($filter: ModelAdFilterInput, $limit: Int, $nextToken: String) {
    listAds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        adIcon
        name
        title
        description
        type
        url
        redirectLink
        businessId
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSchedule = /* GraphQL */ `
  query GetSchedule($id: ID!) {
    getSchedule(id: $id) {
      name
      shifts {
        nextToken
        __typename
      }
      status
      businessId
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSchedules = /* GraphQL */ `
  query ListSchedules(
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchedules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        status
        businessId
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getShift = /* GraphQL */ `
  query GetShift($id: ID!) {
    getShift(id: $id) {
      ad {
        adIcon
        name
        title
        description
        type
        url
        redirectLink
        businessId
        id
        createdAt
        updatedAt
        __typename
      }
      startTime
      endTime
      weekday
      businessId
      id
      createdAt
      updatedAt
      scheduleShiftsId
      shiftAdId
      __typename
    }
  }
`;
export const listShifts = /* GraphQL */ `
  query ListShifts(
    $filter: ModelShiftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShifts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        startTime
        endTime
        weekday
        businessId
        id
        createdAt
        updatedAt
        scheduleShiftsId
        ad {
          type
          url
          id
          name
          title
          description
          redirectLink
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      name
      ad {
        adIcon
        name
        title
        description
        type
        url
        redirectLink
        businessId
        id
        createdAt
        updatedAt
        __typename
      }
      date
      startTime
      endTime
      businessId
      id
      createdAt
      updatedAt
      eventAdId
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        date
        startTime
        endTime
        businessId
        id
        createdAt
        updatedAt
        ad {
          type
          url
          id
          name
          title
          description
          redirectLink
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPhoto = /* GraphQL */ `
  query GetPhoto($id: ID!) {
    getPhoto(id: $id) {
      url
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPhotos = /* GraphQL */ `
  query ListPhotos(
    $filter: ModelPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhotos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        url
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAnalytics = /* GraphQL */ `
  query GetAnalytics($id: ID!) {
    getAnalytics(id: $id) {
      action
      user {
        firstName
        lastName
        email
        role
        gender
        birthday
        businessId
        username
        id
        createdAt
        updatedAt
        businessUsersId
        __typename
      }
      device
      deviceName
      businessId
      ad {
        adIcon
        name
        title
        description
        type
        url
        redirectLink
        businessId
        id
        createdAt
        updatedAt
        __typename
      }
      id
      createdAt
      updatedAt
      analyticsUserId
      analyticsAdId
      __typename
    }
  }
`;
export const listAnalytics = /* GraphQL */ `
  query ListAnalytics(
    $filter: ModelAnalyticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnalytics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        action
        device
        deviceName
        businessId
        id
        createdAt
        updatedAt
        analyticsUserId
        analyticsAdId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getArticles = /* GraphQL */ `
  query GetArticles($id: ID!) {
    getArticles(id: $id) {
      author
      title
      description
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listArticles = /* GraphQL */ `
  query ListArticles(
    $filter: ModelArticlesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listArticles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        author
        title
        description
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLeaderboard = /* GraphQL */ `
  query GetLeaderboard($id: ID!) {
    getLeaderboard(id: $id) {
      downloadLeader
      lastMonthWinner
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listLeaderboards = /* GraphQL */ `
  query ListLeaderboards(
    $filter: ModelLeaderboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeaderboards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        downloadLeader
        lastMonthWinner
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        firstName
        lastName
        email
        role
        gender
        birthday
        businessId
        username
        id
        createdAt
        updatedAt
        businessUsersId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const beaconByUID = /* GraphQL */ `
  query BeaconByUID(
    $uuid: String!
    $sortDirection: ModelSortDirection
    $filter: ModelBeaconFilterInput
    $limit: Int
    $nextToken: String
  ) {
    beaconByUID(
      uuid: $uuid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        name
        businessName
        uuid
        toggle
        businessId
        note
        lastSeen
        battery
        id
        createdAt
        updatedAt
        businessBeaconsId
        beaconLocationId
        beaconScheduleId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const anayticByAction = /* GraphQL */ `
  query AnayticByAction(
    $action: String!
    $businessId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAnalyticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    anayticByAction(
      action: $action
      businessId: $businessId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        action
        device
        deviceName
        businessId
        id
        createdAt
        updatedAt
        analyticsUserId
        analyticsAdId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const analyticByDevice = /* GraphQL */ `
  query AnalyticByDevice(
    $device: String!
    $businessId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAnalyticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    analyticByDevice(
      device: $device
      businessId: $businessId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        action
        device
        deviceName
        businessId
        id
        createdAt
        updatedAt
        analyticsUserId
        analyticsAdId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const analyticsByBusinessId = /* GraphQL */ `
  query AnalyticsByBusinessId(
    $businessId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAnalyticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    analyticsByBusinessId(
      businessId: $businessId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        action
        device
        deviceName
        businessId
        id
        createdAt
        updatedAt
        analyticsUserId
        ad {
          name
          url
          title
          description
          id
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
