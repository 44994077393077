import React from 'react';
import FAQCard from './FAQCard';
import faq1 from '../assets/faq_1.png';
import faq2 from '../assets/faq_2.png';
import faq3 from '../assets/faq_3.png';
import faq4 from '../assets/faq_4.png';
import faq5 from '../assets/faq_5.png';
import faq6 from '../assets/faq_6.png';
import faq7 from '../assets/faq_7.png';
import faq8 from '../assets/faq_8.png';
import faq9 from '../assets/faq_9.png';
import { useMediaQuery } from '@mui/material';

const Help = () => {
  const matches600 = useMediaQuery('(min-width:600px)');
  const matches1000 = useMediaQuery('(min-width:1000px)');


    return(
        <>
            <div className="title_container">
                <h1>HELP & FAQS</h1>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: matches1000 ? '1fr 1fr 1fr' : matches600 ? '1fr 1fr' : '1fr', width: '100%', gap: '0.25rem', placeItems: 'center', alignItems: 'center' }}>
                <FAQCard title={'How to use the DTGVL Dashboard'} image={faq1} subtext={'Click the button below to find out more about how to use the DTGVL dashboard.'} buttonText={'Read more'} redirect={'link'}/>
                <FAQCard title={'Possible Uses For Transmitters'} image={faq2} subtext={'Curious about the differences in use cases between the shorter and longer range transmitters? Click below.'} buttonText={'Read more'} redirect={'link'}/>
                <FAQCard title={'Understanding DTGVL Analytics'} image={faq3} subtext={'Want to understand more about the analytics that DTGVL captures? Click below to read more.'} buttonText={'Read more'} redirect={'link'}/>
                <FAQCard title={'Improving CTR (Click-Through-Rate)'} image={faq4} subtext={'Click the button below to find out more about how to use the DTGVL dashboard.'} buttonText={'Read more'} redirect={'link'}/>
                <FAQCard title={'Scheduling Campaigns'} image={faq5} subtext={'Click the button below to find out more about how to use the DTGVL dashboard.'} buttonText={'Read more'} redirect={'link'}/>
                <FAQCard title={'Landing Page Creator'} image={faq6} subtext={'Click the button below to find out more about how to use the DTGVL dashboard.'} buttonText={'Read more'} redirect={'link'}/>
                <FAQCard title={'URL Landing Page'} image={faq7} subtext={'Click the button below to find out more about how to use the DTGVL dashboard.'} buttonText={'Read more'} redirect={'link'}/>
                <FAQCard title={'Creating & Using Forms to Collect Info'} image={faq8} subtext={'Click the button below to find out more about how to use the DTGVL dashboard.'} buttonText={'Read more'} redirect={'link'}/>
                <FAQCard title={'How to use the DTGVL Dashboard'} image={faq9} subtext={'Click the button below to find out more about how to use the DTGVL dashboard.'} buttonText={'Read more'} redirect={'link'}/>
            </div>
        </>
    )

}

export default Help