import React from 'react';
import { Collection } from '@aws-amplify/ui-react'
import BeaconCard from './BeaconCard';

const BeaconList = ({ user, beacons, setBeacons, deleteBeacon, locations, schedules, adminOverride, setBusiness }) => {

  return (
    <>
      <div className="headers_container">
        <p>Current Ad</p>
        <p>Name</p>
        <p>Location</p>
        <p>Schedule</p>
        <p>Last Seen On</p>
        <p>Broadcast</p>
        <p>Actions</p>
      </div>
      <div className="list_container">
        {beacons.length > 0 ? <Collection type="list" items={beacons} gap="0.25rem" >
          {(item, index) => (
            <BeaconCard key={item.id} adminOverride={adminOverride} beacon={item} beacons={beacons} setBeacons={setBeacons} user={user} deleteBeacon={deleteBeacon} locations={locations} schedules={schedules} setBusiness={setBusiness} />
          )}
        </Collection> : <p style={{ color: "white", fontStyle: "italic", fontWeight: "100" }}>No beacons to display.</p>}
      </div>
    </>
  );
};

export default BeaconList;
