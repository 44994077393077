import React, { useState, useEffect, useRef } from 'react';
import { Collection, Card, Text, Button, Image, TextField, SelectField, Flex } from '@aws-amplify/ui-react'
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { Download } from '@mui/icons-material';
import { QRCodeCanvas } from 'qrcode.react';
import { getBusiness, listEvents, listShifts } from '../graphql/queries';
import { updateQr } from '../graphql/mutations';

const baseUrl = "https://dtgvlv2f04838c8631b4596b8b3acde06c1239b145859-dev.s3.amazonaws.com/public/"

const QRCard = ({ qr, qrs, setQrs, schedules, user, deleteQr, analytics }) => {
  const [openContent, setOpenContent] = useState(false);
  const handleOpenContent = () => setOpenContent(true);
  const handleCloseContent = () => setOpenContent(false);
  const [shift, setShift] = useState(null);
  const [event, setEvent] = useState(null);
  const [businessName, setBusinessName] = useState('');
  const [scans, setScans] = useState(0);

  // state variables for displaying qr update form
  const [qrId, setQrId] = useState("");
  const [openQRForm, setOpenQRForm] = useState(false);
  const [qrFormData, setQRFormData] = useState({name: '', qrType: '', schedule: '', value: ''});

  const handleOpenQRForm = (id, name, qrType, schedule, value) => {
    setOpenQRForm(true);
    setQRFormData({name: name, qrType: qrType, schedule: schedule, value: value});
    setQrId(id);
  }
  const handleCloseQRForm = () => setOpenQRForm(false);
  const handleQRFormChange = (e) => {
    setQRFormData({...qrFormData, [e.target.name]: e.target.value});
  }

  const handleDownload = (canvas) => {
    console.log(canvas);
    if(!canvas) return;
    const link = document.createElement("a");
    link.download = `qrcode_${qr.name}.png`;
    link.href = canvas.toDataURL();
    link.click();
  };

  const handleQRSubmit = async (e) => {
    e.preventDefault();
    const {name, qrType, schedule, value} = qrFormData;
    let qr;

    if (qrType === "ad") {
      qr = {
        id: qrId,
        name,
        qrType,
        qrScheduleId: schedule.id
      }
    } else {
      qr = {
        id: qrId,
        name,
        qrType,
        value
      }
    }
    const result = await API.graphql(graphqlOperation(updateQr,  {input: qr}));

    let newArr = qrs.filter(x => x.id !== qrId);
    setQrs([...newArr, result.data.updateQr])
    handleCloseQRForm();
  }

  const handleScheduleSelection = (e) => {
    const schedule = schedules.find((element) => element.name === e.target.value);
    setQRFormData({...qrFormData, schedule: schedule});
  };

  const week = ["sunday","monday","tuesday","wednesday","thursday","friday","saturday"];
  const d = new Date();
  let day = week[d.getDay()];
  let hour = d.getHours();
  
  useEffect(() => {
    (async () => {
      const busName = await API.graphql(graphqlOperation(getBusiness, { id: qr.businessId }));
      setBusinessName(busName.data.getBusiness.name)
    })();

    (async () => {
      const scansRes = analytics.filter(analytic => analytic.deviceName === qr.name);
      console.log(scansRes.length);
      setScans(scansRes.length)
    })();
  }, [qr.businessId])

  useEffect(()=>{

    if (qr.qrType === "ad") {
      (async () => {
        const shifts = await API.graphql(graphqlOperation(listShifts, {filter: {scheduleShiftsId: {eq: qr.schedule.id}, weekday: {eq: day}, startTime: {le: hour}, endTime: {gt: hour} }}));
        setShift(shifts.data.listShifts.items[0]);
      })();//IIFE

      (async () => {
        console.log(d.toLocaleDateString());
        const events = await API.graphql(graphqlOperation(listEvents, {filter: {businessId: {eq: qr.businessId}, date: {eq: d.toLocaleDateString()}, startTime: {le: hour}, endTime: {gt: hour} }}));
        console.log(events);
        setEvent(events.data.listEvents.items[0]);
      })();//IIFE
    }

  }, [qr.qrType === "ad" ? qr.schedule.id : null])

  return (
    <>
      <Card style={cardStyle}>
        <div className={`qr_holder_${qr.id}`}>
          <QRCodeCanvas size={65} value={'https://d1os8kn99h.execute-api.us-east-1.amazonaws.com/dev/returnurl/'+qr.id} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '100%', minWidth: 0, overflowX: "auto" }}>
          <Text style={{ fontSize: 16}}>{qr.name}</Text>
          {qr.qrType === "webpage" ? <Text style={{ fontSize: 8, color: 'grey', marginBlock: 5}}>Link: {qr.value}</Text> : <Text style={{ fontSize: 8, color: 'grey', marginBlock: 5}}>Schedule: {qr.schedule.name}</Text>}
          <Button onClick={handleOpenContent} style={buttonStyle}>View</Button>
        </div>
        <Text style={textStyle}>{scans}</Text>
        <Text style={textStyle}>{businessName}</Text>
        <Text style={textStyle}>{qr.qrType}</Text>
        <Text style={textStyle}>{new Date(qr.createdAt).toLocaleDateString()}</Text>
        <div className="card_btn_wrapper">
          {user.role !== "Manager" ?
            <>
              <IconButton aria-label="delete" size="large" onClick={()=>handleOpenQRForm(qr.id, qr.name, qr.qrType, qr.schedule, qr.value)}>
                <EditIcon />
              </IconButton>
              <IconButton aria-label="delete" onClick={()=>deleteQr(qr.id)}>
                <DeleteIcon />
              </IconButton>
              <IconButton aria-label="download" onClick={()=>handleDownload(document.querySelector(`.qr_holder_${qr.id} canvas`))}>
                <Download />
              </IconButton>
            </> :
            <>
              <IconButton aria-label="delete" size="large" disabled>
                <EditIcon />
              </IconButton>
              <IconButton aria-label="delete" disabled>
                <DeleteIcon />
              </IconButton>
              <IconButton aria-label="download" disabled>
                <Download />
              </IconButton>
            </>}
        </div>
      </Card>
      <Modal sx={{ 
            "& > .MuiBackdrop-root" : {
                    backdropFilter: "blur(2px)",
                    backgroundColor: "#FFFFFF55"
                  }
            }} open={openContent} onClose={handleCloseContent}>
          {qr.qrType === "webpage" ?
            <Box className="add_input_margin larger_frame">
              <iframe src={qr.value}></iframe> 
            </Box> :
            event ? 
              (event.ad.type) === "Image" ?
                <Box className="show_ad">
                  <Image
                  alt="Content"
                  src={baseUrl+event.ad.url}
                  width="400px"
                  height="667px"/>
                </Box> :
                <Box className="add_input_margin larger_frame">
                  <iframe src={event.ad.url}></iframe> 
                </Box> :
            shift ?
              (shift.ad.type) === "Image" ?
                <Box className='show_ad'>
                  <Image
                    alt="Content"
                    src={baseUrl+shift.ad.url}
                    width="400px"
                    height="667px"/>
                </Box> :
                <Box className="add_input_margin larger_frame">
                  <iframe src={shift.ad.url}></iframe>
                </Box>
              : <Box className="show_ad"><h1>Currently no ad running</h1></Box>}
      </Modal>
      <Modal sx={{ 
            "& > .MuiBackdrop-root" : {
                    backdropFilter: "blur(2px)",
                    backgroundColor: "#FFFFFF55"
                  }
            }} open={openQRForm} onClose={handleCloseQRForm}>
        <Box className="add_input_margin padding_3 smaller_frame">
          <Flex as="form" padding={30} direction="column" onSubmit={handleQRSubmit}>
            <TextField name="name" label="QR Name" value={qrFormData.name} onChange={handleQRFormChange} isRequired={true}/>
            <SelectField
              name="qrType"
              label="QR Type"
              value={qrFormData.qrType}
              disabled>
              <option>{qrFormData.qrType}</option>
            </SelectField>
            {qrFormData.qrType === "webpage" ?
              <TextField name="value" label="Webpage URL" value={qrFormData.value} onChange={handleQRFormChange} isRequired={true}/> :
              <SelectField label="Ad Schedule" value={qrFormData.schedule.name} onChange={handleScheduleSelection}>
                <option>Please select an ad schedule</option>
                {schedules.map((schedule) =>
                  <option key={schedule.id} value={schedule.name}>{schedule.name}</option>)}
              </SelectField>}
            <Button type="submit" variation="primary" backgroundColor={"#429321"}>Submit</Button>
          </Flex>
        </Box>
      </Modal>
    </>
  );
}

const textStyle = {
  fontSize: '12px',
  textAlign: 'center'
}
const buttonStyle = { backgroundColor: '#429321', color: 'white', fontSize: 8, width: '30%' }

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  height: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  overflow: 'auto',
  p: 8,
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex'
};

const cardStyle = {
  border: '0.5px solid lightgrey',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
  justifyItems: 'center',
  alignItems: 'center'
}

export default QRCard;
