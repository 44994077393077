import React, { useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Collection, Card, Text, Button, Image, TextField, SelectField, Flex } from '@aws-amplify/ui-react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import * as mutations from '../graphql/mutations';

const baseUrl = "https://dtgvlv2f04838c8631b4596b8b3acde06c1239b145859-dev.s3.amazonaws.com/public/"

const AdList = ({ ads, setAds, user, deleteAd }) => {
  // state variables for displaying ad content
  const [ad, setAd] = useState([]);
  const [openContent, setOpenContent] = useState(false);
  const handleOpenContent = (ad) => {
    setOpenContent(true);
    setAd(ad);
  }
  const handleCloseContent = () => setOpenContent(false);

  // state variables for displaying ad update form
  const [adId, setAdId] = useState("");
  const [openAdForm, setOpenAdForm] = useState(false);
  const [adFormData, setAdFormData] = useState({name: '', title: '', description: '', type: '', url: '', redirectLink: ''});

  const handleOpenAdForm = (id, name, title, description, type, url, redirectLink) => {
    console.log(type);
    setOpenAdForm(true);
    setAdFormData({name: name, title: title, description: description, type: type, url: url, redirectLink: redirectLink});
    setAdId(id);
  }
  const handleCloseAdForm = () => setOpenAdForm(false);
  const handleAdFormChange = (e) => {
    setAdFormData({...adFormData, [e.target.name]: e.target.value});
  }
  const handleAdSubmit = async (e) => {
    e.preventDefault();
    const {name, title, description, type, url, redirectLink} = adFormData;

    const ad = {
      id: adId,
      name,
      title,
      description,
      type,
      url,
      redirectLink,
    }

    const result = await API.graphql(graphqlOperation(mutations.updateAd, {input: ad }));

    let newArr = ads.filter(x => x.id !== adId);
    setAds([...newArr, result.data.updateAd])
    handleCloseAdForm();
  }

  const handleSelection = (e) => {
    if (e.target.name === "Image") {
      setAdFormData({...adFormData, [e.target.name]: e.target.value});
    } else {
      setAdFormData({...adFormData, [e.target.name]: e.target.value, redirectLink: ''});
    }
  };

  return (
    <>
      <div className="ad_headers_container">
        <p>Circular Icon</p>
        <p>Name</p>
        <p>Title</p>
        <p>Description</p>
        <p>Ad Viewer</p>
        <p>Actions</p>
      </div>
      <div className="list_container">
        {ads.length > 0 ? <Collection type="list" items={ads} gap="0.25rem" >
          {(item, index) => (
            <Card key={index} style={cardStyle}>
              <Image
                alt="Ad icon"
                src={baseUrl+item.adIcon}
                width="50px"
                height="50px"
                style={{ borderRadius: '50px' }}
                />
              <Text style={textStyle}>{item.name}</Text>
              <Text style={textStyle}>{item.title}</Text>
              <Text style={textStyle}>{item.description}</Text>
              <Button onClick={()=>handleOpenContent(item)} style={buttonStyle}>Show {item.type}</Button>
              <div className="card_btn_wrapper">
                {user.role !== "Manager" ?
                  <>
                    <IconButton aria-label="delete" size="large" onClick={()=>handleOpenAdForm(item.id, item.name, item.title, item.description, item.type, item.url, item.redirectLink)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={()=>deleteAd(item.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </> :
                  <>
                    <IconButton aria-label="delete" size="large" disabled>
                      <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete" disabled>
                      <DeleteIcon />
                    </IconButton>
                  </>}
              </div>
              <Modal sx={{ 
                "& > .MuiBackdrop-root" : {
                        backdropFilter: "blur(2px)",
                        backgroundColor: "#FFFFFF55"
                      }
                }} open={openAdForm} onClose={handleCloseAdForm}>
                <Box className="add_input_margin padding_3 smaller_frame">
                  <Flex as="form" padding={30} direction="column" onSubmit={handleAdSubmit}>
                    <TextField name="name" label="Ad Name" value={adFormData.name} onChange={handleAdFormChange} isRequired={true}/>
                    <TextField name="title" label="Ad Title" value={adFormData.title} onChange={handleAdFormChange} isRequired={true}/>
                    <TextField name="description" label="Ad Description" value={adFormData.description} onChange={handleAdFormChange} isRequired={true}/>
                    <SelectField name="type" label="Ad Type" value={adFormData.type} onChange={handleSelection} style={{ marginBottom: '15px' }} disabled>
                      <option>{adFormData.type}</option>
                    </SelectField>
                    {adFormData.type === "Image" ?
                        <TextField name="redirectLink" label="Redirect Link" value={adFormData.redirectLink} onChange={handleAdFormChange} isRequired={true}/>
                      : <TextField name="url" label="Website" value={adFormData.url} onChange={handleAdFormChange} isRequired={true}/>}
                    <Button type="submit" variation="primary" backgroundColor={"#429321"}>Submit</Button>
                  </Flex>
                </Box>
              </Modal>
            </Card>
          )}
        </Collection> : <p style={{ color: "white", fontStyle: "italic", fontWeight: "100" }}>No ads to display.</p>}
      </div>
      <Modal sx={{ 
            "& > .MuiBackdrop-root" : {
                    backdropFilter: "blur(2px)",
                    backgroundColor: "#FFFFFF55"
                  }
            }} open={openContent} onClose={handleCloseContent}>
          {ad.type === "Image" ?
          <Box className="show_ad">
            <Image
              alt="Content"
              src={baseUrl+ad.url}
              width="400px"
              height="667px"/>
          </Box> :
          <Box className="add_input_margin larger_frame">
            <iframe title="website_ad_holder" src={ad.url}></iframe>
          </Box>}
      </Modal>
    </>
  );
};

const textStyle = {
  fontSize: '12px',
  textAlign: 'center'
}
const buttonStyle = { backgroundColor: '#429321', color: 'white', height: '50px' };

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  overflow: 'auto',
};

const style2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  height: 500,
  boxShadow: 24,
  overflow: 'auto'
};

const cardStyle = {
  border: '0.5px solid lightgrey',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
  justifyItems: 'center',
  alignItems: 'center'
}

export default AdList;
