import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { API, graphqlOperation } from 'aws-amplify';
import { analyticsByBusinessId, data } from '../graphql/queries';
import Skeleton from '@mui/material/Skeleton';

function getWeekdayFromISODate(isoDate) {
    const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dateObj = new Date(isoDate);
    const weekdayIndex = dateObj.getDay();
    
    return weekdays[weekdayIndex];
}

function AnalyticsChart({ business, startDate, endDate }) {
    const [chart, setChart] = useState([]);

    useEffect(() => {
        if (!startDate || !endDate) return;
        else {
            (async () => {
                // get all the analytics for the business over the given time period
                const fetchedData = await API.graphql(graphqlOperation(analyticsByBusinessId, { businessId: business.id, limit: 500 }));
    
                // filter out by interval
                const data = fetchedData.data.analyticsByBusinessId.items.filter(entry => new Date(entry.createdAt) < endDate && new Date(entry.createdAt) > startDate);
    
                // separate all the analytics out by type
                const beaconNotifs = data.filter(entry => entry.action === 'notification' && entry.device === 'beacon');
                const beaconTaps = data.filter(entry => entry.action === 'tap' && entry.device === 'beacon');
                const geofenceNotifs = data.filter(entry => entry.action === 'notification' && entry.device === 'geofence');
                const geofenceTaps = data.filter(entry => entry.action === 'tap' && entry.device === 'geofence');
                const nfcTaps = data.filter(entry => entry.device === 'nfc');
                const qrScans = data.filter(entry => entry.device === 'qr');
    
                // filter out each data type by day
                setChart([
                    {
                        name: 'Sun',
                        'beacon notifications': beaconNotifs.filter(value => getWeekdayFromISODate(value.createdAt) === "Sunday").length,
                        'beacon taps': beaconTaps.filter(value => getWeekdayFromISODate(value.createdAt) === "Sunday").length,
                        'geofence notifications': geofenceNotifs.filter(value => getWeekdayFromISODate(value.createdAt) === "Sunday").length,
                        'geofence taps': geofenceTaps.filter(value => getWeekdayFromISODate(value.createdAt) === "Sunday").length,
                        'nfc taps': nfcTaps.filter(value => getWeekdayFromISODate(value.createdAt) === "Sunday").length,
                        'qr scans': qrScans.filter(value => getWeekdayFromISODate(value.createdAt) === "Sunday").length
                    },
                    {
                        name: 'Mon',
                        'beacon notifications': beaconNotifs.filter(value => getWeekdayFromISODate(value.createdAt) === "Monday").length,
                        'beacon taps': beaconTaps.filter(value => getWeekdayFromISODate(value.createdAt) === "Monday").length,
                        'geofence notifications': geofenceNotifs.filter(value => getWeekdayFromISODate(value.createdAt) === "Monday").length,
                        'geofence taps': geofenceTaps.filter(value => getWeekdayFromISODate(value.createdAt) === "Monday").length,
                        'nfc taps': nfcTaps.filter(value => getWeekdayFromISODate(value.createdAt) === "Monday").length,
                        'qr scans': qrScans.filter(value => getWeekdayFromISODate(value.createdAt) === "Monday").length
                    },
                    {
                        name: 'Tue',
                        'beacon notifications': beaconNotifs.filter(value => getWeekdayFromISODate(value.createdAt) === "Tuesday").length,
                        'beacon taps': beaconTaps.filter(value => getWeekdayFromISODate(value.createdAt) === "Tuesday").length,
                        'geofence notifications': geofenceNotifs.filter(value => getWeekdayFromISODate(value.createdAt) === "Tuesday").length,
                        'geofence taps': geofenceTaps.filter(value => getWeekdayFromISODate(value.createdAt) === "Tuesday").length,
                        'nfc taps': nfcTaps.filter(value => getWeekdayFromISODate(value.createdAt) === "Tuesday").length,
                        'qr scans': qrScans.filter(value => getWeekdayFromISODate(value.createdAt) === "Tuesday").length
                    },
                    {
                        name: 'Wed',
                        'beacon notifications': beaconNotifs.filter(value => getWeekdayFromISODate(value.createdAt) === "Wednesday").length,
                        'beacon taps': beaconTaps.filter(value => getWeekdayFromISODate(value.createdAt) === "Wednesday").length,
                        'geofence notifications': geofenceNotifs.filter(value => getWeekdayFromISODate(value.createdAt) === "Wednesday").length,
                        'geofence taps': geofenceTaps.filter(value => getWeekdayFromISODate(value.createdAt) === "Wednesday").length,
                        'nfc taps': nfcTaps.filter(value => getWeekdayFromISODate(value.createdAt) === "Wednesday").length,
                        'qr scans': qrScans.filter(value => getWeekdayFromISODate(value.createdAt) === "Wednesday").length
                    },
                    {
                        name: 'Thu',
                        'beacon notifications': beaconNotifs.filter(value => getWeekdayFromISODate(value.createdAt) === "Thursday").length,
                        'beacon taps': beaconTaps.filter(value => getWeekdayFromISODate(value.createdAt) === "Thursday").length,
                        'geofence notifications': geofenceNotifs.filter(value => getWeekdayFromISODate(value.createdAt) === "Thursday").length,
                        'geofence taps': geofenceTaps.filter(value => getWeekdayFromISODate(value.createdAt) === "Thursday").length,
                        'nfc taps': nfcTaps.filter(value => getWeekdayFromISODate(value.createdAt) === "Thursday").length,
                        'qr scans': qrScans.filter(value => getWeekdayFromISODate(value.createdAt) === "Thursday").length
                    },
                    {
                        name: 'Fri',
                        'beacon notifications': beaconNotifs.filter(value => getWeekdayFromISODate(value.createdAt) === "Friday").length,
                        'beacon taps': beaconTaps.filter(value => getWeekdayFromISODate(value.createdAt) === "Friday").length,
                        'geofence notifications': geofenceNotifs.filter(value => getWeekdayFromISODate(value.createdAt) === "Friday").length,
                        'geofence taps': geofenceTaps.filter(value => getWeekdayFromISODate(value.createdAt) === "Friday").length,
                        'nfc taps': nfcTaps.filter(value => getWeekdayFromISODate(value.createdAt) === "Friday").length,
                        'qr scans': qrScans.filter(value => getWeekdayFromISODate(value.createdAt) === "Friday").length
                    },
                    {
                        name: 'Sat',
                        'beacon notifications': beaconNotifs.filter(value => getWeekdayFromISODate(value.createdAt) === "Saturday").length,
                        'beacon taps': beaconTaps.filter(value => getWeekdayFromISODate(value.createdAt) === "Saturday").length,
                        'geofence notifications': geofenceNotifs.filter(value => getWeekdayFromISODate(value.createdAt) === "Saturday").length,
                        'geofence taps': geofenceTaps.filter(value => getWeekdayFromISODate(value.createdAt) === "Saturday").length,
                        'nfc taps': nfcTaps.filter(value => getWeekdayFromISODate(value.createdAt) === "Saturday").length,
                        'qr scans': qrScans.filter(value => getWeekdayFromISODate(value.createdAt) === "Saturday").length
                    },
                ])
                
            })();
        }
    }, [startDate, endDate, business.id]);

    return (
        <div className="top_right_analytics">
            <h3 className="item2">All usage information combined</h3>
            {!startDate && !endDate ? 
                <h3>Please select a time interval</h3>
            : chart.length > 0 ?
                <ResponsiveContainer>
                    <BarChart
                        data={chart}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        width={350}
                        height={200}
                    >
                        <CartesianGrid vertical={false} />
                        <XAxis dataKey="name" style={{ fontSize: '8px' }} />
                        <YAxis axisLine={false} style={{ fontSize: '8px'}} />
                        <Tooltip itemStyle={{ fontSize: '8px', textAlign: 'center' }} />
                        <Legend iconSize="6" />
                        <Bar dataKey="beacon taps" stackId="a" fill="#77C545" />
                        <Bar dataKey="beacon notifications" stackId="a" fill="#429321" />
                        <Bar dataKey="geofence taps" stackId="b" fill="#A773E3" />
                        <Bar dataKey="geofence notifications" stackId="b" fill="#6E3FC3" />
                        <Bar dataKey="nfc taps" stackId="c" fill="#4880FF" />
                        <Bar dataKey="qr scans" stackId="d" fill="#E24E59" />
                    </BarChart>
                </ResponsiveContainer> : <h3>Error displaying chart</h3>}
        </div>
    );
}

export default AnalyticsChart;