import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Scheduler from './Scheduler';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import ScheduleOverview from './ScheduleOverview';
import { Card, Text, Button, SelectField, TextField, Flex } from '@aws-amplify/ui-react';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { useEffect } from 'react';
import UsageComponent from './UsageComponent';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    width: 1200,
    height: 500,
    boxShadow: 24,
    p: 5,
    overflow: 'auto'
};
  
const cardStyle = {
    border: '0.5px solid lightgrey',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
    justifyItems: 'center',
    alignItems: 'center'
}

function ScheduleCard({ schedule, schedules, setSchedules, user, handleDelete, handleCopy }) {
    const [shifts, setShifts] = useState([]);

    const [openScheduler, setOpenScheduler] = useState(false);
    const handleOpenScheduler = () => setOpenScheduler(true);
    const handleCloseScheduler = () => setOpenScheduler(false);

    // state variables for displaying beacon update form
    const [scheduleId, setScheduleId] = useState("");
    const [openScheduleForm, setOpenScheduleForm] = useState(false);
    const [scheduleFormData, setScheduleFormData] = useState({name: ''});

    const handleOpenScheduleForm = (id, name) => {
        setOpenScheduleForm(true);
        setScheduleFormData({name: name});
        setScheduleId(id);
    }
    const handleCloseScheduleForm = () => setOpenScheduleForm(false);
    const handleScheduleFormChange = (e) => {
        setScheduleFormData({...scheduleFormData, [e.target.name]: e.target.value});
    }
    const handleScheduleSubmit = async (e) => {
        e.preventDefault();
        const {name} = scheduleFormData;

        const schedule = {
            id: scheduleId,
            name
        }

        const result = await API.graphql(graphqlOperation(mutations.updateSchedule, {input: schedule }));

        let newArr = schedules.filter(x => x.id !== scheduleId);
        setSchedules([...newArr, result.data.updateSchedule])
        handleCloseScheduleForm();
    }

    useEffect(() => {
        (async () => {
            const result = await API.graphql(graphqlOperation(queries.listShifts, {filter: {scheduleShiftsId: {eq: schedule.id}}}));
            console.log(result);
            setShifts(result.data.listShifts.items);
        })();//IIFE
    }, []);

    return (
        <Card style={cardStyle}>
            <h2 style={textStyle}>{schedule.name}</h2>
            <ScheduleOverview schedule={schedule.id} shifts={shifts}/>
            <UsageComponent schedule={schedule} />
            <div className="card_btn_wrapper">
            {user.role !== "Manager" ?
                <>
                    <IconButton aria-label="delete" size="large" onClick={()=>handleOpenScheduleForm(schedule.id, schedule.name)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={()=>handleDelete(schedule.id)}>
                        <DeleteIcon />
                    </IconButton>
                    <IconButton aria-label="copy" onClick={()=>handleCopy(schedule.id)}>
                        <ContentCopyRoundedIcon />
                    </IconButton>
                </> :
                <>
                    <IconButton aria-label="delete" size="large" disabled>
                        <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete" disabled>
                        <DeleteIcon />
                    </IconButton>
                    <IconButton aria-label="copy" disabled>
                        <ContentCopyRoundedIcon />
                    </IconButton>
                </>}
            </div>
            <Button style={{ backgroundColor: '#429321', color: 'white' }} onClick={()=>handleOpenScheduler()}>Show Schedule</Button>
            <Modal sx={{ 
                "& > .MuiBackdrop-root" : {
                        backdropFilter: "blur(2px)",
                        backgroundColor: "#FFFFFF55"
                    }
                }} open={openScheduleForm} onClose={handleCloseScheduleForm}>
                <Box className="add_input_margin padding_3 smaller_frame">
                    <Flex as="form" padding={30} direction="column" onSubmit={handleScheduleSubmit}>
                        <TextField name="name" label="Schedule Name" value={scheduleFormData.name} onChange={handleScheduleFormChange} isRequired={true}/>
                        <Button type="submit" variation="primary" backgroundColor={"#429321"}>Submit</Button>
                    </Flex>
                </Box>
            </Modal>
            <Modal sx={{ 
                "& > .MuiBackdrop-root" : {
                        backdropFilter: "blur(2px)",
                        backgroundColor: "#FFFFFF55"
                    }
                }} open={openScheduler} onClose={handleCloseScheduler}>
                <Box className="add_input_margin" width={1200} padding={5}>
                    <Scheduler schedule={schedule} shifts={shifts} setShifts={setShifts} />
                </Box>
            </Modal>
        </Card>
    );
}

const textStyle = {
    fontSize: '14px',
    display: 'flex',
    padding: 8,
    textAlign: 'center'
}

export default ScheduleCard;