import { API, graphqlOperation, sumerianSceneContainer } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { listShifts } from '../graphql/queries';

const timeStrings = ['12a', '1a', '2a', '3a', '4a', '5a', '6a', '7a', '8a', '9a', '10a', '11a', '12p', '1p', '2p', '3p', '4p', '5p', '6p', '7p', '8p', '9p', '10p', '11p', '12a'];

function ScheduleOverview({schedule, shifts}) {
    const [mon, setMon] = useState([]);
    const [tues, setTues] = useState([]);
    const [wed, setWed] = useState([]);
    const [thur, setThur] = useState([]);
    const [fri, setFri] = useState([]);
    const [sat, setSat] = useState([]);
    const [sun, setSun] = useState([]);

    useEffect(() => {
        setMon(shifts.filter(item => item.weekday === "monday"));
        setTues(shifts.filter(item => item.weekday === "tuesday"));
        setWed(shifts.filter(item => item.weekday === "wednesday"));
        setThur(shifts.filter(item => item.weekday === "thursday"));
        setFri(shifts.filter(item => item.weekday === "friday"));
        setSat(shifts.filter(item => item.weekday === "saturday"));
        setSun(shifts.filter(item => item.weekday === "sunday"));
    }, [shifts]);
    return (
        <div style={{ gridColumn: '2/4', display: 'grid', width: '100%', gridTemplateRows: '10 10 10 10'}}>
            <div style={{  display: 'grid', width: '100%', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr'}}>
                <h2 style={{ fontSize: 10 }}>Mon</h2>
                <h2 style={{ fontSize: 10 }}>Tue</h2>
                <h2 style={{ fontSize: 10 }}>Wed</h2>
                <h2 style={{ fontSize: 10 }}>Thu</h2>
                <h2 style={{ fontSize: 10 }}>Fri</h2>
                <h2 style={{ fontSize: 10 }}>Sat</h2>
                <h2 style={{ fontSize: 10 }}>Sun</h2>
            </div>
            <div style={{  display: 'grid', width: '100%', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr'}}>
                <p style={paraStyle}>{mon[0] ? `${timeStrings[mon[0].startTime]} - ${timeStrings[mon[0].endTime]}` : 'No ads'}</p>
                <p style={paraStyle}>{tues[0] ? `${timeStrings[tues[0].startTime]} - ${timeStrings[tues[0].endTime]}` : 'No ads'}</p>
                <p style={paraStyle}>{wed[0] ? `${timeStrings[wed[0].startTime]} - ${timeStrings[wed[0].endTime]}` : 'No ads'}</p>
                <p style={paraStyle}>{thur[0] ? `${timeStrings[thur[0].startTime]} - ${timeStrings[thur[0].endTime]}` : 'No ads'}</p>
                <p style={paraStyle}>{fri[0] ? `${timeStrings[fri[0].startTime]} - ${timeStrings[fri[0].endTime]}` : 'No ads'}</p>
                <p style={paraStyle}>{sat[0] ? `${timeStrings[sat[0].startTime]} - ${timeStrings[sat[0].endTime]}` : 'No ads'}</p>
                <p style={paraStyle}>{sun[0] ? `${timeStrings[sun[0].startTime]} - ${timeStrings[sun[0].endTime]}` : 'No ads'}</p>
            </div>
            <div style={{  display: 'grid', width: '100%', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr'}}>
                <p style={paraStyle}>{mon[1] ? `${timeStrings[mon[1].startTime]} - ${timeStrings[mon[1].endTime]}` : ''}</p>
                <p style={paraStyle}>{tues[1] ? `${timeStrings[tues[1].startTime]} - ${timeStrings[tues[1].endTime]}` : ''}</p>
                <p style={paraStyle}>{wed[1] ? `${timeStrings[wed[1].startTime]} - ${timeStrings[wed[1].endTime]}` : ''}</p>
                <p style={paraStyle}>{thur[1] ? `${timeStrings[thur[1].startTime]} - ${timeStrings[thur[1].endTime]}` : ''}</p>
                <p style={paraStyle}>{fri[1] ? `${timeStrings[fri[1].startTime]} - ${timeStrings[fri[1].endTime]}` : ''}</p>
                <p style={paraStyle}>{sat[1] ? `${timeStrings[sat[1].startTime]} - ${timeStrings[sat[1].endTime]}` : ''}</p>
                <p style={paraStyle}>{sun[1] ? `${timeStrings[sun[1].startTime]} - ${timeStrings[sun[1].endTime]}` : ''}</p>
            </div>
            <div style={{  display: 'grid', width: '100%', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr'}}>
                <p style={paraStyle}>{mon[2] ? `${timeStrings[mon[2].startTime]} - ${timeStrings[mon[2].endTime]}` : ''}</p>
                <p style={paraStyle}>{tues[2] ? `${timeStrings[tues[2].startTime]} - ${timeStrings[tues[2].endTime]}` : ''}</p>
                <p style={paraStyle}>{wed[2] ? `${timeStrings[wed[2].startTime]} - ${timeStrings[wed[2].endTime]}` : ''}</p>
                <p style={paraStyle}>{thur[2] ? `${timeStrings[thur[2].startTime]} - ${timeStrings[thur[2].endTime]}` : ''}</p>
                <p style={paraStyle}>{fri[2] ? `${timeStrings[fri[2].startTime]} - ${timeStrings[fri[2].endTime]}` : ''}</p>
                <p style={paraStyle}>{sat[2] ? `${timeStrings[sat[2].startTime]} - ${timeStrings[sat[2].endTime]}` : ''}</p>
                <p style={paraStyle}>{sun[2] ? `${timeStrings[sun[2].startTime]} - ${timeStrings[sumerianSceneContainer[2].endTime]}` : ''}</p>
            </div>
        </div>
    );
}

const paraStyle = {
    fontSize: 7
}

export default ScheduleOverview;