import { Button } from '@aws-amplify/ui-react';
import React from 'react';

function FAQCard({ title, image, subtext, buttonText, redirect }) {

    const goToLink = () => {
        console.log(redirect);
    };

    return (
        <div style={{ width: 380, height: 250, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', background: 'white' }}>
            <h3 style={{ textAlign: 'center', fontSize: 12}}>{title}</h3>
            <img src={image} alt={`FAQ card picture for ${title}`} style={{ width: 200, height: 115 }}/>
            <p style={{ fontSize: 9, textAlign: 'center', fontWeight: 600 }}>{subtext}</p>
            <Button onClick={goToLink} style={{ backgroundColor: '#429321', color: 'white', height: '25px' }}>{buttonText}</Button>
        </div>
    );
}

export default FAQCard;