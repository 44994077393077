/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBusiness = /* GraphQL */ `
  mutation CreateBusiness(
    $input: CreateBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    createBusiness(input: $input, condition: $condition) {
      name
      numberBeacons
      numberGeofences
      numberLocations
      numberQRs
      numberNFCs
      note
      subscriptionRate
      users {
        nextToken
        __typename
      }
      locations {
        nextToken
        __typename
      }
      beacons {
        nextToken
        __typename
      }
      status
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBusiness = /* GraphQL */ `
  mutation UpdateBusiness(
    $input: UpdateBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    updateBusiness(input: $input, condition: $condition) {
      name
      numberBeacons
      numberGeofences
      numberLocations
      numberQRs
      numberNFCs
      note
      subscriptionRate
      users {
        nextToken
        __typename
      }
      locations {
        nextToken
        __typename
      }
      beacons {
        nextToken
        __typename
      }
      status
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBusiness = /* GraphQL */ `
  mutation DeleteBusiness(
    $input: DeleteBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    deleteBusiness(input: $input, condition: $condition) {
      name
      numberBeacons
      numberGeofences
      numberLocations
      numberQRs
      numberNFCs
      note
      subscriptionRate
      users {
        nextToken
        __typename
      }
      locations {
        nextToken
        __typename
      }
      beacons {
        nextToken
        __typename
      }
      status
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      firstName
      lastName
      email
      role
      gender
      birthday
      businessId
      username
      id
      createdAt
      updatedAt
      businessUsersId
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      firstName
      lastName
      email
      role
      gender
      birthday
      businessId
      username
      id
      createdAt
      updatedAt
      businessUsersId
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      firstName
      lastName
      email
      role
      gender
      birthday
      businessId
      username
      id
      createdAt
      updatedAt
      businessUsersId
      __typename
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      name
      locIcon {
        url
        id
        createdAt
        updatedAt
        __typename
      }
      participantsToggle
      address
      longitude
      latitude
      description
      hours
      phone
      email
      websiteUrl
      reservations
      participantsImage {
        url
        id
        createdAt
        updatedAt
        __typename
      }
      sections
      businessId
      id
      createdAt
      updatedAt
      businessLocationsId
      locationLocIconId
      locationParticipantsImageId
      __typename
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      name
      locIcon {
        url
        id
        createdAt
        updatedAt
        __typename
      }
      participantsToggle
      address
      longitude
      latitude
      description
      hours
      phone
      email
      websiteUrl
      reservations
      participantsImage {
        url
        id
        createdAt
        updatedAt
        __typename
      }
      sections
      businessId
      id
      createdAt
      updatedAt
      businessLocationsId
      locationLocIconId
      locationParticipantsImageId
      __typename
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      name
      locIcon {
        url
        id
        createdAt
        updatedAt
        __typename
      }
      participantsToggle
      address
      longitude
      latitude
      description
      hours
      phone
      email
      websiteUrl
      reservations
      participantsImage {
        url
        id
        createdAt
        updatedAt
        __typename
      }
      sections
      businessId
      id
      createdAt
      updatedAt
      businessLocationsId
      locationLocIconId
      locationParticipantsImageId
      __typename
    }
  }
`;
export const createBeacon = /* GraphQL */ `
  mutation CreateBeacon(
    $input: CreateBeaconInput!
    $condition: ModelBeaconConditionInput
  ) {
    createBeacon(input: $input, condition: $condition) {
      name
      businessName
      uuid
      toggle
      businessId
      location {
        name
        participantsToggle
        address
        longitude
        latitude
        description
        hours
        phone
        email
        websiteUrl
        reservations
        sections
        businessId
        id
        createdAt
        updatedAt
        businessLocationsId
        locationLocIconId
        locationParticipantsImageId
        __typename
      }
      schedule {
        name
        status
        businessId
        id
        createdAt
        updatedAt
        __typename
      }
      note
      lastSeen
      battery
      id
      createdAt
      updatedAt
      businessBeaconsId
      beaconLocationId
      beaconScheduleId
      __typename
    }
  }
`;
export const updateBeacon = /* GraphQL */ `
  mutation UpdateBeacon(
    $input: UpdateBeaconInput!
    $condition: ModelBeaconConditionInput
  ) {
    updateBeacon(input: $input, condition: $condition) {
      name
      businessName
      uuid
      toggle
      businessId
      location {
        name
        participantsToggle
        address
        longitude
        latitude
        description
        hours
        phone
        email
        websiteUrl
        reservations
        sections
        businessId
        id
        createdAt
        updatedAt
        businessLocationsId
        locationLocIconId
        locationParticipantsImageId
        __typename
      }
      schedule {
        name
        status
        businessId
        id
        createdAt
        updatedAt
        __typename
      }
      note
      lastSeen
      battery
      id
      createdAt
      updatedAt
      businessBeaconsId
      beaconLocationId
      beaconScheduleId
      __typename
    }
  }
`;
export const deleteBeacon = /* GraphQL */ `
  mutation DeleteBeacon(
    $input: DeleteBeaconInput!
    $condition: ModelBeaconConditionInput
  ) {
    deleteBeacon(input: $input, condition: $condition) {
      name
      businessName
      uuid
      toggle
      businessId
      location {
        name
        participantsToggle
        address
        longitude
        latitude
        description
        hours
        phone
        email
        websiteUrl
        reservations
        sections
        businessId
        id
        createdAt
        updatedAt
        businessLocationsId
        locationLocIconId
        locationParticipantsImageId
        __typename
      }
      schedule {
        name
        status
        businessId
        id
        createdAt
        updatedAt
        __typename
      }
      note
      lastSeen
      battery
      id
      createdAt
      updatedAt
      businessBeaconsId
      beaconLocationId
      beaconScheduleId
      __typename
    }
  }
`;
export const createGeofence = /* GraphQL */ `
  mutation CreateGeofence(
    $input: CreateGeofenceInput!
    $condition: ModelGeofenceConditionInput
  ) {
    createGeofence(input: $input, condition: $condition) {
      name
      businessName
      toggle
      lat
      lng
      businessId
      schedule {
        name
        status
        businessId
        id
        createdAt
        updatedAt
        __typename
      }
      lastSeen
      radius
      id
      createdAt
      updatedAt
      geofenceScheduleId
      __typename
    }
  }
`;
export const updateGeofence = /* GraphQL */ `
  mutation UpdateGeofence(
    $input: UpdateGeofenceInput!
    $condition: ModelGeofenceConditionInput
  ) {
    updateGeofence(input: $input, condition: $condition) {
      name
      businessName
      toggle
      lat
      lng
      businessId
      schedule {
        name
        status
        businessId
        id
        createdAt
        updatedAt
        __typename
      }
      lastSeen
      radius
      id
      createdAt
      updatedAt
      geofenceScheduleId
      __typename
    }
  }
`;
export const deleteGeofence = /* GraphQL */ `
  mutation DeleteGeofence(
    $input: DeleteGeofenceInput!
    $condition: ModelGeofenceConditionInput
  ) {
    deleteGeofence(input: $input, condition: $condition) {
      name
      businessName
      toggle
      lat
      lng
      businessId
      schedule {
        name
        status
        businessId
        id
        createdAt
        updatedAt
        __typename
      }
      lastSeen
      radius
      id
      createdAt
      updatedAt
      geofenceScheduleId
      __typename
    }
  }
`;
export const createQr = /* GraphQL */ `
  mutation CreateQr($input: CreateQrInput!, $condition: ModelQrConditionInput) {
    createQr(input: $input, condition: $condition) {
      name
      qrType
      value
      toggle
      businessId
      schedule {
        name
        status
        businessId
        id
        createdAt
        updatedAt
        __typename
      }
      id
      createdAt
      updatedAt
      qrScheduleId
      __typename
    }
  }
`;
export const updateQr = /* GraphQL */ `
  mutation UpdateQr($input: UpdateQrInput!, $condition: ModelQrConditionInput) {
    updateQr(input: $input, condition: $condition) {
      name
      qrType
      value
      toggle
      businessId
      schedule {
        name
        status
        businessId
        id
        createdAt
        updatedAt
        __typename
      }
      id
      createdAt
      updatedAt
      qrScheduleId
      __typename
    }
  }
`;
export const deleteQr = /* GraphQL */ `
  mutation DeleteQr($input: DeleteQrInput!, $condition: ModelQrConditionInput) {
    deleteQr(input: $input, condition: $condition) {
      name
      qrType
      value
      toggle
      businessId
      schedule {
        name
        status
        businessId
        id
        createdAt
        updatedAt
        __typename
      }
      id
      createdAt
      updatedAt
      qrScheduleId
      __typename
    }
  }
`;
export const createNfc = /* GraphQL */ `
  mutation CreateNfc(
    $input: CreateNfcInput!
    $condition: ModelNfcConditionInput
  ) {
    createNfc(input: $input, condition: $condition) {
      name
      uuid
      nfcType
      value
      toggle
      businessId
      schedule {
        name
        status
        businessId
        id
        createdAt
        updatedAt
        __typename
      }
      id
      createdAt
      updatedAt
      nfcScheduleId
      __typename
    }
  }
`;
export const updateNfc = /* GraphQL */ `
  mutation UpdateNfc(
    $input: UpdateNfcInput!
    $condition: ModelNfcConditionInput
  ) {
    updateNfc(input: $input, condition: $condition) {
      name
      uuid
      nfcType
      value
      toggle
      businessId
      schedule {
        name
        status
        businessId
        id
        createdAt
        updatedAt
        __typename
      }
      id
      createdAt
      updatedAt
      nfcScheduleId
      __typename
    }
  }
`;
export const deleteNfc = /* GraphQL */ `
  mutation DeleteNfc(
    $input: DeleteNfcInput!
    $condition: ModelNfcConditionInput
  ) {
    deleteNfc(input: $input, condition: $condition) {
      name
      uuid
      nfcType
      value
      toggle
      businessId
      schedule {
        name
        status
        businessId
        id
        createdAt
        updatedAt
        __typename
      }
      id
      createdAt
      updatedAt
      nfcScheduleId
      __typename
    }
  }
`;
export const createAd = /* GraphQL */ `
  mutation CreateAd($input: CreateAdInput!, $condition: ModelAdConditionInput) {
    createAd(input: $input, condition: $condition) {
      adIcon
      name
      title
      description
      type
      url
      redirectLink
      businessId
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAd = /* GraphQL */ `
  mutation UpdateAd($input: UpdateAdInput!, $condition: ModelAdConditionInput) {
    updateAd(input: $input, condition: $condition) {
      adIcon
      name
      title
      description
      type
      url
      redirectLink
      businessId
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAd = /* GraphQL */ `
  mutation DeleteAd($input: DeleteAdInput!, $condition: ModelAdConditionInput) {
    deleteAd(input: $input, condition: $condition) {
      adIcon
      name
      title
      description
      type
      url
      redirectLink
      businessId
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSchedule = /* GraphQL */ `
  mutation CreateSchedule(
    $input: CreateScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    createSchedule(input: $input, condition: $condition) {
      name
      shifts {
        nextToken
        __typename
      }
      status
      businessId
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSchedule = /* GraphQL */ `
  mutation UpdateSchedule(
    $input: UpdateScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    updateSchedule(input: $input, condition: $condition) {
      name
      shifts {
        nextToken
        __typename
      }
      status
      businessId
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSchedule = /* GraphQL */ `
  mutation DeleteSchedule(
    $input: DeleteScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    deleteSchedule(input: $input, condition: $condition) {
      name
      shifts {
        nextToken
        __typename
      }
      status
      businessId
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createShift = /* GraphQL */ `
  mutation CreateShift(
    $input: CreateShiftInput!
    $condition: ModelShiftConditionInput
  ) {
    createShift(input: $input, condition: $condition) {
      ad {
        adIcon
        name
        title
        description
        type
        url
        redirectLink
        businessId
        id
        createdAt
        updatedAt
        __typename
      }
      startTime
      endTime
      weekday
      businessId
      id
      createdAt
      updatedAt
      scheduleShiftsId
      shiftAdId
      __typename
    }
  }
`;
export const updateShift = /* GraphQL */ `
  mutation UpdateShift(
    $input: UpdateShiftInput!
    $condition: ModelShiftConditionInput
  ) {
    updateShift(input: $input, condition: $condition) {
      ad {
        adIcon
        name
        title
        description
        type
        url
        redirectLink
        businessId
        id
        createdAt
        updatedAt
        __typename
      }
      startTime
      endTime
      weekday
      businessId
      id
      createdAt
      updatedAt
      scheduleShiftsId
      shiftAdId
      __typename
    }
  }
`;
export const deleteShift = /* GraphQL */ `
  mutation DeleteShift(
    $input: DeleteShiftInput!
    $condition: ModelShiftConditionInput
  ) {
    deleteShift(input: $input, condition: $condition) {
      ad {
        adIcon
        name
        title
        description
        type
        url
        redirectLink
        businessId
        id
        createdAt
        updatedAt
        __typename
      }
      startTime
      endTime
      weekday
      businessId
      id
      createdAt
      updatedAt
      scheduleShiftsId
      shiftAdId
      __typename
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      name
      ad {
        adIcon
        name
        title
        description
        type
        url
        redirectLink
        businessId
        id
        createdAt
        updatedAt
        __typename
      }
      date
      startTime
      endTime
      businessId
      id
      createdAt
      updatedAt
      eventAdId
      __typename
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      name
      ad {
        adIcon
        name
        title
        description
        type
        url
        redirectLink
        businessId
        id
        createdAt
        updatedAt
        __typename
      }
      date
      startTime
      endTime
      businessId
      id
      createdAt
      updatedAt
      eventAdId
      __typename
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      name
      ad {
        adIcon
        name
        title
        description
        type
        url
        redirectLink
        businessId
        id
        createdAt
        updatedAt
        __typename
      }
      date
      startTime
      endTime
      businessId
      id
      createdAt
      updatedAt
      eventAdId
      __typename
    }
  }
`;
export const createPhoto = /* GraphQL */ `
  mutation CreatePhoto(
    $input: CreatePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    createPhoto(input: $input, condition: $condition) {
      url
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePhoto = /* GraphQL */ `
  mutation UpdatePhoto(
    $input: UpdatePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    updatePhoto(input: $input, condition: $condition) {
      url
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePhoto = /* GraphQL */ `
  mutation DeletePhoto(
    $input: DeletePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    deletePhoto(input: $input, condition: $condition) {
      url
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAnalytics = /* GraphQL */ `
  mutation CreateAnalytics(
    $input: CreateAnalyticsInput!
    $condition: ModelAnalyticsConditionInput
  ) {
    createAnalytics(input: $input, condition: $condition) {
      action
      user {
        firstName
        lastName
        email
        role
        gender
        birthday
        businessId
        username
        id
        createdAt
        updatedAt
        businessUsersId
        __typename
      }
      device
      deviceName
      businessId
      ad {
        adIcon
        name
        title
        description
        type
        url
        redirectLink
        businessId
        id
        createdAt
        updatedAt
        __typename
      }
      id
      createdAt
      updatedAt
      analyticsUserId
      analyticsAdId
      __typename
    }
  }
`;
export const updateAnalytics = /* GraphQL */ `
  mutation UpdateAnalytics(
    $input: UpdateAnalyticsInput!
    $condition: ModelAnalyticsConditionInput
  ) {
    updateAnalytics(input: $input, condition: $condition) {
      action
      user {
        firstName
        lastName
        email
        role
        gender
        birthday
        businessId
        username
        id
        createdAt
        updatedAt
        businessUsersId
        __typename
      }
      device
      deviceName
      businessId
      ad {
        adIcon
        name
        title
        description
        type
        url
        redirectLink
        businessId
        id
        createdAt
        updatedAt
        __typename
      }
      id
      createdAt
      updatedAt
      analyticsUserId
      analyticsAdId
      __typename
    }
  }
`;
export const deleteAnalytics = /* GraphQL */ `
  mutation DeleteAnalytics(
    $input: DeleteAnalyticsInput!
    $condition: ModelAnalyticsConditionInput
  ) {
    deleteAnalytics(input: $input, condition: $condition) {
      action
      user {
        firstName
        lastName
        email
        role
        gender
        birthday
        businessId
        username
        id
        createdAt
        updatedAt
        businessUsersId
        __typename
      }
      device
      deviceName
      businessId
      ad {
        adIcon
        name
        title
        description
        type
        url
        redirectLink
        businessId
        id
        createdAt
        updatedAt
        __typename
      }
      id
      createdAt
      updatedAt
      analyticsUserId
      analyticsAdId
      __typename
    }
  }
`;
export const createArticles = /* GraphQL */ `
  mutation CreateArticles(
    $input: CreateArticlesInput!
    $condition: ModelArticlesConditionInput
  ) {
    createArticles(input: $input, condition: $condition) {
      author
      title
      description
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateArticles = /* GraphQL */ `
  mutation UpdateArticles(
    $input: UpdateArticlesInput!
    $condition: ModelArticlesConditionInput
  ) {
    updateArticles(input: $input, condition: $condition) {
      author
      title
      description
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteArticles = /* GraphQL */ `
  mutation DeleteArticles(
    $input: DeleteArticlesInput!
    $condition: ModelArticlesConditionInput
  ) {
    deleteArticles(input: $input, condition: $condition) {
      author
      title
      description
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLeaderboard = /* GraphQL */ `
  mutation CreateLeaderboard(
    $input: CreateLeaderboardInput!
    $condition: ModelLeaderboardConditionInput
  ) {
    createLeaderboard(input: $input, condition: $condition) {
      downloadLeader
      lastMonthWinner
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLeaderboard = /* GraphQL */ `
  mutation UpdateLeaderboard(
    $input: UpdateLeaderboardInput!
    $condition: ModelLeaderboardConditionInput
  ) {
    updateLeaderboard(input: $input, condition: $condition) {
      downloadLeader
      lastMonthWinner
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLeaderboard = /* GraphQL */ `
  mutation DeleteLeaderboard(
    $input: DeleteLeaderboardInput!
    $condition: ModelLeaderboardConditionInput
  ) {
    deleteLeaderboard(input: $input, condition: $condition) {
      downloadLeader
      lastMonthWinner
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
