import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { Button, TextField, SelectField, Flex } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import {
  Modal,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import QRList from "./QRList";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { CircularProgress } from "@mui/material";

const QRCodes = ({ adminOverride, business, setBusiness, user }) => {
  /* Dialog Box */
  const [open, setOpen] = useState(false);
  const [qrId, setQrId] = useState(null);

  const handleClickOpen = (id) => {
    setQrId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setQrId(null);
    setOpen(false);
  };
  /* QR Form */
  const [openQRForm, setOpenQRForm] = useState(false);
  const [qrFormData, setQRFormData] = useState({
    name: "",
    qrType: "ad",
    schedule: "",
    value: "",
  });
  const [qrs, setQRs] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [analytics, setAnalytics] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleOpenQRForm = () => setOpenQRForm(true);
  const handleCloseQRForm = () => setOpenQRForm(false);
  const handleQRFormChange = (e) => {
    setQRFormData({ ...qrFormData, [e.target.name]: e.target.value });
  };

  const handleQRSubmit = async (e) => {
    e.preventDefault();
    const { name, qrType, schedule, value } = qrFormData;

    const qr = {
      name,
      qrType,
      value,
      toggle: true,
      businessId: business.id,
      qrScheduleId: schedule.id,
    };
    const result = await API.graphql(
      graphqlOperation(mutations.createQr, { input: qr })
    );
    const updateQrCount = await API.graphql(
      graphqlOperation(mutations.updateBusiness, {
        input: { id: business.id, numberQRs: business.numberQRs + 1 },
      })
    );
    setBusiness(updateQrCount.data.updateBusiness);

    setQRs([...qrs, result.data.createQr]);
    handleCloseQRForm();
  };

  const handleQrDelete = async (id) => {
    let responseDB, responseB;
    // Delete from dB
    responseDB = await API.graphql(
      graphqlOperation(mutations.deleteQr, { input: { id: id } })
    );

    // reduce beacon count by 1
    responseB = await API.graphql(
      graphqlOperation(mutations.updateBusiness, {
        input: { id: business.id, numberQRs: business.numberQRs - 1 },
      })
    );
    setBusiness(responseB.data.updateBusiness);

    // filter out the deleted beacon and rerender the list
    var filtered = qrs.filter(function (value, index, arr) {
      console.log(value.id + " = " + responseDB.data.deleteQr.id);
      return value.id != responseDB.data.deleteQr.id;
    });
    setQRs(filtered);
    console.log("Successfully deleted QR " + id);
  };

  const handleScheduleSelection = (e) => {
    const schedule = schedules.find(
      (element) => element.name === e.target.value
    );
    setQRFormData({ ...qrFormData, schedule: schedule });
  };

  useEffect(() => {
    setLoading(true);
    //useEffect function must return a cleanup function or nothing
    (async () => {
      if (adminOverride) {
        const resultQRs = await API.graphql(graphqlOperation(queries.listQrs));
        setQRs(resultQRs.data.listQrs.items);

        const resultSchedules = await API.graphql(
          graphqlOperation(queries.listSchedules)
        );
        setSchedules(resultSchedules.data.listSchedules.items);

        const resultAnalytics = await API.graphql(
          graphqlOperation(queries.listAnalytics, {
            filter: { device: { eq: "qr" } },
            limit: 500,
          })
        );
        setAnalytics(resultAnalytics.data.listAnalytics.items);

        setLoading(false);
      } else {
        const resultQRs = await API.graphql(
          graphqlOperation(queries.listQrs, {
            filter: { businessId: { eq: business.id } },
          })
        );
        setQRs(resultQRs.data.listQrs.items);

        const resultSchedules = await API.graphql(
          graphqlOperation(queries.listSchedules, {
            filter: { businessId: { eq: business.id } },
          })
        );
        setSchedules(resultSchedules.data.listSchedules.items);

        const resultAnalytics = await API.graphql(
          graphqlOperation(queries.analyticsByBusinessId, {
            businessId: business.id,
            filter: { device: { eq: "qr" } },
            limit: 500,
          })
        );
        setAnalytics(resultAnalytics.data.analyticsByBusinessId.items);

        setLoading(false);
      }
    })(); //IIFE
  }, [!adminOverride ? business.id : adminOverride]);

  return (
    <>
      <div className="title_container">
        <h1>QR Manager Page</h1>
        {!adminOverride ? (
          <Button className="add_button" onClick={handleOpenQRForm}>
            + New QR
          </Button>
        ) : (
          <Button className="add_button" onClick={handleOpenQRForm} disabled>
            + New QR
          </Button>
        )}
      </div>
      {loading ? (
        <CircularProgress />
      ) : (
        <QRList
          qrs={qrs}
          setQrs={setQRs}
          user={user}
          schedules={schedules}
          deleteQr={handleClickOpen}
          analytics={analytics}
        />
      )}
      <Modal
        sx={{
          "& > .MuiBackdrop-root": {
            backdropFilter: "blur(2px)",
            backgroundColor: "#FFFFFF55",
          },
        }}
        open={openQRForm}
        onClose={handleCloseQRForm}
      >
        <Box className="add_input_margin padding_3 smaller_frame">
          <Flex
            as="form"
            padding={30}
            direction="column"
            onSubmit={handleQRSubmit}
          >
            <TextField
              name="name"
              label="QR Name"
              placeholder="QR name"
              onChange={handleQRFormChange}
              isRequired={true}
            />
            <SelectField
              name="qrType"
              label="QR Type"
              value={qrFormData.qrType}
              onChange={handleQRFormChange}
              isRequired={true}
            >
              <option value="webpage">Webpage</option>
              <option value="ad">Advertisement</option>
            </SelectField>
            {qrFormData.qrType === "webpage" ? (
              <TextField
                name="value"
                label="Webpage URL"
                placeholder="https://example.com"
                onChange={handleQRFormChange}
                isRequired={true}
              />
            ) : (
              <SelectField
                label="Ad Schedule"
                onChange={handleScheduleSelection}
              >
                <option>Please select an ad schedule</option>
                {schedules.map((schedule) => (
                  <option key={schedule.id}>{schedule.name}</option>
                ))}
              </SelectField>
            )}
            <Button
              type="submit"
              variation="primary"
              backgroundColor={"#429321"}
            >
              Submit
            </Button>
          </Flex>
        </Box>
      </Modal>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Delete ${qrId}?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this QR Code? Doing so will remove
            this QR Code and all associated data
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => handleQrDelete(qrId)} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default QRCodes;
