import React, { useState, useEffect } from "react";
import Amplify, { Auth, API, graphqlOperation, Storage } from "aws-amplify";
import {
  Button,
  TextField,
  SelectField,
  Grid,
  Flex,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import {
  Modal,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  IconButton,
} from "@mui/material";
import UserList from "./UserList";
import LocationList from "./LocationList";
import EditIcon from "@mui/icons-material/Edit";
import * as queries from "./../graphql/queries";
import * as mutations from "./../graphql/mutations";
import Geocode from "react-geocode";

Geocode.setApiKey("AIzaSyApsniWSxldveBShG_HE6-yelwRLYX7NN4");

const BusinessInfo = ({ user, business, setBusiness }) => {
  const [errMessage, setErrMessage] = useState("");
  /* Dialog Boxes */
  const [openLoc, setOpenLoc] = useState(false);
  const [locId, setLocId] = useState(null);

  const handleClickOpenLoc = (id) => {
    setLocId(id);
    setOpenLoc(true);
  };

  const handleCloseLoc = () => {
    setLocId(null);
    setOpenLoc(false);
  };
  const [openUser, setOpenUser] = useState(false);
  const [userId, setUserId] = useState(null);

  const handleClickOpenUser = (id) => {
    setUserId(id);
    setOpenUser(true);
  };

  const handleCloseUser = () => {
    setUserId(null);
    setOpenUser(false);
  };

  /* User Form */
  const [openUserForm, setOpenUserForm] = useState(false);
  const [userFormData, setUserFormData] = useState({ email: "", role: "" });
  const [users, setUsers] = useState([]);
  const [found, setFound] = useState(false);

  const handleOpenUserForm = () => setOpenUserForm(true);
  const handleCloseUserForm = () => setOpenUserForm(false);
  const handleUserFormChange = (e) => {
    if (e.target.value.includes("@")) {
      (async () => {
        const foundEmail = await API.graphql(
          graphqlOperation(queries.userByEmail, { email: e.target.value })
        );
        if (foundEmail.data.userByEmail.items.length > 0) {
          console.log("test");
          setFound(true);
        } else {
          setFound(false);
          console.log("no");
        }
      })();
    }
    setUserFormData({ ...userFormData, [e.target.name]: e.target.value });
  };

  // handles user getting added to business (Admin only)
  const handleUserSubmit = async (e) => {
    e.preventDefault();
    const { email, role } = userFormData;

    try {
      // get the user from the dB using the email
      let dBUser = await API.graphql(
        graphqlOperation(queries.userByEmail, { email: email })
      );
      console.log(dBUser.data.userByEmail);

      // if user is part of the organization already, notify admin
      if (dBUser.data.userByEmail.items.length === 0) {
        throw "User does not exist";
      }
      if (dBUser.data.userByEmail.items[0].businessId === business.id) {
        throw "User already added to your organization";
      }
      // if user is not part of the organization, see if they are apart of another org
      if (
        dBUser.data.userByEmail.items[0].businessId !== "" &&
        dBUser.data.userByEmail.items[0].businessId !== null
      ) {
        console.log(dBUser.data.userByEmail.items[0].businessId);
        throw "User is already apart of another organization";
      }

      // update the dB entry of the user with the business the user was added to
      const updateUser = await API.graphql(
        graphqlOperation(mutations.updateUser, {
          input: {
            id: dBUser.data.userByEmail.items[0].id,
            role: role,
            businessId: business.id,
          },
        })
      );

      setUsers([...users, updateUser.data.updateUser]);
      handleCloseUserForm();
    } catch (e) {
      setErrMessage(e.message);
    }
  };

  // handles user getting removed from business (Admin only)
  const handleUserDelete = async (id) => {
    // remove user from business in dB and update role back to Mobile
    let responseDB = await API.graphql(
      graphqlOperation(mutations.updateUser, {
        input: { id: id, role: "Mobile", businessId: "" },
      })
    );
    console.log(responseDB);

    // filter out the deleted beacon and rerender the list
    var filtered = users.filter(function (value, index, arr) {
      return value.id != id;
    });
    setUsers(filtered);
    console.log("Successfully deleted User " + id);
  };
  /**********************/

  /* Location Form */
  const [openLocationForm, setOpenLocationForm] = useState(false);
  const [locationFormData, setLocationFormData] = useState({
    name: "",
    iconUrl: "",
    address: "",
    description: "",
    phone: "",
    email: "",
    hours: "",
    websiteUrl: "",
  });
  const [locations, setLocations] = useState(null);

  const handleOpenLocationForm = () => setOpenLocationForm(true);
  const handleCloseLocationForm = () => setOpenLocationForm(false);
  const handleLocationFormChange = (e) => {
    setLocationFormData({
      ...locationFormData,
      [e.target.name]: e.target.value,
    });
  };
  const handleLocationSubmit = async (e) => {
    e.preventDefault();
    // get all the form data for the location
    const {
      name,
      streetName,
      city,
      stateCode,
      zipcode,
      description,
      phone,
      email,
      reservations,
      websiteUrl,
      hours,
    } = locationFormData;
    const file = document.getElementById("icon-button-file").files[0];
    const imgFile = document.getElementById("image-button-file").files[0];

    const uploadNameFile = file.name.split(" ").join("_");
    const uploadNameImgFile = imgFile.name.split(" ").join("_");

    try {
      // convert the address to lat and long
      const response = await Geocode.fromAddress(
        streetName + ", " + city + ", " + stateCode + ", " + zipcode
      );

      // create the photos in the dB & S3
      const iconPhoto = await API.graphql(
        graphqlOperation(mutations.createPhoto, {
          input: { url: uploadNameFile },
        })
      );
      await Storage.put(iconPhoto.data.createPhoto.id, file);

      const participantsPhoto = await API.graphql(
        graphqlOperation(mutations.createPhoto, {
          input: { url: uploadNameImgFile },
        })
      );
      await Storage.put(participantsPhoto.data.createPhoto.id, imgFile);

      // create the location in the dB
      const location = {
        name,
        participantsToggle: true,
        locationLocIconId: iconPhoto.data.createPhoto.id,
        locationParticipantsImageId: participantsPhoto.data.createPhoto.id,
        address:
          streetName.trim() +
          ", " +
          city +
          ", " +
          stateCode +
          ", " +
          zipcode.trim(),
        latitude: response.results[0].geometry.location["lat"],
        longitude: response.results[0].geometry.location["lng"],
        description,
        hours,
        phone,
        email,
        websiteUrl,
        reservations,
        sections: "generic",
        businessId: business.id,
      };
      const result = await API.graphql(
        graphqlOperation(mutations.createLocation, { input: location })
      );

      // update business locations by 1
      const updateLocCount = await API.graphql(
        graphqlOperation(mutations.updateBusiness, {
          input: {
            id: business.id,
            numberLocations: business.numberLocations + 1,
          },
        })
      );
      setBusiness(updateLocCount.data.updateBusiness);

      setLocations([...locations, result.data.createLocation]);
      handleCloseLocationForm();
    } catch (e) {
      alert(e);
    }
  };

  const handleLocDelete = async (id) => {
    try {
      // first, delete the images from the S3 and the dB
      const locationRes = await API.graphql(
        graphqlOperation(queries.getLocation, { id: id })
      );
      console.log(locationRes.data.getLocation);

      await API.graphql(
        graphqlOperation(mutations.deletePhoto, {
          input: { id: locationRes.data.getLocation.locationLocIconId },
        })
      );
      await Storage.remove(locationRes.data.getLocation.locationLocIconId);

      await API.graphql(
        graphqlOperation(mutations.deletePhoto, {
          input: {
            id: locationRes.data.getLocation.locationParticipantsImageId,
          },
        })
      );
      await Storage.remove(
        locationRes.data.getLocation.locationParticipantsImageId
      );

      // Delete from dB
      await API.graphql(
        graphqlOperation(mutations.deleteLocation, { input: { id: id } })
      );

      // delete beacons from business location
      const findBeaconsForLoc = await API.graphql(
        graphqlOperation(queries.listBeacons, {
          filter: { beaconLocationId: id },
        })
      );
      for (
        let i = 0;
        i < findBeaconsForLoc.data.listBeacons.items.length;
        i++
      ) {
        const deleteBeacon = await API.graphql(
          graphqlOperation(mutations.deleteBeacon, {
            input: { id: findBeaconsForLoc.data.listBeacons.items[i].id },
          })
        );
        console.log(deleteBeacon);
      }

      // reduce location count by 1 and beacon count by number of beacons at location
      const responseB = await API.graphql(
        graphqlOperation(mutations.updateBusiness, {
          input: {
            id: business.id,
            numberLocations: business.numberLocations - 1,
            numberBeacons:
              business.numberBeacons -
              findBeaconsForLoc.data.listBeacons.items.length,
          },
        })
      );
      setBusiness(responseB.data.updateBusiness);

      // filter out the deleted location and rerender the list
      var filtered = locations.filter(function (value, index, arr) {
        console.log(value.id + " = " + id);
        return value.id != id;
      });
      setLocations(filtered);
      console.log("Successfully deleted Location " + id);
    } catch (e) {
      alert(JSON.stringify(e));
    }
  };
  /*************************************/

  const [openBusinessForm, setOpenBusinessForm] = useState(false);
  const [businessFormData, setBusinessFormData] = useState({
    name: business.name,
    subscriptionRate: business.subscriptionRate,
    note: business.note,
  });
  const handleOpenBusinessForm = () => setOpenBusinessForm(true);
  const handleCloseBusinessForm = () => setOpenBusinessForm(false);
  const handleBusinessFormChange = (e) => {
    setBusinessFormData({
      ...businessFormData,
      [e.target.name]: e.target.value,
    });
  };
  const handleBusinessSubmit = async (e) => {
    e.preventDefault();
    const { name, note, subscriptionRate } = businessFormData;
    const result = await API.graphql(
      graphqlOperation(mutations.updateBusiness, {
        input: {
          id: business.id,
          name: name,
          note: note,
          subscriptionRate: subscriptionRate,
        },
      })
    );

    setBusiness(result.data.updateBusiness);
    handleCloseBusinessForm();
  };

  useEffect(() => {
    //useEffect function must return a cleanup function or nothing
    (async () => {
      const result = await API.graphql(
        graphqlOperation(queries.listUsers, {
          filter: { businessId: { eq: business.id } },
        })
      );
      console.log(result);
      setUsers(result.data.listUsers.items);
    })(); //IIFE

    (async () => {
      const result = await API.graphql(
        graphqlOperation(queries.listLocations, {
          filter: { businessId: { eq: business.id } },
        })
      );
      setLocations(result.data.listLocations.items);
    })(); //IIFE
  }, [business.id]);

  return (
    <>
      <h2
        style={{
          position: "relative",
          left: "2.5%",
          color: "white",
          marginBlock: 15,
        }}
      >
        Business Info & Location Manager
      </h2>
      <div className="business_info_w_users">
        <div className="business_info_container">
          {/* Business data */}
          <h3
            style={{ position: "relative", color: "white", marginLeft: "5%" }}
          >
            Main Business Information
          </h3>
          <div className="business_content">
            <div className="business_header">
              <p>Business Name:</p>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <p>{business.name}</p>
                {user.role !== "Manager" ? (
                  <>
                    <IconButton
                      aria-label="edit"
                      size="small"
                      onClick={handleOpenBusinessForm}
                    >
                      <EditIcon fontSize="inherit" />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <IconButton aria-label="edit" size="small" disabled>
                      <EditIcon fontSize="inherit" />
                    </IconButton>
                  </>
                )}
              </div>
            </div>
            <div className="business_body_left">
              <Grid templateColumns="1fr 1fr 1fr 1fr">
                <p>Number of Beacons</p>
                <p className="business_int">{business.numberBeacons}</p>
                <p>Number of Locations</p>
                <p className="business_int">{business.numberLocations}</p>
                <p>Number of Geofences</p>
                <p className="business_int">{business.numberGeofences}</p>
                <p>Subscription Rate</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ position: "relative", left: "45%" }}>
                    {business.subscriptionRate}
                  </p>
                  {user.role === "Admin" ? (
                    <>
                      <IconButton
                        aria-label="edit"
                        size="small"
                        onClick={handleOpenBusinessForm}
                      >
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <IconButton aria-label="edit" size="small" disabled>
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                    </>
                  )}
                </div>
                <p>Number of NFC Tags</p>
                <p className="business_int">{business.numberNFCs}</p>
                <p>Notes</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <p>{business.note}</p>
                  {user.role !== "Manager" ? (
                    <>
                      <IconButton
                        aria-label="edit"
                        size="small"
                        onClick={handleOpenBusinessForm}
                      >
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <IconButton aria-label="edit" size="small" disabled>
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                    </>
                  )}
                </div>
                <p>Number of QR Codes</p>
                <p className="business_int">{business.numberQRs}</p>
              </Grid>
              <p className="business_created_date">
                Date created: {business.createdAt.substring(1, 10)}
              </p>
            </div>
          </div>
        </div>
        <Modal
          sx={{
            "& > .MuiBackdrop-root": {
              backdropFilter: "blur(2px)",
              backgroundColor: "#FFFFFF55",
            },
          }}
          open={openBusinessForm}
          onClose={handleCloseBusinessForm}
        >
          <Box className="add_input_margin smaller_frame">
            <Flex
              as="form"
              padding={30}
              direction="column"
              onSubmit={handleBusinessSubmit}
            >
              <TextField
                name="name"
                label="Business Name"
                value={businessFormData.name}
                onChange={handleBusinessFormChange}
                isRequired={true}
              />
              <TextField
                name="note"
                label="Note"
                value={businessFormData.note}
                onChange={handleBusinessFormChange}
                isRequired={true}
              />
              <SelectField
                name="subscriptionRate"
                label="Subscription Rate"
                value={businessFormData.subscriptionRate}
                onChange={handleBusinessFormChange}
              >
                <option value="84">Business Standard - $84/month</option>
              </SelectField>
              <Button
                type="submit"
                variation="primary"
                backgroundColor={"#429321"}
              >
                Submit
              </Button>
            </Flex>
          </Box>
        </Modal>

        {/* User data */}
        <div className="user_info_container">
          <div
            style={{
              width: "85%",
              alignItems: "center",
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <h3 style={subheading}>Users</h3>
            {user.role !== "Manager" ? (
              <Button
                className="add_button"
                onClick={handleOpenUserForm}
                padding={0}
              >
                + Add User
              </Button>
            ) : null}
          </div>
          <div className="user_content">
            <UserList
              users={users}
              setUsers={setUsers}
              deleteUser={handleClickOpenUser}
              user={user}
            />
            <p className="user_count">
              {users.length} {users.length === 1 ? "user" : "users"}
            </p>
          </div>
        </div>
      </div>
      <Modal
        sx={{
          "& > .MuiBackdrop-root": {
            backdropFilter: "blur(2px)",
            backgroundColor: "#FFFFFF55",
          },
        }}
        open={openUserForm}
        onClose={handleCloseUserForm}
      >
        <Box className="add_input_margin smaller_frame">
          <Flex
            as="form"
            padding={30}
            direction="column"
            onSubmit={handleUserSubmit}
          >
            {errMessage !== "" ? (
              <h3 style={{ textAlign: "center", color: "red" }}>
                {errMessage}
              </h3>
            ) : null}
            <h2 style={{ margin: 0 }}>Add User</h2>
            <p style={{ fontSize: 12 }}>
              Enter the email of the user that you are looking to add to your
              organization. The user must already have an account on our app.
            </p>
            <div>
              <TextField
                style={{
                  border: found ? "2px solid green" : "1px solid lightgrey",
                  margin: 0,
                }}
                name="email"
                label="Email"
                placeholder="Email"
                onChange={handleUserFormChange}
                isRequired={true}
              />
              {found ? (
                <p style={{ color: "green", fontSize: 8 }}>User exists</p>
              ) : null}
            </div>
            <SelectField
              name="role"
              label="Role"
              placeholder="Please select a user role"
              onChange={handleUserFormChange}
            >
              {user.role === "Admin" ? (
                <option value="Admin">Admin</option>
              ) : null}
              <option value="Editor">Editor</option>
              <option value="Manager">Manager</option>
            </SelectField>
            <Button
              type="submit"
              variation="primary"
              backgroundColor={"#429321"}
            >
              Submit
            </Button>
          </Flex>
        </Box>
      </Modal>

      <div className="locations_container">
        {/* Location data */}
        <div className="title_container">
          <h3 style={subheading}>Locations</h3>
          {user.role === "Admin" ? (
            <Button className="add_button" onClick={handleOpenLocationForm}>
              + Add Location
            </Button>
          ) : null}
        </div>
        <div className="location_content">
          <LocationList
            locations={locations}
            setLocations={setLocations}
            user={user}
            deleteLocation={handleClickOpenLoc}
          />
        </div>
      </div>
      <Modal
        sx={{
          "& > .MuiBackdrop-root": {
            backdropFilter: "blur(2px)",
            backgroundColor: "#FFFFFF55",
          },
        }}
        open={openLocationForm}
        onClose={handleCloseLocationForm}
      >
        <Box className="add_input_margin larger_frame">
          <Flex
            as="form"
            padding={30}
            direction="column"
            onSubmit={handleLocationSubmit}
          >
            <h2 style={{ margin: 0 }}>Add Location</h2>
            <div style={flexAligned}>
              <div style={{ padding: 15, width: "50%" }}>
                <label
                  htmlFor="icon-button-file"
                  style={{ color: "hsl(210deg 25% 25%)", fontSize: "1.05rem" }}
                >
                  Location Icon <br />
                  <input
                    name="icon"
                    accept="image/*"
                    id="icon-button-file"
                    type="file"
                    required
                  />
                </label>
                <div style={flexAligned}>
                  <h3 style={{ width: "50%" }}>Location Name</h3>
                  <TextField
                    style={{ width: "100%" }}
                    name="name"
                    placeholder="DTGVL Office - NW"
                    onChange={handleLocationFormChange}
                    isRequired={true}
                  />
                </div>
                <div style={flexAligned}>
                  <h3 style={{ width: "50%" }}>Address</h3>
                  <TextField
                    name="streetName"
                    placeholder="123 N Main St."
                    onChange={handleLocationFormChange}
                    isRequired={true}
                  />
                </div>
                <div style={flexAligned}>
                  <h3 style={{ width: "50%" }}>City</h3>
                  <SelectField
                    name="city"
                    placeholder="City"
                    onChange={handleLocationFormChange}
                  >
                    <option value="Greenville">Greenville</option>
                  </SelectField>
                </div>
                <div style={flexAligned}>
                  <h3 style={{ width: "50%" }}>State</h3>
                  <SelectField
                    name="stateCode"
                    placeholder="State"
                    onChange={handleLocationFormChange}
                  >
                    <option value="SC">SC</option>
                  </SelectField>
                </div>
                <div style={flexAligned}>
                  <h3 style={{ width: "50%" }}>Zipcode</h3>
                  <TextField
                    name="zipcode"
                    placeholder="29601, 29607, ..."
                    onChange={handleLocationFormChange}
                    isRequired={true}
                  />
                </div>
                <div style={flexAligned}>
                  <h3 style={{ width: "50%" }}>Description</h3>
                  <TextField
                    name="description"
                    placeholder="Brand new office overlooking the water"
                    onChange={handleLocationFormChange}
                    isRequired={true}
                  />
                </div>
              </div>
              <div style={{ paddingInline: 15, width: "50%" }}>
                <div style={flexAligned}>
                  <h3 style={{ width: "50%" }}>Hours</h3>
                  <TextField
                    name="hours"
                    placeholder="Mon-Thu: 8AM - 10PM, Fri-Sat: 8AM-2AM, Sun: Closed"
                    onChange={handleLocationFormChange}
                  />
                </div>
                <div style={flexAligned}>
                  <h3 style={{ width: "50%" }}>Phone Number</h3>
                  <TextField
                    name="phone"
                    placeholder="8641234567"
                    onChange={handleLocationFormChange}
                  />
                </div>
                <div style={flexAligned}>
                  <h3 style={{ width: "50%" }}>Email Address</h3>
                  <TextField
                    name="email"
                    placeholder="info@dtgvl.com"
                    onChange={handleLocationFormChange}
                  />
                </div>
                <div style={flexAligned}>
                  <h3 style={{ width: "50%" }}>Website</h3>
                  <TextField
                    name="websiteUrl"
                    placeholder="www.dtgvl.com"
                    onChange={handleLocationFormChange}
                  />
                </div>
                <div style={flexAligned}>
                  <h3 style={{ width: "50%" }}>Reservations</h3>
                  <TextField
                    name="reservations"
                    placeholder="www.dtgvl.com/reservations"
                    onChange={handleLocationFormChange}
                  />
                </div>
                <div style={flexAligned}>
                  <label
                    htmlFor="image-button-file"
                    style={{
                      color: "hsl(210deg 25% 25%)",
                      fontSize: "1.05rem",
                    }}
                  >
                    Location Image (for Mobile) <br />
                    <input
                      name="icon"
                      accept="image/*"
                      id="image-button-file"
                      type="file"
                      required
                    />
                  </label>
                </div>
              </div>
            </div>
            <Button
              type="submit"
              variation="primary"
              backgroundColor={"#429321"}
            >
              Submit
            </Button>
          </Flex>
        </Box>
      </Modal>
      <Dialog
        open={openLoc}
        onClose={handleCloseLoc}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Delete ${locId}?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this location? Doing so will remove
            this location and any associated beacons
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLoc}>Cancel</Button>
          <Button onClick={() => handleLocDelete(locId)} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openUser}
        onClose={handleCloseUser}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Remove ${userId}?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this user? Doing so will remove this
            user and revoke their dashboard permissions
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUser}>Cancel</Button>
          <Button onClick={() => handleUserDelete(userId)} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const subheading = { position: "relative", color: "white" };
const flexAligned = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  color: "#757575",
  fontSize: 13,
  alignItems: "flex-start",
};

export default BusinessInfo;
