import React, { useState, useEffect } from "react";
import Amplify, { Auth, API, graphqlOperation } from "aws-amplify";
import { Button, TextField, SelectField, Flex } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import BeaconList from "./BeaconList";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const Beacons = ({ user, adminOverride, business, setBusiness }) => {
  const [beaconAddType, setBeaconAddType] = useState("default");
  /* Dialog Box */
  const [open, setOpen] = useState(false);
  const [beaconId, setBeaconId] = useState(null);

  const handleClickOpen = (id) => {
    setBeaconId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setBeaconId(null);
    setOpen(false);
  };
  /* Beacon Form */
  const [openBeaconForm, setOpenBeaconForm] = useState(false);
  const [beaconFormData, setBeaconFormData] = useState({
    name: "",
    note: "",
    location: "",
    schedule: "",
    uuid: "",
  });
  const [beacons, setBeacons] = useState([]);
  const [unassignedBeacons, setUnassignedBeacons] = useState([]);
  const [beaconAssignment, setBeaconAssignment] = useState(null);
  const [locations, setLocations] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleOpenBeaconForm = async () => {
    setOpenBeaconForm(true);
    const beaconsToAssign = await API.graphql(
      graphqlOperation(queries.listBeacons, {
        filter: {
          businessId: { eq: "84e79b51-a9d8-4466-92a1-6bd3b9238f9b" },
          beaconLocationId: { eq: "" },
        },
      })
    );
    console.log(beaconsToAssign.data.listBeacons.items);
    setUnassignedBeacons(beaconsToAssign.data.listBeacons.items);
  };
  const handleCloseBeaconForm = () => setOpenBeaconForm(false);
  const handleBeaconFormChange = (e) => {
    setBeaconFormData({ ...beaconFormData, [e.target.name]: e.target.value });
  };

  const handleBeaconSubmit = async (e) => {
    e.preventDefault();
    const { name, uuid, note, location, schedule } = beaconFormData;

    if (location === "" && schedule !== "") {
      alert(
        "You must assign the beacon to a location in order to set an ad schedule"
      );
      return;
    }
    const value = {
      name,
      businessName: business.name,
      uuid,
      note,
      lastSeen: "N/A",
      toggle: location !== "" && schedule !== "" ? true : false,
      beaconLocationId: location !== "" ? location.id : "",
      businessId: business.id,
      beaconScheduleId: schedule !== "" ? schedule.id : "",
    };
    const result = await API.graphql(
      graphqlOperation(mutations.createBeacon, { input: value })
    );
    const updateBeaconCount = await API.graphql(
      graphqlOperation(mutations.updateBusiness, {
        input: { id: business.id, numberBeacons: business.numberBeacons + 1 },
      })
    );
    setBusiness(updateBeaconCount.data.updateBusiness);

    setBeacons([...beacons, result.data.createBeacon]);
    handleCloseBeaconForm();
  };

  const handleBeaconDelete = async (id) => {
    let responseDB, responseB;
    // Delete from dB
    responseDB = await API.graphql(
      graphqlOperation(mutations.deleteBeacon, { input: { id: id } })
    );
    console.log(responseDB);

    // reduce beacon count by 1
    responseB = await API.graphql(
      graphqlOperation(mutations.updateBusiness, {
        input: { id: business.id, numberBeacons: business.numberBeacons - 1 },
      })
    );
    setBusiness(responseB.data.updateBusiness);

    // filter out the deleted beacon and rerender the list
    var filtered = beacons.filter(function (value, index, arr) {
      console.log(value.id + " = " + responseDB.data.deleteBeacon.id);
      return value.id != responseDB.data.deleteBeacon.id;
    });
    setBeacons(filtered);
    console.log("Successfully deleted Beacon " + id);
  };

  const handleLocSelection = (e) => {
    const location = locations.find(
      (element) => element.name === e.target.value
    );
    if (location !== undefined) {
      setBeaconFormData({ ...beaconFormData, location: location });
    } else {
      setBeaconFormData({ ...beaconFormData, location: "" });
    }
  };

  const handleScheduleSelection = (e) => {
    const schedule = schedules.find(
      (element) => element.name === e.target.value
    );
    if (schedule !== undefined) {
      setBeaconFormData({ ...beaconFormData, schedule: schedule });
    } else {
      setBeaconFormData({ ...beaconFormData, schedule: "" });
    }
  };

  const handleBeaconSelection = (e) => {
    const beacon = unassignedBeacons.find(
      (element) => element.name === e.target.value
    );
    setBeaconAssignment(beacon);
  };

  const handleDefaultSelect = () => {
    setBeaconAddType("default");
  };

  const handleBeaconAssignment = async (e) => {
    e.preventDefault();
    console.log(beaconAssignment);

    // Take beaconAssignment and update beacon to new business
    const input = {
      id: beaconAssignment.id,
      businessId: business.id,
      businessName: business.name,
    };
    const updated = await API.graphql(
      graphqlOperation(mutations.updateBeacon, { input: input })
    );
    setBeacons([...beacons, updated.data.updateBeacon]);

    const updateBeaconCount = await API.graphql(
      graphqlOperation(mutations.updateBusiness, {
        input: { id: business.id, numberBeacons: business.numberBeacons + 1 },
      })
    );
    setBusiness(updateBeaconCount.data.updateBusiness);

    const dtgvlBusiness = await API.graphql(
      graphqlOperation(queries.getBusiness, {
        id: "84e79b51-a9d8-4466-92a1-6bd3b9238f9b",
      })
    );
    await API.graphql(
      graphqlOperation(mutations.updateBusiness, {
        input: {
          id: "84e79b51-a9d8-4466-92a1-6bd3b9238f9b",
          numberBeacons: dtgvlBusiness.data.getBusiness.numberBeacons - 1,
        },
      })
    );
    handleCloseBeaconForm();
  };

  useEffect(() => {
    setLoading(true);
    //useEffect function must return a cleanup function or nothing
    (async () => {
      if (adminOverride) {
        const resultBeacons = await API.graphql(
          graphqlOperation(queries.listBeacons)
        );
        setBeacons(resultBeacons.data.listBeacons.items);

        const resultLocs = await API.graphql(
          graphqlOperation(queries.listLocations)
        );
        setLocations(resultLocs.data.listLocations.items);

        const resultSchedules = await API.graphql(
          graphqlOperation(queries.listSchedules)
        );
        setSchedules(resultSchedules.data.listSchedules.items);
        setLoading(false);
      } else {
        const resultBeacons = await API.graphql(
          graphqlOperation(queries.listBeacons, {
            filter: { businessId: { eq: business.id } },
          })
        );
        setBeacons(resultBeacons.data.listBeacons.items);

        const resultLocs = await API.graphql(
          graphqlOperation(queries.listLocations, {
            filter: { businessId: { eq: business.id } },
          })
        );
        setLocations(resultLocs.data.listLocations.items);

        const resultSchedules = await API.graphql(
          graphqlOperation(queries.listSchedules, {
            filter: { businessId: { eq: business.id } },
          })
        );
        setSchedules(resultSchedules.data.listSchedules.items);
        setLoading(false);
      }
    })(); //IIFE

    (async () => {
      if (adminOverride) {
      } else {
      }
    })(); //IIFE

    (async () => {
      if (adminOverride) {
      } else {
      }
    })(); //IIFE
  }, [!adminOverride ? business.id : adminOverride]);

  return (
    <>
      <div className="title_container">
        <h1>Beacon Manager Page</h1>
        {user.role === "Admin" ? (
          !adminOverride ? (
            <Button className="add_button" onClick={handleOpenBeaconForm}>
              + New Beacon
            </Button>
          ) : (
            <Button
              className="add_button"
              onClick={handleOpenBeaconForm}
              disabled
            >
              + New Beacon
            </Button>
          )
        ) : null}
      </div>
      {loading ? (
        <CircularProgress />
      ) : (
        <BeaconList
          adminOverride={adminOverride}
          beacons={beacons}
          setBeacons={setBeacons}
          user={user}
          deleteBeacon={handleClickOpen}
          locations={locations}
          schedules={schedules}
          setBusiness={setBusiness}
        />
      )}
      <Modal
        sx={{
          "& > .MuiBackdrop-root": {
            backdropFilter: "blur(2px)",
            backgroundColor: "#FFFFFF55",
          },
        }}
        open={openBeaconForm}
        onClose={handleCloseBeaconForm}
      >
        <Box className="add_input_margin padding_3 smaller_frame">
          {!adminOverride && business.name === "DTGVL" ? (
            <Flex
              as="form"
              padding={30}
              direction="column"
              onSubmit={handleBeaconSubmit}
            >
              <TextField
                name="name"
                label="Beacon Name"
                placeholder="Beacon name"
                onChange={handleBeaconFormChange}
                isRequired={true}
              />
              <TextField
                name="uuid"
                label="Serial Number"
                placeholder="Serial Number"
                onChange={handleBeaconFormChange}
                isRequired={true}
              />
              <TextField
                name="note"
                label="Note"
                placeholder="Note"
                onChange={handleBeaconFormChange}
                isRequired={false}
              />
              <SelectField
                onChange={handleLocSelection}
                label="Beacon Location"
              >
                <option value="">Please select an install location</option>
                {locations.map((location) => (
                  <option key={location.id}>{location.name}</option>
                ))}
              </SelectField>
              <SelectField
                onChange={handleScheduleSelection}
                label="Beacon Ad Schedule"
              >
                <option value="">Please select an ad schedule</option>
                {schedules.map((schedule) => (
                  <option key={schedule.id}>{schedule.name}</option>
                ))}
              </SelectField>
              <Button
                type="submit"
                variation="primary"
                backgroundColor={"#429321"}
              >
                Submit
              </Button>
            </Flex>
          ) : (
            <>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  height: 35,
                  marginBottom: 35,
                }}
              >
                <Button
                  style={{
                    borderRadius: 0,
                    width: "50%",
                    border: "none",
                    borderBottom:
                      beaconAddType === "default" ? "1px solid #429321" : null,
                  }}
                  onClick={handleDefaultSelect}
                >
                  Assign Beacon
                </Button>
                <Button
                  style={{
                    borderRadius: 0,
                    width: "50%",
                    border: "none",
                    borderBottom:
                      beaconAddType === "new" ? "1px solid #429321" : null,
                  }}
                  onClick={() => setBeaconAddType("new")}
                >
                  New Beacon
                </Button>
              </div>
              {beaconAddType === "new" ? (
                <Flex
                  as="form"
                  padding={30}
                  direction="column"
                  onSubmit={handleBeaconSubmit}
                >
                  <TextField
                    name="name"
                    label="Beacon Name"
                    placeholder="Beacon name"
                    onChange={handleBeaconFormChange}
                    isRequired={true}
                  />
                  <TextField
                    name="uuid"
                    label="Serial Number"
                    placeholder="Serial Number"
                    onChange={handleBeaconFormChange}
                    isRequired={true}
                  />
                  <TextField
                    name="note"
                    label="Note"
                    placeholder="Note"
                    onChange={handleBeaconFormChange}
                    isRequired={false}
                  />
                  <SelectField
                    onChange={handleLocSelection}
                    label="Beacon Location"
                  >
                    <option value="">Please select an install location</option>
                    {locations.map((location) => (
                      <option key={location.id}>{location.name}</option>
                    ))}
                  </SelectField>
                  <SelectField
                    onChange={handleScheduleSelection}
                    label="Beacon Ad Schedule"
                  >
                    <option value="">Please select an ad schedule</option>
                    {schedules.map((schedule) => (
                      <option key={schedule.id}>{schedule.name}</option>
                    ))}
                  </SelectField>
                  <Button
                    type="submit"
                    variation="primary"
                    backgroundColor={"#429321"}
                  >
                    Submit
                  </Button>
                </Flex>
              ) : (
                <Flex
                  as="form"
                  padding={30}
                  direction="column"
                  onSubmit={handleBeaconAssignment}
                >
                  <SelectField
                    onChange={handleBeaconSelection}
                    label="Beacons"
                    isRequired={true}
                  >
                    <option value="">Please select a beacon to assign</option>
                    {unassignedBeacons.map((beacon) => (
                      <option key={beacon.id}>{beacon.name}</option>
                    ))}
                  </SelectField>
                  <Button
                    type="submit"
                    variation="primary"
                    backgroundColor={"#429321"}
                  >
                    Submit
                  </Button>
                </Flex>
              )}
            </>
          )}
        </Box>
      </Modal>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Delete ${beaconId}?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this beacon? Doing so will remove
            this device and any associated shifts and analytics
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => handleBeaconDelete(beaconId)} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Beacons;
