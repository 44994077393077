/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://2czwf06qp4.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://r4bn5fxd55gxflhrb2teqo7wmm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-pv2w34pp4zcdpnez4hvfodg5uq",
    "aws_cognito_identity_pool_id": "us-east-1:a5f87294-05bf-486c-9c14-04fc254c217b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_QDZv5PrZS",
    "aws_user_pools_web_client_id": "5isd8q6ba4vk77u79ictfv1lp4",
    "oauth": {
        "domain": "dtgvlv2e79c0184-e79c0184-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "dtgvl://,http://localhost:3000/,https://ds9jl6k575cqw.cloudfront.net/",
        "redirectSignOut": "dtgvl://,http://localhost:3000/,https://ds9jl6k575cqw.cloudfront.net/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "dtgvlv2f04838c8631b4596b8b3acde06c1239b145859-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "geo": {
        "amazon_location_service": {
            "region": "us-east-1",
            "geofenceCollections": {
                "items": [
                    "geofenceCollection61cd1351-dev"
                ],
                "default": "geofenceCollection61cd1351-dev"
            }
        }
    }
};


export default awsmobile;
