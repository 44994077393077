import React, { useState, useEffect } from 'react';
import { Card, Text, Button, Image, TextField, SelectField, Flex } from '@aws-amplify/ui-react'
import { API, graphqlOperation } from 'aws-amplify';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { Switch } from '@mui/material';
import { SwapHoriz } from '@mui/icons-material';

const baseUrl = "https://dtgvlv2f04838c8631b4596b8b3acde06c1239b145859-dev.s3.amazonaws.com/public/"

const BeaconCard = ({ user, beacon, beacons, setBeacons, deleteBeacon, locations, schedules, adminOverride, setBusiness }) => {
  console.log({locations, schedules} );
  const [switchVal, setSwitchVal] = useState(beacon.toggle);

  const [openContent, setOpenContent] = useState(false);
  const handleOpenContent = () => setOpenContent(true);
  const handleCloseContent = () => setOpenContent(false);

  // state variables for displaying beacon update form
  const [beaconId, setBeaconId] = useState("");
  const [openBeaconForm, setOpenBeaconForm] = useState(false);
  const [beaconFormData, setBeaconFormData] = useState({name: '', uuid: '', note: '', location: '', schedule: ''});

  const handleOpenBeaconForm = (id, name, note, location, schedule, uuid) => {
    setOpenBeaconForm(true);
    setBeaconFormData({name: name, note: note, location: location, schedule: schedule, uuid: uuid});
    setBeaconId(id);
  }
  const handleCloseBeaconForm = () => setOpenBeaconForm(false);
  const handleBeaconFormChange = (e) => {
    setBeaconFormData({...beaconFormData, [e.target.name]: e.target.value});
  }
  const handleBeaconSubmit = async (e) => {
    e.preventDefault();
    const {name, uuid, note, location, schedule} = beaconFormData;

    const beaconInput = {
      id: beaconId,
      name,
      uuid,
      note,
      beaconLocationId: location !== '' ? location.id : '',
      beaconScheduleId: schedule !== '' ? schedule.id : ''
    }

    const result = await API.graphql(graphqlOperation(mutations.updateBeacon, {input: beaconInput }));

    let newArr = beacons.filter(x => x.id !== beaconId);
    setBeacons([...newArr, result.data.updateBeacon])
    handleCloseBeaconForm();
  }

  const handleSwap = async (id) => {
    // change the beacon information to clear the location and schedule, move the beacon back to DTGVL, and turn the beacon off
    const updateInput = {
      id,
      businessId: '84e79b51-a9d8-4466-92a1-6bd3b9238f9b',
      businessName: 'DTGVL',
      toggle: false,
      beaconLocationId: '',
      beaconScheduleId: '',
      note: 'Unassigned beacon'
    }
    const updatedBeacon = await API.graphql(graphqlOperation(mutations.updateBeacon, { input: updateInput }));

    // reduce the beacon count for the business by 1
    const beaconBusiness = await API.graphql(graphqlOperation(queries.getBusiness, { id: beacon.businessId }));
    const updatedBus = await API.graphql(graphqlOperation(mutations.updateBusiness, { input: { id:  beacon.businessId, numberBeacons: beaconBusiness.data.getBusiness.numberBeacons-1}}))
    setBusiness(updatedBus.data.updateBusiness);

    // increase the DTGVL beacon count by 1
    const dtgvlBusiness = await API.graphql(graphqlOperation(queries.getBusiness, {id: "84e79b51-a9d8-4466-92a1-6bd3b9238f9b" }));
    await API.graphql(graphqlOperation(mutations.updateBusiness, { input: { id: "84e79b51-a9d8-4466-92a1-6bd3b9238f9b", numberBeacons: dtgvlBusiness.data.getBusiness.numberBeacons+1 }}))

    // remove the beacon from the list, or update the data on the card depending on the screen parameters
    if (!adminOverride && beacon.businessName !== 'DTGVL') {
      setBeacons(prev => prev.filter(beacon => beacon.id !== id))
    } else {
      const beaconCopy = [...beacons];
      const changeIndex = beaconCopy.findIndex(val => val.id === id);
      if (changeIndex !== -1) {
        beaconCopy[changeIndex] = updatedBeacon.data.updateBeacon;
        setBeacons(beaconCopy);
      }
    }
  }

  const handleLocSelection = (e) => {
    console.log(locations);
    const location = locations.find((element) => element.name === e.target.value);
    if (location !== undefined) {
      setBeaconFormData({...beaconFormData, location: location});
    } else {
      setBeaconFormData({...beaconFormData, location: ''});
    }
  };

  const handleScheduleSelection = (e) => {
    console.log(schedules);
    const schedule = schedules.find((element) => element.name === e.target.value);
    if (schedule !== undefined) {
      setBeaconFormData({...beaconFormData, schedule: schedule});
    } else {
      setBeaconFormData({...beaconFormData, schedule: ''});
    }
  };

  const handleChange = async (e, item) => {
    // first check that there is a location assigned to the beacon; if not, alert user they need to assign a schedule first
    if (!beacon.location) {
      alert('The beacon must be assigned to a location before turning on the broadcast');
      return;
    }

    // then check that there is a schedule assigned to the beacon; if not, alert user they need to assign a schedule first
    if (!beacon.schedule) {
      alert('The beacon must be assigned a schedule before turning on the broadcast');
      return;
    }

    // next check that the location is turned on in the mobile app; if not, do nothing
    const participantsOn = await API.graphql(graphqlOperation(queries.getLocation, { id: item.beaconLocationId }));
    if (!participantsOn.data.getLocation.participantsToggle) {
      alert('This location is currently not broadcasting. Please turn this location back on in the Business Info page to broadcast this beacon.');
      return;
    }

    const input = {
      id: item.id,
      toggle: !switchVal
    }
    const result = await API.graphql(graphqlOperation(mutations.updateBeacon,  {input: input }));
    setSwitchVal(result.data.updateBeacon.toggle);
  };

  const [shift, setShift] = useState(null);
  const [event, setEvent] = useState(null);

  const week = ["sunday","monday","tuesday","wednesday","thursday","friday","saturday"];
  const d = new Date();
  let day = week[d.getDay()];
  let hour = d.getHours();

  console.log(hour);

  useEffect(()=>{
    if (beacon.schedule) {
      (async () => {
        const shifts = await API.graphql(graphqlOperation(queries.listShifts, {filter: {scheduleShiftsId: {eq: beacon.schedule.id}, weekday: {eq: day}, startTime: {le: hour}, endTime: {gt: hour} }}));
        console.log(shifts);
        setShift(shifts.data.listShifts.items[0]);
      })();//IIFE
    }

    (async () => {
      console.log(d.toLocaleDateString());
      const events = await API.graphql(graphqlOperation(queries.listEvents, {filter: {businessId: {eq: beacon.businessId}, date: {eq: d.toLocaleDateString()}, startTime: {le: hour}, endTime: {gt: hour} }}));
      console.log(events);
      setEvent(events.data.listEvents.items[0]);
    })();//IIFE

  }, [beacon.schedule ? beacon.schedule.id : null])

  return (
    <Card style={cardStyle}>
      <Button onClick={handleOpenContent} style={buttonStyle}>View Ad</Button>
      <div className="beacon_details_holder">
        <Text style={{ fontSize: 14}}>{beacon.name}</Text>
        <Text style={{ color: 'grey', fontSize: '10px'}}>note: {beacon.note}</Text>
        <br/>
        <Text style={{ color: 'grey', fontSize: '10px'}}>SN: {beacon.uuid}</Text>
      </div>
      {beacon.location ? <Text style={textStyle}>{beacon.location.name}</Text> : <Text style={textStyle}>Unassigned</Text>}
      {beacon.schedule ? <Text style={textStyle}>{beacon.schedule.name}</Text> : <Text style={textStyle}>Unassigned</Text>}
      <Text style={textStyle}>{beacon.lastSeen}</Text>
      <div style={{  alignSelf: 'center'}}>
        <Switch
          id={beacon.id}
          checked={switchVal}
          onChange={e => handleChange(e, beacon)}
        />
      </div>
      <Modal sx={{ 
            "& > .MuiBackdrop-root" : {
                    backdropFilter: "blur(2px)",
                    backgroundColor: "#FFFFFF55"
                  }
            }} open={openContent} onClose={handleCloseContent}>
        {event ? 
          (event.ad.type) === "Image" ?
          <Box className="show_ad">
            <Image
            alt="Content"
            src={baseUrl+event.ad.url}
            width="400px"
            height="667px"/>
          </Box> :
          <Box className="add_input_margin larger_frame">
            <iframe src={event.ad.url}></iframe>
          </Box> :
        shift ?
          (shift.ad.type) === "Image" ?
          <Box className="show_ad">
            <Image
              alt="Content"
              src={baseUrl+shift.ad.url}
              width="300px"
              height="500px"/>
          </Box> :
          <Box className="add_input_margin larger_frame">
            <iframe src={shift.ad.url}></iframe>
          </Box>
          : 
          <Box className="show_ad">
            <h1>Currently no ad running</h1>
          </Box>}
      </Modal>
      <div className="card_btn_wrapper">
        {user.role === "Admin" ?
          <>
            {beacon.businessName !== 'DTGVL' ? <IconButton aria-label="swap" size="large" onClick={()=>handleSwap(beacon.id)}>
              <SwapHoriz />
            </IconButton> : null}
            <IconButton aria-label="delete" size="large" onClick={()=>handleOpenBeaconForm(beacon.id, beacon.name, beacon.note, beacon.location, beacon.schedule, beacon.uuid)}>
              <EditIcon />
            </IconButton>
            <IconButton aria-label="delete" onClick={()=>deleteBeacon(beacon.id)}>
              <DeleteIcon />
            </IconButton>
          </> :
          user.role === "Editor" ?
          <>
            <IconButton aria-label="delete" size="large" onClick={()=>handleOpenBeaconForm(beacon.id, beacon.name, beacon.note, beacon.location, beacon.schedule)}>
              <EditIcon />
            </IconButton>
            <IconButton aria-label="delete" onClick={()=>deleteBeacon(beacon.id)} disabled>
              <DeleteIcon />
            </IconButton>
          </> : 
          <>
            <IconButton aria-label="delete" size="large" disabled>
              <EditIcon />
            </IconButton>
            <IconButton aria-label="delete" disabled>
              <DeleteIcon />
            </IconButton>
          </>}
      </div>
      <Modal sx={{ 
            "& > .MuiBackdrop-root" : {
                    backdropFilter: "blur(2px)",
                    backgroundColor: "#FFFFFF55"
                  }
            }} open={openBeaconForm} onClose={handleCloseBeaconForm}>
        <Box className="add_input_margin padding_3 smaller_frame">
          <Flex as="form" padding={30} direction="column" onSubmit={handleBeaconSubmit}>
            <TextField name="name" label="Beacon Name" value={beaconFormData.name} onChange={handleBeaconFormChange} isRequired={true}/>
            <TextField name="uuid" label="Serial Number" value={beaconFormData.uuid} onChange={handleBeaconFormChange} isRequired={true}/>
            <TextField name="note" label="Note" value={beaconFormData.note} onChange={handleBeaconFormChange} isRequired={false}/>
            {beacon.location ? <SelectField value={beaconFormData.location.name} label="Beacon Location" onChange={handleLocSelection}>
              <option value="">Uninstall from location</option>
              {locations.map((location) =>
                <option key={location.id} value={location.name}>{location.name}</option>)}
            </SelectField> : 
            <SelectField onChange={handleLocSelection} label="Beacon Location">
              <option value="">Please select an install location</option>
              {locations.map((location) =>
                <option key={location.id}>{location.name}</option>)}
            </SelectField>}
            {beacon.schedule ? <SelectField value={beaconFormData.schedule.name} label="Beacon Ad Schedule" onChange={handleScheduleSelection}>
              <option value="">Remove ad schedule</option>
              {schedules.map((schedule) =>
                <option key={schedule.id} value={schedule.name}>{schedule.name}</option>)}
            </SelectField> : 
            <SelectField onChange={handleScheduleSelection} label="Beacon Ad Schedule">
              <option value="">Please select an ad schedule</option>
              {schedules.map((schedule) =>
                <option key={schedule.id}>{schedule.name}</option>)}
            </SelectField>}
            <Button type="submit" variation="primary" backgroundColor={"#429321"}>Submit</Button>
          </Flex>
        </Box>
      </Modal>
    </Card>
  );
}

const textStyle = {
  fontSize: '14px',
  textAlign: 'center',
  width: '90%'
}

const buttonStyle = { backgroundColor: '#429321', color: 'white', height: '50px', alignSelf: 'center' }

const cardStyle = {
  border: '0.5px solid lightgrey',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
  justifyItems: 'center',
  alignItems: 'center'
}

export default BeaconCard;
