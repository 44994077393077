import React from "react";
import { Collection } from "@aws-amplify/ui-react";
import QRCard from "./QRCard";

const QRList = ({ qrs, setQrs, user, schedules, deleteQr, analytics }) => {
  return (
    <>
      <div className="qr_headers_container">
        <p>Current Ad</p>
        <p>QR Code Name</p>
        <p>Scans</p>
        <p>Business</p>
        <p>QR Type</p>
        <p>Created</p>
        <p>Actions</p>
      </div>
      <div className="list_container">
        {qrs && qrs.length > 0 ? (
          <Collection type="list" items={qrs} gap="0.25rem">
            {(item, index) => (
              <QRCard
                key={item.id}
                qrs={qrs}
                setQrs={setQrs}
                schedules={schedules}
                user={user}
                qr={item}
                deleteQr={deleteQr}
                analytics={analytics}
              />
            )}
          </Collection>
        ) : (
          <p style={{ color: "white", fontStyle: "italic", fontWeight: "100" }}>
            No QRs to display.
          </p>
        )}
      </div>
    </>
  );
};

export default QRList;
