import { Flex, TextField, Button } from '@aws-amplify/ui-react';
import { Alert, AlertTitle } from '@mui/material';
import { Auth } from 'aws-amplify';
import React from 'react';
import { useState } from 'react';

const PassReset = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [changed, setChanged] = useState(false);
  const [err, setErr] = useState(false);
  const [errMessage, setErrMessage] = useState('');

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
        let user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, oldPassword, newPassword);
        console.log("Successfully reset password");
        setErr(false);
        setChanged(true);
    } catch (e) {
        setErrMessage(e.message);
        setChanged(false);
        setErr(true);
    }
  };

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <Flex as="form" padding={30} direction="column" onSubmit={handlePasswordReset} style={{ width: '100%', height: '70%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <h1 style={{ color: 'white', fontSize: 32, margin: 0 }}>Password Management</h1>
        <h1 style={{ color: 'white', fontSize: 24, marginTop: 0, fontFamily: 'MontserratLight' }}>Change account password</h1>
        <TextField style={{ color: 'white', borderColor: 'white' }} type={'password'} width={'30%'} gap={20} name="old" placeholder="Current password" value={oldPassword} onChange={(e)=>setOldPassword(e.target.value)} isRequired={true} variation={'quiet'} color={'white'} /> 
        <TextField style={{ color: 'white', borderColor: 'white' }} type={'password'} width={'30%'} name="new" placeholder="New password" value={newPassword} onChange={(e)=>setNewPassword(e.target.value)} isRequired={true} variation={'quiet'} color={'white'} /> 
        <Button style={{ backgroundColor: 'white', borderRadius: 25, width: '15%', marginTop: 35 }} type="submit" >Submit</Button>
      </Flex>
      <div style={{ width: '30%', height: '30%' }}>
        {changed ? <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          Password successfully updated — <strong>check it out!</strong>
        </Alert> : null}
        {err ? <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errMessage}
        </Alert> : null}
      </div>
    </div>
  );
}

export default PassReset;
