import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { API, graphqlOperation } from 'aws-amplify';
import { Box, Button, IconButton, Modal, Skeleton } from '@mui/material';
import { analyticsByBusinessId, listAds } from '../graphql/queries';
import { Image } from '@aws-amplify/ui-react';
import { PlayCircle } from '@mui/icons-material';

const baseUrl = "https://dtgvlv2f04838c8631b4596b8b3acde06c1239b145859-dev.s3.amazonaws.com/public/";

// helper function
function countObjectsWithActions(arr) {
    console.log(arr);
    const nameCounts = {};
  
    for (const obj of arr) {
        if (obj.ad) {
            const name = obj.ad.name;
            const action = obj.action;
            if (!nameCounts[name]) {
              nameCounts[name] = { name: name, notification: 0, tap: 0, scan: 0, id: obj.ad.id, url: obj.ad.url, title: obj.ad.title, description: obj.ad.description };
            }
            nameCounts[name][action]++;
        }
    }
  
    const resultArray = Object.values(nameCounts);
  
    return resultArray;
  }

function AnalyticsAdChart({ business }) {
    const [adData, setAdData] = useState([]);
    const [displayAd, setDisplayAd] = useState(null);
    const [loading, setLoading] = useState(false);

    // Modal for preview
    const [openContent, setOpenContent] = useState(false);
    const handleOpenContent = () => setOpenContent(true);
    const handleCloseContent = () => setOpenContent(false);

    const handlePreview = (item) => {
        console.log(item);
        setDisplayAd(item);
        handleOpenContent();
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            // get the data for the given business
            const analyticsData = await API.graphql(graphqlOperation(analyticsByBusinessId, { businessId: business.id, limit: 500}));
    
            // separate out the analytics by device
            const result = countObjectsWithActions(analyticsData.data.analyticsByBusinessId.items);
    
            console.log(result);
            setAdData(result);

            setLoading(false);
        })();//IIFE
      }, [business.id]);

    return (
        <>
        <div className="bottom_container">
            <div style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
                <div>
                    <h3 style={{ margin: 0 }}>Notification & Ad Rankings</h3>
                    <p style={{ fontSize: '10px', margin: 0 }}>Message taps and Ad views</p>
                </div>
                {/* <div style={{ display: 'flex', flexDirection: 'row'}}>
                    <h3>Sort</h3>
                    <Button onClick={()=>setActionFilter('notifications')}>Notifications</Button>
                    <Button onClick={()=>setActionFilter('taps')}>Taps</Button>
                </div> */}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', width: '60%', justifyContent: 'space-around' }}>
                <p style={{ fontSize: 12, width: '33%', textAlign: 'center' }}>Title</p>
                <p style={{ fontSize: 12, width: '33%', textAlign: 'center' }}>Description</p>
                <p style={{ fontSize: 12, width: '33%', textAlign: 'center' }}>Preview</p>
            </div>
                {loading ? <Skeleton variant="rectangular" style={{ justifySelf: 'center', width: '80%', height: '80%' }} /> :
                adData.length === 0 ? <h3>No data to display</h3> :
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                    <div style={{ width: '60%' }}>
                        {adData.map(item =>
                            <div key={item.id} style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                                <h3 style={{ width: '33%', textAlign: 'center' }}>{item.title}</h3>
                                <h3 style={{ width: '33%', textAlign: 'center' }}>{item.description}</h3>
                                <div style={{ width: '33%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                    <IconButton color='success' onClick={() => handlePreview(item)}><PlayCircle /></IconButton>
                                </div>
                            </div>)}
                    </div>
                    <div style={{ width: '40%', height: 100 }}>
                        <ResponsiveContainer>
                            <BarChart
                            data={adData}
                            layout={'vertical'}
                            >
                                <CartesianGrid />
                                <XAxis type="number" axisLine={false} style={{ fontSize: '8px'}}/>
                                <YAxis type="category" dataKey="name" style={{ fontSize: '8px'}}/>
                                <Tooltip itemStyle={{ fontSize: '8px', textAlign: 'center' }} position={{ x: 0, y: -50}}/>
                                <Bar dataKey="notification" stackId="a" fill="#4E6576" />
                                <Bar dataKey="tap" stackId="a" fill="#4880FF" />
                                <Bar dataKey="scan" stackId="b" fill="#4880FF" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div> 
                </div>}
        </div>
        {displayAd && 
        <Modal sx={{ 
            "& > .MuiBackdrop-root" : {
                    backdropFilter: "blur(2px)",
                    backgroundColor: "#FFFFFF55"
                  }
            }} open={openContent} onClose={handleCloseContent}>
            <Box className="show_ad">
                {displayAd.type === "Website" ?
                    <iframe src={displayAd.url}></iframe> :
                        <Image
                        alt="Content"
                        src={baseUrl+displayAd.url}
                        width="400px"
                        height="667px"/>}
            </Box>
        </Modal>}
      </>
    );
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#000000',
    boxShadow: 24,
    overflow: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    color: '#000000',
    fontSize: 13
  };


export default AnalyticsAdChart;